import { Component, Input, OnInit } from '@angular/core';
import { TlnQueryServiceInterface } from '../models';
import { TlnCacheQueryService} from '../tln-query.service';
import { DataHandler} from '../data_handler';
import { TlnStatus } from '../datatypes/status';
import { DATA_PROCESSING_STATUS } from '../constants';



/**
 * Given a page or manuscript IRI, this component will request its status information and
 * symbolize it with an icon.
 **/
@Component({
  selector: 'tln-status',
  templateUrl: './tln-status.component.html',
  styleUrls: ['./tln-status.component.css']
})
export class TlnStatusComponent implements OnInit {
   readonly SHOW_NONE_OPTION: number = -1;
   readonly SHOW_PAGE_OPTION: number = 0;
   readonly SHOW_MANUSCRIPT_OPTION: number = 1;
   @Input() showDataStatusOptions: number = this.SHOW_NONE_OPTION;
   @Input() currentIri: string;
   /**
    * OPTIONAL pass a queryService with method 
    * {@link /interfaces/TlnQueryServiceInterface.html#getData|getData}
    * to TlnPageViewComponent.
    **/
   @Input() queryService: TlnQueryServiceInterface;
   /**
    * the data handler of this component that retrieves
    * data and instantiates it according to their proper 
    * datatypes.
    **/
   dataHandler: DataHandler = new DataHandler(this);
   private readonly icon_names: string[] = [ 'task_alt', 'data_saver_on', 'data_saver_off' ];
   readonly descriptions: string[] = [ 'Textdaten vollständig erfasst', 'Textdaten teilweise erfasst', 'Ohne Textdaten' ]
   private readonly DEFAULT: string = this.icon_names[0];
   private readonly DEFAULT_DESCRIPTION: string = this.descriptions[0];
   data_processing_dict = {}
   iconDescriptions = {} 
   tlnStatus: TlnStatus;

  constructor(private localQueryService: TlnCacheQueryService) { }

  ngOnInit() {
      this.initDataProcessingDict();
      if (this.currentIri != undefined && this.currentIri != null){
         this.localQueryService.use_cache = true;
         let tlnQueryService = (this.queryService != null) ? this.queryService : this.localQueryService;
         this.dataHandler.addHandler('tlnStatus', { 'handler': TlnStatus });
         this.dataHandler.setQueryService(tlnQueryService);
         this.dataHandler.getData('tlnStatus', this.currentIri);
      }
  }
  private initDataProcessingDict() {
      const data_processing_keys = Object.values(DATA_PROCESSING_STATUS);
      for (let i = 0; i < this.icon_names.length; i++){
         this.data_processing_dict[data_processing_keys[i]] = this.icon_names[i];
      }
      this.iconDescriptions = this.getIconDescriptions();
  }
  private getStatus(currentStatus: string): string {
      const status = (currentStatus != undefined) ? currentStatus : undefined;
      return this.getSaveInformation(status, this.data_processing_dict, this.DEFAULT);
  }
  private getDescription(currentStatus: string): string {
      const icon = this.getStatus(currentStatus);
      return this.getSaveInformation(icon, this.iconDescriptions, this.DEFAULT_DESCRIPTION);
  }
  private getSaveInformation(key: string, dictionary: Object, defaultValue: string): string {
      if (key == undefined || key == null || dictionary[key] == undefined){
         return defaultValue;
      }  
      return dictionary[key];
  }
  private getKeys(): string[] {
      if(this.showDataStatusOptions > this.SHOW_PAGE_OPTION) return this.icon_names;
      return [ this.icon_names[0], this.icon_names[2] ];
  }
  public getIconDescriptions(): Object {
      let iconDescriptions = {};
      for (let i = 0; i < this.icon_names.length; i++){
         iconDescriptions[this.icon_names[i]] = this.descriptions[i];
      }
      return iconDescriptions;
  }
}
