import { Component, OnInit, Input } from '@angular/core';
import { Line, Continuation, Point, Reference } from '../../models';
import { PageViewService } from '../../page-view.service';

@Component({
  selector: 'line-reference',
  templateUrl: './line-reference.component.html',
  styleUrls: ['./line-reference.component.css']
})
export class LineReferenceComponent implements OnInit {
   @Input() line: Line;
   @Input('showReference') referenceName: string;
   highlighted: boolean = false;
   continuation: Continuation;
   reference: Reference;

  constructor(private pageViewService: PageViewService) { }

  ngOnInit() { 
     if (this.line != null && this.referenceName != '' && ((this.referenceName == 'to' && this.line.continuesTo != null)
                              || (this.referenceName == 'from' && this.line.continuesFrom != null))){
         this.reference = (this.referenceName == 'to') ? this.line.continuesTo : this.line.continuesFrom;
         this.continuation = { source: this.line.source, reference: this.reference, datatype: 'Continuation', show: this.referenceName }
     }
  }

  private showReference(show: boolean, e: MouseEvent){
     this.highlighted = show;
     let point: Point = { visible: show, clientX: e.clientX-10, clientY: e.clientY-80, layerX: e.layerX, layerY: e.layerY }
     if (show){
         this.pageViewService.onHoverService(this.continuation, point);
         this.pageViewService.onHoverService(this.line)
     } else {
         this.pageViewService.offHoverService(this.continuation);
         this.pageViewService.offHoverService(this.line)
     }
  }
  private changeLocation(){
     this.pageViewService.referenceService(this.reference);
  }
}
