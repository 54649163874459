import { Pipe, PipeTransform } from '@angular/core';
import { ResultRange } from './paginator-result-status';

@Pipe({
  name: 'pageResultFilter'
})
export class PageResultFilterPipe implements PipeTransform {

  transform(results: any[], range: ResultRange): any[] {
    if (results.length <= range.start){
      return results;
    }
    return results.slice(range.start, range.end);
  }

}
