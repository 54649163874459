import { AppComponent } from './app.component';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule} from '@angular/forms';
import { NgModule } from '@angular/core';
import {MatCardModule} from '@angular/material/card';
import { MatToolbarModule, MatButtonModule, MatFormFieldModule, MatInputModule } from '@angular/material';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatTreeModule} from '@angular/material/tree';
import { MatExpansionModule } from '@angular/material/expansion';
import {MatIconModule} from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule} from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material';
import { MatSelectModule } from '@angular/material/select';
import {MatTabsModule} from '@angular/material/tabs';
import {MatTooltipModule} from '@angular/material';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { QueryService } from './services/query.service';
import {routing} from './app.routing';
import {HomeComponent} from './home.component';
import { ManuscriptViewComponentComponent } from './manuscript-view-component/manuscript-view-component.component';
import { ContentViewTabComponentComponent } from './content-view-tab-component/content-view-tab-component.component';
import { RhizomeViewComponentComponent } from './rhizome-view-component/rhizome-view-component.component';
import { MainMenuComponentComponent } from './main-menu-component/main-menu-component.component';
import { PageViewWrapperComponent } from './page-view-wrapper-component/page-view-wrapper.component';
import { NavigationListComponentComponent } from './navigation-list-component/navigation-list-component.component';
import {NavigationServiceService} from './services/navigation-service.service';
import { TlnEditionModule} from './tln-edition/tln-edition.module';
import { NavTree } from './navigation-list-component/navtree-directive.directive';
import { LazyImageLoadDirectiveDirective } from './content-view-tab-component/lazy-image-load-directive.directive';
import { ImpressumComponent } from './impressum.component';
import { ProjectComponent } from './project.component';
import { NavigationlistListComponentComponent } from './navigation-list-component/navigationlist-list-component/navigationlist-list-component.component';
import { QuantComponent } from './quant/quant.component';
import { QueryComponent } from './query.component';
import { ConceptionComponent } from './conception.component';
import { SupportComponent } from './support/support.component';
import { HeadingComponent } from './support/heading.component';
import { AddContentPipe } from './support/add-content.pipe';
import { SubheadingComponent } from './support/subheading.component';
import { SubSubheadingComponent } from './support/subsubheading.component';
import { MenuComponent } from './support/menu.component';


@NgModule({
  declarations: [
     AppComponent,
     HomeComponent,
     ManuscriptViewComponentComponent,
     ContentViewTabComponentComponent,
     RhizomeViewComponentComponent,
     MainMenuComponentComponent,
     PageViewWrapperComponent,
     NavigationListComponentComponent,
     NavTree,
     LazyImageLoadDirectiveDirective,
     ImpressumComponent,
     ProjectComponent,
     NavigationlistListComponentComponent,
     QuantComponent,
     QueryComponent,
     ConceptionComponent,
     SupportComponent,
     HeadingComponent,
     AddContentPipe,
     SubheadingComponent,
     SubSubheadingComponent,
     MenuComponent
  ],
  imports: [
    routing,
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    HttpClientModule,
    MatButtonModule,
    MatCardModule,
    MatExpansionModule,
    MatIconModule,
    MatListModule,
    MatMenuModule,
    MatProgressBarModule,
    MatSelectModule, // for themes selection
    MatSidenavModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTreeModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    TlnEditionModule,
    ReactiveFormsModule
  ],
  providers: [ NavigationServiceService, QueryService ],
  bootstrap: [AppComponent]
})
export class AppModule { }
