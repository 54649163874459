import { Component, Input, OnInit} from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { TLN_SEARCH_ROUTE, TLN_FULLSCREEN_PARAM, TLN_NAV_BAR_OPEN_STATE_PARAM} from '../constants';
import { Mapping } from '../route-reader';
import { RouteUpdater } from '../route-updater';


@Component({
  selector: 'toggle-navigation',
  templateUrl: './toggle-navigation.component.html',
  styleUrls: ['./toggle-navigation.component.css']
})

export class ToggleNavigationComponent extends RouteUpdater {
   @Input() showNavBarOpenButton: boolean = true;
   fullscreen: boolean = false;
   navBarOpenState: boolean = false;
   protected mapping: Mapping = { 
      navBarOpenState: { param: TLN_NAV_BAR_OPEN_STATE_PARAM, type: "boolean" },
      fullscreen: { param: TLN_FULLSCREEN_PARAM, type: "boolean" }
   }

   constructor(protected router: Router, protected activatedRoute: ActivatedRoute ) { 
      super(router, activatedRoute);
   }
   private toggleFullscreen(){
     this.fullscreen = !this.fullscreen;
     this.updateParams();
   }
   toggleNavDrawer(){
      this.navBarOpenState = !this.navBarOpenState;
      this.updateParams();
   }
}
