import {Component, OnInit} from '@angular/core';
import { OverlayContainer} from '@angular/cdk/overlay';
import {Subscription} from 'rxjs';
import {ActivatedRoute, Params} from '@angular/router';
import { environment } from './../environments/environment';
declare var require: any;

@Component({
   selector: 'app-root',
   templateUrl: './app.component.html',
   styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
   title = 'Digitale Manuskriptedition: Der späte Nietzsche';
   theme = 'standard-theme';
   version: string;
   showVersion: boolean = true;

   fullScreen: string;
   queryParamSub: Subscription;

   constructor(private overlayContainer: OverlayContainer, private activatedRoute: ActivatedRoute) {
     this.version = require( '../../package.json').version;
     if (environment.showVersion != undefined){
         this.showVersion = environment.showVersion;
     }
   }

   ngOnInit() {
     this.overlayContainer.getContainerElement().classList.add(this.theme);
     this.queryParamSub = this.activatedRoute.queryParams.subscribe( (queryParams: Params ) => {
       this.fullScreen = queryParams.fullscreen;
     });

   }

   onThemeChange() {
     this.overlayContainer.getContainerElement().classList.add(this.theme);
   }
}
