import {AfterViewInit, Component, HostListener, Input, OnInit} from '@angular/core';
import {ActivatedRoute, Params} from '@angular/router';

@Component({
  selector: 'app-page-view-wrapper-component',
  templateUrl: './page-view-wrapper.component.html',
  styleUrls: ['./page-view-wrapper.component.scss']
})
export class PageViewWrapperComponent implements OnInit, AfterViewInit {
  @Input() showAllLines: boolean = false;
  fullscreen: boolean;
  innerHeight: number;

  constructor(private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    this.innerHeight = window.innerHeight;
    this.activatedRoute.queryParams.subscribe( (queryParams: Params)  => this.fullscreen = (queryParams.fullscreen === 'true') );
   }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.ngOnInit();
  }

   ngAfterViewInit() {
     window.setTimeout(() => this.logHeights(), 500);
   }

   logHeights() {
     console.log('Window inner height', window.innerHeight);
     console.log('Window outer height', window.outerHeight);
     const elemIds = ['wrapper', 'navigator', 'content', 'tooltip-container', 'page'];
     elemIds.forEach(id => {
       const el = document.getElementById(id);
       if (el) {
         console.log('--------------------');
         console.log(id);
         console.log(el);
         // including the element's border
         const height = el.offsetHeight;
         // not including the element's border
         const cHeight = el.clientHeight;
         console.log('height with border: ', height);
         console.log('height without border: ', cHeight);
       }
     });
   }
}
