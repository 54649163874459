import {Directive, EventEmitter} from '@angular/core';
import {Params} from '@angular/router';
import {ApiDef, NavigationEntity, TlnEntity} from '../models/models';
import * as _ from 'lodash';

@Directive({
  selector: '[appNavtreeDirective]'
})

export class NavTree {
  id: string;
  idx: number;
  label: string;
  entries: NavigationEntity[];
  qParam: string;
  description?: string;
  apiDef?: ApiDef;
  selectedItem?: string;
  selectedItemLabel?: string;
  parentLabel?: string;

  selectedItemSet: EventEmitter<SelectedItem>

  constructor(id: string,
              idx: number,
              label: string,
              entries: NavigationEntity[],
              qParam: string,
              description?: string,
              apiDef?: ApiDef,
              selectedItem?: string,
              selectedItemLabel?: string,
              parentLabel?: string) {
    this.id = id;
    this.idx = idx;
    this.label = label;
    this.qParam = qParam;
    this.entries = entries || [];
    this.description = description;
    this.apiDef = apiDef;
    this.selectedItem = selectedItem;
    this.selectedItemLabel = selectedItemLabel;
    this.selectedItemSet = new EventEmitter();
    this.parentLabel = parentLabel;
  }

  async setNavTreeData(data: any, queryParams?: Params, parentLabel?: string) {
    this.parentLabel = parentLabel;
    const selectedId = queryParams[this.qParam];
    if (selectedId && selectedId !== '' ) {
      const selectedLabel = await _.get(data[data.findIndex(item => item[this.apiDef.mapping.id] === selectedId)], this.apiDef.mapping.label);
      this.setSelectedItem(selectedId, selectedLabel); } else {
        const firstEntry = await _.get(data[0], this.apiDef.mapping.id);
        await this.setSelectedItem(firstEntry, await _.get(data[0], this.apiDef.mapping.label));
      }

    this.entries = []; // resetting tree data of the given tab
    data.forEach((entry, index) => {
      // (id: string, iri: string, type: string, navIndex: number, label?: string)
      const entity = new TlnEntity(_.get(entry, this.apiDef.mapping.id),
        _.get(entry, this.apiDef.mapping.iri), _.get(entry, this.apiDef.mapping.type) ||
        null, index , _.get(entry, this.apiDef.mapping.label) || '', _.get(entry, this.apiDef.mapping.description || ''));
      const thumb = _.get(entry, this.apiDef.mapping.thumb);
      let svg;
      if (_.get(entry, this.apiDef.mapping.svg)) {
        svg = _.get(entry, this.apiDef.mapping.svg);
      }
      const avatar = _.get(entry, this.apiDef.mapping.avatar);
      const navEntity = new NavigationEntity(index, entity, thumb, '', svg, avatar);
      this.entries.push(navEntity);
    });
  }

  public setSelectedItem(itemId: string, itemLabel?: string) {
    this.selectedItem = itemId;
    this.selectedItemLabel = itemLabel;
    //
    this.selectedItemSet.emit({tabId: this.idx, itemId});
    //
  }

  public getSelectedById(itemId: string) {
    const idx = this.entries.findIndex(item => item.tlnEntity.id === itemId);
    if (idx !== -1) {
      return this.entries[idx];
    } else { console.log('no item with id', itemId, 'found'); }
  }
}

export interface SelectedItem {
  tabId: number;
  itemId: string;
}
