import { BasicResultBindingElement } from './basic_datatype';
import { ManuscriptUnity, NavigationPage } from '../models';

export class TlnManuscriptUnity extends BasicResultBindingElement implements ManuscriptUnity {
   static readonly query: string = `
   PREFIX tln: <http://www.nie.org/ontology/nietzsche#>
   PREFIX rdf: <http://www.w3.org/1999/02/22-rdf-syntax-ns#>
   PREFIX list: <http://jena.apache.org/ARQ/list#>

   SELECT ?title ?numberOfPages ?firstItem ?firstNumber ?lastItem ?lastNumber WHERE {
      ?id a tln:ManuscriptUnity;
         tln:hasTitle ?title;
         tln:hasPages ?list.
      ?list list:length ?numberOfPages;
            rdf:first ?firstItem;
            rdf:rest* ?lastNode.
      ?lastNode rdf:first ?lastItem;
                rdf:rest rdf:nil.
      ?firstItem tln:hasNumber ?firstNumber.
      ?lastItem tln:hasNumber ?lastNumber.
   }`;
   static readonly query_key: string = 'id';
   title: string;
   numberOfPages: number;
   firstPage: NavigationPage;
   lastPage: NavigationPage;

   constructor (data: any, id?: string, service?: any) {
      super(data, id, service);
      this.title =  this.getData4Key('title');
      this.numberOfPages = this.getData4Key('numberOfPages')
      this.firstPage = { id: this.getData4Key('firstItem'), number: this.getData4Key('firstNumber'), title: this.title, index: 1 };
      this.lastPage = { id: this.getData4Key('lastItem'), number: this.getData4Key('lastNumber'), title: this.title, index: this.numberOfPages };
   }
}

export class TlnNavigationPage extends BasicResultBindingElement  {
   static readonly query: string = `
   PREFIX tln: <http://www.nie.org/ontology/nietzsche#>
   PREFIX rdf: <http://www.w3.org/1999/02/22-rdf-syntax-ns#>
   PREFIX list: <http://jena.apache.org/ARQ/list#>

   SELECT ?title ?number  ?previous ?previousNumber  ?next ?nextNumber  ?previousIndex ?nextIndex WHERE {
      ?id tln:hasNumber ?number.
      ?unity a tln:ArchivalManuscriptUnity;
         tln:hasTitle ?title;
         tln:hasPages ?list.
      ?list rdf:rest* ?node.
      ?node rdf:first ?id.
      OPTIONAL { ?node ^rdf:rest/rdf:first ?previous.
                 ?previous tln:hasNumber ?previousNumber.
                 ?list list:index (?previousIndex ?previous);
      }
      OPTIONAL { ?node rdf:rest/rdf:first ?next.
                 ?next tln:hasNumber ?nextNumber.
                 ?list list:index (?nextIndex ?next);
      }
   }`;
   static readonly query_key: string = 'id';
   title: string; 
   number: string;
   previous?: NavigationPage;
   next?: NavigationPage;

   constructor (data: any, id?: string, service?: any) {
      super(data, id, service);
      this.title =  this.getData4Key('title');
      this.number =  this.getData4Key('number');
      let previous = this.getData4Key('previous');
      let next =  this.getData4Key('next');
      if (previous != null){
         this.previous = { id: previous, number: this.getData4Key('previousNumber'), title: this.title, index: this.getData4Key('previousIndex')+1 };
      }
      if (next != null){
         this.next = { id: next, number: this.getData4Key('nextNumber'), title: this.title, index: this.getData4Key('nextIndex')+1 };
      }
   }
}

