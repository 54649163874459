import { BasicResultBindingElement } from './basic_datatype';
/**
 * This is the datatype instantiation of an element of {@link /interfaces/FusekiResults.html|FusekiResults}.
 **/
export class TlnType extends BasicResultBindingElement  {
   static readonly default_key: string = 'id';
   static readonly query_key: string = 'id';
   /** 
    * the SPARQL-query of this datatype.
    **/
   static readonly query: string = `
   PREFIX tln: <http://www.nie.org/ontology/nietzsche#>
   PREFIX rdfs: <http://www.w3.org/2000/01/rdf-schema#>

   SELECT distinct ?type WHERE {
       ?id a ?type.
       ?type rdfs:isDefinedBy <http://www.nie.org/ontology/nietzsche>.
   }`;
   type: string;
    /**
    * The constructor creates a datatype from the data.
    *
    * @param id if omitted the id will be retrieved from data
    **/
   constructor(data: any, id?: string, service?: any){
      super(data, id, service)
      this.type = this.getData4Key('type')
   }

}

