import { BasicResultBindingElement } from './basic_datatype';
import { TlnPositionalObject } from './positional_object';
import { Word, Representation } from '../models';
/**
 * This is the word instantiation of an element of {@link /interfaces/FusekiResults.html|FusekiResults}.
 * It extends {@link /classes/BasicResultBindingElement.html|BasicResultBindingElement}.
 **/
export class TlnWord extends TlnPositionalObject implements Word {
   /** 
    * the SPARQL-query of this datatype.
    **/
   static readonly query: string = `
   PREFIX tln: <http://www.nie.org/ontology/nietzsche#>
   PREFIX rdf: <http://www.w3.org/1999/02/22-rdf-syntax-ns#>

   SELECT ?id ?text ?edited_text ?left ?top ?width ?height ?transform ?line ?line_number ?deleted ?deletion_path ?undeleted WHERE {
      ?page tln:hasWords/rdf:rest*/rdf:first ?id.
      ?id tln:wordBelongsToLine ?line; 
            tln:hasText ?text;
            tln:hasTranskriptionPosition ?tp.
    	?tp tln:hasLeft ?left; tln:hasTop ?top; tln:hasWidth ?width; tln:hasHeight ?height.
      ?line tln:lineHasNumber ?line_number.
      BIND(exists{
            {?id tln:wordIsDeletedByPath ?path} 
            UNION{ ?id tln:wordHasWordParts/rdf:rest*/rdf:first ?word_part.
                   ?word_part tln:hasTranskriptionPosition ?tp; tln:wordIsDeletedByPath ?path}
          } as ?deleted)
      BIND(exists{
            {?id tln:wordIsUndeletedFromPath ?path} 
            UNION{ ?id tln:wordHasWordParts/rdf:rest*/rdf:first ?word_part.
                   ?word_part tln:hasTranskriptionPosition ?tp; tln:wordIsUndeletedFromPath ?path}
          } as ?undeleted)
      OPTIONAl { ?tp tln:hasTransform ?transform.}
      OPTIONAl { ?id tln:hasEditedText ?edited_text.}
      OPTIONAl { ?id tln:wordIsDeletedByPath/tln:hasDAttribute ?deletion_path.}
      OPTIONAl { ?id tln:wordHasWordParts/rdf:rest*/rdf:first ?word_part.
                   ?word_part tln:hasTranskriptionPosition ?tp; tln:wordIsDeletedByPath/tln:hasDAttribute ?deletion_path}
   } `;
   /**
    * the text of this word
    **/
   text: string;
   /**
    * the text of this word as it has been edited by the editors.
   **/
   edited_text?: string;
   /**
    * the id of the line to which this word belongs.
    **/
   line: string;
   /**
    * the number of the line to which this word belongs.
    * */
   line_number: number;
   /**
    * is this word deleted.
    **/
   deleted: boolean;
   /** 
    * a deletion path
    **/
   deletion_path?: string;
   
    /**
    * The constructor creates a datatype from the data.
    *
    * @param id if omitted the id will be retrieved from data
    **/
   constructor(data: any, id?: string, service?: any){
      super(data, id, service)
      this.text = this.getData4Key('text');
      this.edited_text = this.getData4Key('edited_text');
      this.line = this.getData4Key('line');
      this.line_number = this.getData4Key('line_number');
      this.deleted = this.getData4Key('deleted');
      let undeleted = this.getData4Key('undeleted');
      if (undeleted){
         this.deleted = false;
      }
      this.deletion_path = this.getData4Key('deletion_path');
   }
}
/**
 * This is the faksimile word instantiation of an element of {@link /interfaces/FusekiResults.html|FusekiResults}.
 * It extends {@link /classes/BasicResultBindingElement.html|BasicResultBindingElement}.
 **/
export class FaksimileWord extends TlnWord {
   /** 
    * the SPARQL-query of this datatype.
    **/
    static readonly query: string = `
   PREFIX tln: <http://www.nie.org/ontology/nietzsche#>
   PREFIX rdf: <http://www.w3.org/1999/02/22-rdf-syntax-ns#>

   SELECT ?id ?text ?edited_text ?left ?top ?width ?height ?transform ?line ?line_number ?deleted ?undeleted WHERE {
      ?page tln:hasWords/rdf:rest*/rdf:first ?id.
      ?id tln:wordBelongsToLine ?line; 
            tln:hasText ?text;
            tln:hasFaksimilePosition ?fp.
    	?fp tln:hasLeft ?left; tln:hasTop ?top; tln:hasWidth ?width; tln:hasHeight ?height.
      ?line tln:lineHasNumber ?line_number.
      BIND(exists{{?id tln:wordIsDeletedByPath ?path} UNION { ?id tln:wordHasWordParts/rdf:rest*/rdf:first/tln:wordIsDeletedByPath ?path}
      } as ?deleted)
      BIND(exists{
            {?id tln:wordIsUndeletedFromPath ?path}
            UNION{ ?id tln:wordHasWordParts/rdf:rest*/rdf:first ?word_part.
                   ?word_part tln:hasTranskriptionPosition ?tp; tln:wordIsUndeletedFromPath ?path}
          } as ?undeleted)
      OPTIONAl { ?fp tln:hasTransform ?transform.}
      OPTIONAl { ?id tln:hasEditedText ?edited_text.}
   } `;
}
/**
 * This is the word stub instantiation of an element of {@link /interfaces/FusekiResults.html|FusekiResults}.
 * It extends {@link /classes/BasicResultBindingElement.html|BasicResultBindingElement}.
 **/
export class WordStub extends BasicResultBindingElement {
   /** 
    * the internal default key for replacing {@link /classes/TlnWord.html#query|query} by "id"
    * in {@link /classes/TlnWord.html#getQuery|getQuery} if "key" is omitted.
    **/
   static readonly default_key: string = 'word';
   /** 
    * the public key for replacing {@link /classes/TlnWord.html#query|query} by "id".
    **/
   static readonly query_key: string = 'word';
   /** 
    * the SPARQL-query of this datatype.
    **/
   static readonly query: string = `
   PREFIX tln: <http://www.nie.org/ontology/nietzsche#>
   PREFIX rdf: <http://www.w3.org/1999/02/22-rdf-syntax-ns#>

   SELECT ?id ?text WHERE {
      ?id a tln:Word;
         tln:hasText ?text.
   }`;
   /**
    * text of word 
    **/
   text: string;
      
    /**
    * The constructor creates a datatype from the data.
    *
    * @param id if omitted the id will be retrieved from data
    **/
   constructor(data: any, id?: string, service?: any){
      super(data, id, service)
      this.text = this.getData4Key('text');
   }
}
export class WordWithLine extends WordStub {
   /** 
    * the internal default key for replacing {@link /classes/TlnWord.html#query|query} by "id"
    * in {@link /classes/TlnWord.html#getQuery|getQuery} if "key" is omitted.
    **/
   static readonly default_key: string = 'id';
   /** 
    * the public key for replacing {@link /classes/TlnWord.html#query|query} by "id".
    **/
   static readonly query_key: string = 'id';
   /** 
    * the SPARQL-query of this datatype.
    **/
   static readonly query: string = `
   PREFIX tln: <http://www.nie.org/ontology/nietzsche#>
   PREFIX rdf: <http://www.w3.org/1999/02/22-rdf-syntax-ns#>

   SELECT ?text ?lineNumber WHERE {
      ?id a tln:Word;
         tln:wordBelongsToLine ?line;
         tln:hasText ?text.
      ?line tln:lineHasNumber ?lineNumber.
   }`;
   /**
    * text of word 
    **/
   lineNumber: number;
      
    /**
    * The constructor creates a datatype from the data.
    *
    * @param id if omitted the id will be retrieved from data
    **/
   constructor(data: any, id?: string, service?: any){
      super(data, id, service)
      this.lineNumber = this.getData4Key('lineNumber');
   }
}
export class WordRepresentation extends WordWithLine implements Representation {
   readonly openInTarget: string = 'viewer';
   /** 
    * the internal default key for replacing {@link /classes/TlnWord.html#query|query} by "id"
    * in {@link /classes/TlnWord.html#getQuery|getQuery} if "key" is omitted.
    **/
   static readonly default_key: string = 'id';
   /** 
    * the public key for replacing {@link /classes/TlnWord.html#query|query} by "id".
    **/
   static readonly query_key: string = 'id';
   /** 
    * the SPARQL-query of this datatype.
    **/
   static readonly query: string = `
   PREFIX tln: <http://www.nie.org/ontology/nietzsche#>
   PREFIX rdf: <http://www.w3.org/1999/02/22-rdf-syntax-ns#>

   SELECT ?text ?lineNumber ?pageNumber ?title ?page ?manuscript WHERE {
      ?id a tln:Word;
         tln:wordBelongsToLine ?line;
         tln:hasText ?text.
      ?line tln:lineHasNumber ?lineNumber.
      ?page a tln:Page;
         tln:hasNumber ?pageNumber;
         tln:hasWords/rdf:rest*/rdf:first ?id.
      ?manuscript a tln:ArchivalManuscriptUnity;
         tln:hasPages/rdf:rest*/rdf:first ?page;
         tln:hasTitle ?title.
   }`;
   /**
    * text of word 
    **/
   lineNumber: number;
   pageNumber: string;
   page: string;
   title: string;
   manuscript: string;
   representation: string;
      
    /**
    * The constructor creates a datatype from the data.
    *
    * @param id if omitted the id will be retrieved from data
    **/
   constructor(data: any, id?: string, service?: any){
      super(data, id, service)
      this.pageNumber = this.getData4Key('pageNumber');
      this.page = this.getData4Key('page');
      this.title = this.getData4Key('title');
      this.manuscript = this.getData4Key('manuscript');
      this.representation = this.title + " " + this.pageNumber + ", " + this.lineNumber + " (" + this.text +")";
   }
   getRouterInformation(launchExternally: boolean): Object {
         return { title: this.title, number: this.pageNumber, page: this.page, 
            manuscript: this.manuscript, selectedWords: [ this.id ], launchExternally: launchExternally }
   }
}
