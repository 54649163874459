import { Component, OnInit, Input } from '@angular/core';
import { ngxCsv } from 'ngx-csv/ngx-csv';
import { SelectableWordProperty } from '../datatypes/quant';
import { FusekiResults} from '../datatypes/basic_datatype';


@Component({
  selector: 'export',
  templateUrl: './export.component.html',
  styleUrls: ['./export.component.css']
})
export class ExportComponent implements OnInit {
   @Input() displayedColumns: string[];
   @Input() exportData: any[];
   @Input() fusekiResults: FusekiResults;
   @Input() replaceId: string;
   @Input() selectedWordProperties: SelectableWordProperty[] = [];
   @Input() csv_title: string = 'SPN Data from ' + location.hostname;
   @Input() csv_file_name: string = "SPN_Data";
   readonly availableExportFormats: string[] = [ 'CSV', 'JSON' ]
   selectedExportFormat: string = 'CSV'

  constructor() { }

  ngOnInit() {
     if(this.selectedWordProperties.length > 0){
        this.csv_title = 'Wörter mit Eigenschaften:,' + this.selectedWordProperties.map(property =>property.label).join(',');
     }
  }
  // EXPORT function from: https://github.com/nie-ine/inseri/blob/devel/src/app/app-engine/apps/data-list-view/data-list-view-table/data-list-view-table.component.ts

  public export() {
    switch (this.selectedExportFormat) {
      case 'CSV': {
        this.exportToCsv();
        break;
      }
      case 'JSON': this.exportToJson();
    }
  }

  public exportToCsv() {
    var options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: true,
      title: this.csv_title,
      useBom: true,
      noDownload: false,
      headers: this.displayedColumns.map(column =>{
         if (column == 'id' && this.replaceId != undefined && this.replaceId != null){
            return this.replaceId;
         } else {
            return column;
         }
      })
    };

    //let exportData = this.flatten();
    new ngxCsv(this.flattenData(), this.csv_file_name, options);
  }
  private flattenData(): any[] {
      let data = [];
      this.exportData.forEach(entry =>{
         let element = {};
         this.displayedColumns.forEach(key =>{
            element[key] = (entry[key].hasOwnProperty('value')) ? entry[key].value : entry[key];
         });
         data.push(element);
      });
      return data;
  }
  exportToJson() {
    const data = (this.fusekiResults != undefined && this.fusekiResults != null) ? this.fusekiResults : this.exportData;
    const dataStr = JSON.stringify(data, null, 2);
    const file = new Blob([dataStr], {type: 'text/json'});
    this.download(file, 'export.json');
  }

  download(blob, filename) {
    if (window.navigator.msSaveOrOpenBlob) { window.navigator.msSaveOrOpenBlob(blob, filename); } else {
      let elem = document.createElement('a'), url = URL.createObjectURL(blob);
      elem.href = url;
      elem.download = filename;
      document.body.appendChild(elem);
      elem.click();
      setTimeout(function() {
        document.body.removeChild(elem);
        window.URL.revokeObjectURL(url);
      },0);
    }
  }

}
