import { Component, Inject} from '@angular/core';
import {MatDialog, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef} from '@angular/material/bottom-sheet';

@Component({
  selector: 'app-query-error',
  templateUrl: './query-error.component.html',
  styleUrls: ['./query-error.component.css']
})
export class QueryErrorComponent {
   constructor(@Inject(MAT_BOTTOM_SHEET_DATA) public data: any) {}
}
