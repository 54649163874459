import { Pipe, PipeTransform } from '@angular/core';
import { DataMapping, Link, QueryDataMapping } from './models';

@Pipe({
  name: 'uniqueEntry'
})
export class UniqueEntryPipe implements PipeTransform {
  transform(dataMapping: DataMapping): DataMapping {
    let queryDataMappings: QueryDataMapping[] = [];
    let keys = new Set(dataMapping.queryDataMappings.map(queryDataMapping =>queryDataMapping.variable))
    keys.forEach(key =>{
      let items = dataMapping.queryDataMappings.filter(queryDataMapping =>queryDataMapping.variable == key)
      if (items.length == 1 || items[0].links.length == 0){
         queryDataMappings.push(items[0])
      } else {
         let newItem = items[0];
         for (var i = 1; i < items.length; i++){
            if (newItem.links.filter(link =>link.value == items[i].links[0].value).length == 0){
               newItem.links.push(items[i].links[0])
            }
         }
         queryDataMappings.push(newItem);
      }
    })
    dataMapping.queryDataMappings = queryDataMappings;
    return dataMapping;
  }

}
