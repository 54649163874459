import { BasicResultBindingElement, FusekiResults } from './basic_datatype';
import { WordStub } from './word';
import { TlnFilteredPositionalStyleMarkup } from './positional-markup';
/**
 * This is the 'earlier version' stub instantiation of an element of {@link /interfaces/FusekiResults.html|FusekiResults}.
 **/
export class TlnEarlierVersionStub extends WordStub {
   /** 
    * the SPARQL-query of this datatype.
    **/
   static readonly query: string = `
   PREFIX tln: <http://www.nie.org/ontology/nietzsche#>
   PREFIX rdf: <http://www.w3.org/1999/02/22-rdf-syntax-ns#>
   PREFIX homotypic: <http://www.nie.org/ontology/homotypic#>
   PREFIX stoff: <http://www.nie.org/ontology/standoff#>

   SELECT ?id ?text ?markup ?sStyle ?start ?end WHERE {
      ?word tln:wordHasEarlierVersion ?id.
      ?id tln:hasText ?text.
      OPTIONAL {
         ?id homotypic:hasMarkup ?markup.
         ?markup stoff:hasCSS ?sStyle;
          stoff:standoffMarkupHasStartIndex ?start;
          stoff:standoffMarkupHasEndIndex ?end.
      }
   }`;
   markups: TlnFilteredPositionalStyleMarkup[] = [];
   /**
    * The constructor creates a datatype from the data.
    *
    * @param id the id will be retrieved from data anyway
    **/
   constructor(data: any, id?: string, service?: any){
      super(data, id, service)
      this.id = this.getData4Key('id');
   }
   public static convertData<T extends typeof BasicResultBindingElement>(this: T, data: FusekiResults, id?: string, service?: any): Array<InstanceType<T>> {
      let elements = []; 
      let content = this.getContent(data);
      for (var i = 0; i < content.length; i++){
         let element = new this(content[i], id, service);
         element['markups'] = (content[i]['markup'] != undefined) ? 
            TlnFilteredPositionalStyleMarkup.convertData({ head: { vars: []}, results: { bindings: <any>[ content[i] ] } }) : [];
         if (elements.length > 0 && elements[elements.length-1].id == element.id && element['markups'] != undefined && element['markups'] != null && element['markups'].length > 0){
            elements[elements.length-1]['markups'].push(element['markups'][0]);
         } else {
            elements.push(element)
         }
      }
      return elements;
   }

}
export class TlnOverwrittenStub extends TlnEarlierVersionStub {
   /** 
    * the SPARQL-query of this datatype.
    **/
   static readonly query: string = `
   PREFIX tln: <http://www.nie.org/ontology/nietzsche#>
   PREFIX rdf: <http://www.w3.org/1999/02/22-rdf-syntax-ns#>

   SELECT ?id ?text ?uncertain_decipherment ?deleted WHERE {
      ?word (tln:wordHasWordParts/rdf:rest*/rdf:first/tln:overwritesWord|tln:overwritesWord) ?id. 
      ?id tln:hasText ?text.
      OPTIONAL {
         ?id tln:wordHasEditorComment ?comment.
         ?comment a tln:UncertainDecipherment.
    		BIND(true as ?isUncertainDecipherment)
      }
      BIND(IF(bound(?isUncertainDecipherment), true, false) as ?uncertain_decipherment)
      BIND(exists{ ?id tln:wordIsDeletedByPath ?path } as ?deleted) 
   }`;
   deleted: boolean;
   uncertain_decipherment?: boolean;

    /**
    * The constructor creates a datatype from the data.
    *
    * @param id if omitted the id will be retrieved from data
    **/
   constructor(data: any, id?: string, service?: any){
      super(data, id, service)
      this.deleted = this.getData4Key('deleted');
      this.uncertain_decipherment = this.getData4Key('uncertain_decipherment');
   }

}
