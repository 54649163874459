import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-manuscript-view-component',
  templateUrl: './manuscript-view-component.component.html',
  styleUrls: ['./manuscript-view-component.component.scss']
})
export class ManuscriptViewComponentComponent implements OnInit {
  constructor() { }

  ngOnInit() {
  }
}
