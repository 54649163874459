import { Parser, Generator } from 'sparqljs';
import { BasicResultBindingElement, FusekiResults} from './basic_datatype';
import { ManuscriptPages } from './manuscript';
import { QueryJson } from './query_json';
import { TextQuality } from '../models';


export class NumericResultRow extends BasicResultBindingElement {
   static readonly hasText = "http://www.nie.org/ontology/nietzsche#hasText";
   static readonly hasCleanText = "http://www.nie.org/ontology/nietzsche#hasCleanText";
   static readonly hasEditedText = "http://www.nie.org/ontology/nietzsche#hasEditedText";
   static readonly hasCleanEditedText = "http://www.nie.org/ontology/nietzsche#hasCleanEditedText";
   static readonly manuscript_variable = "manuscript";
   static readonly text_variable = "id";
   static readonly raw_text_variable = "raw_text";
   static readonly edited_text_variable = "edited_text";
   static readonly word_variable = "word";
   static readonly bindObject = { type: "bind", variable: { termType: "Variable", value: NumericResultRow.text_variable },
      expression: { type: "operation", operator: "if", args: [ 
         { type: "operation", operator: "bound", args: [ { termType: "Variable", value: NumericResultRow.edited_text_variable } ] },
         { termType: "Variable", value: NumericResultRow.edited_text_variable }, 
         { termType: "Variable", value: NumericResultRow.raw_text_variable }, 
      ]}
   };
   static readonly orderObject = { expression: { termType: "Variable", value: NumericResultRow.text_variable }, descending: false };
   static readonly punctuationPattern = /[.,!;:\-_–()“„]/g
   static readonly query: string = `
   PREFIX tln: <http://www.nie.org/ontology/nietzsche#>
   PREFIX rdf: <http://www.w3.org/1999/02/22-rdf-syntax-ns#>

   SELECT ?id ?word ?numText ?total  WHERE { 
      ?manuscript a tln:ArchivalManuscriptUnity;
            tln:hasManuscriptType "Mappe";
            tln:hasPages/rdf:rest*/rdf:first ?page.
      ?page a tln:Page; 
        tln:hasWords/rdf:rest*/rdf:first ?word.
   }`;
   wordIds: string[] = [];
   numText: number;
   numProperties: number = 1;
   numPropertiesPercent: number = 1;
   numPropertiesIncludeMulti: number = 1;
   numTextPercent: number = 1;
   numPropertyTextPercent: number = 1;
   total: number;

   constructor (data: any, id?: string, service?: any) {
      super(data, id, service);
      this.numText = this.getData4Key('numText');
      this.total = this.getData4Key('total');
      this.wordIds.push(this.getData4Key('word'));
      this.updatePercentages();
   }
   public updateResult(item: NumericResultRow) {
      this.wordIds = this.wordIds.concat(item.wordIds);
      this.numPropertiesIncludeMulti = this.wordIds.length 
      this.numProperties = (new Set(this.wordIds)).size;
      this.updatePercentages();
   }
   private updatePercentages(){
      this.numPropertiesPercent = Math.round((this.numProperties/this.total)*10000)/100;
      this.numTextPercent = Math.round((this.numText/this.total)*10000)/100;
      this.numPropertyTextPercent = Math.round((this.numProperties/this.numText)*10000)/100;
   }
   public static convertData<T extends typeof BasicResultBindingElement>(this: T, data: FusekiResults, id?: string, service?: any): Array<InstanceType<T>> {
      if (!this.contentConforms2Type(data)){
         return [];
      }
      let elements = []; 
      let content = this.getContent(data);
      for (var i = 0; i < content.length; i++){
         let element = new NumericResultRow(content[i], service);
         if (elements.length > 0 && elements[elements.length-1].id == element.id){
            elements[elements.length-1].updateResult(element);
         } else {
            elements.push(element)
         }
      }
      //console.log(elements)
      return elements;
   }
   private static createGroup(whereItems: Object[], aggregate_variable: string, group?: string): Object {
      if (group != undefined && group != null) {
         return  { 
            type: "group", 
            patterns: [ 
               { queryType: "SELECT", 
                  variables: [ 
                     { expression:  { type: "aggregate", aggregation: "count", distinct: false, expression: { termType: "Variable", value: group }}, 
                        variable: { termType: "Variable", value: aggregate_variable } },
                     { termType: "Variable", value: group }
                  ],
                  where: whereItems, 
                  type: "query",
                  group: [ { expression: { termType: "Variable", value: group }} ]
               } ] 
         };
      } else {
         return  { 
            type: "group", 
            patterns: [ 
               { queryType: "SELECT", 
                  variables: [ 
                     { expression:  { type: "aggregate", aggregation: "count", distinct: false, expression: { termType: "Variable", value: this.word_variable }}, 
                        variable: { termType: "Variable", value: aggregate_variable } } 
                  ],
                  where: [{ type: "bgp", triples: whereItems }], 
                  type: "query"
               } ] 
         };
      }
   }
   private static hasTextObject(textQuality: TextQuality): Object {
      const hasText = (textQuality.clean) ? NumericResultRow.hasCleanText : NumericResultRow.hasText;
      const objectVariable = (textQuality.preferEditedText) ? NumericResultRow.raw_text_variable : NumericResultRow.text_variable;
      return {  
            subject: { termType: "Variable", value: NumericResultRow.word_variable },  
            predicate: { termType: "NamedNode", value: hasText },  
            object: { termType: "Variable", value: objectVariable }  
      };
   }
   private static optionalEditedTextObject(textQuality: TextQuality): Object { 
      const hasEditedText = (textQuality.clean) ? NumericResultRow.hasCleanEditedText : NumericResultRow.hasEditedText;
      return { type: "optional", patterns: [ { type: "bgp", triples: [ {  
            subject: { termType: "Variable", value: NumericResultRow.word_variable },  
            predicate: { termType: "NamedNode", value: hasEditedText },  
            object: { termType: "Variable", value: NumericResultRow.edited_text_variable }  
         } ] } ]
      };
   }
   private static insertTextConditions(parsedQuery: Object, textQuality: TextQuality){
      parsedQuery['where'][0].triples.push(this.hasTextObject(textQuality));
      if(textQuality.preferEditedText){
         parsedQuery['where'].push(this.optionalEditedTextObject(textQuality));
         parsedQuery['where'].push(this.bindObject);
      }      
   }
   public static getSelectableQuery(selectableProperties: SelectableWordProperty[], scopus: ManuscriptPages[], filterSelectedWordIds: string[], textQuality: TextQuality, text?: string, ignoreCase?: boolean, orderDesc?: boolean): string {
      let parser = new Parser();
      let sparqlGenerator = new Generator({});
      let parsedQuery = parser.parse(this.query)
      let basicWhereTriples = parsedQuery.where[0].triples.slice();
      this.insertTextConditions(parsedQuery, textQuality);
      const whereBeforeProperties = JSON.parse(JSON.stringify(parsedQuery.where));//deep cloning
      selectableProperties.forEach(selectableProperty =>{
         parsedQuery.where[0].triples.push({ 
            subject: { termType: "Variable", value: this.word_variable },  
            predicate: { termType: "NamedNode", value: selectableProperty.id },  
            object: { termType: "Variable", value: selectableProperty.id.substring(selectableProperty.id.indexOf('#')+1) }  
         })
      });
      if (text != undefined && text != null && text != '') {
         let regexFilter = { type: "filter", expression: {
                  type: "operation", operator: "regex", args: [
                     { termType: "Variable", value: this.text_variable },
                     { termType: "Literal", value: text }
                  ]
               } 
         }
         if (ignoreCase != undefined && ignoreCase){
            regexFilter.expression.args.push({ termType: "Literal", value: "i" });
         }
         parsedQuery.where.push(regexFilter);
      }
      if (filterSelectedWordIds.length > 0){
         let filters = filterSelectedWordIds.map(word =>QueryJson.createEqualsOperation(word, this.word_variable));
         let filterObject = QueryJson.createFilterObject(filters);
         parsedQuery.where.push(filterObject);
      }
      let totalGroup = this.createGroup(basicWhereTriples, "total");
      let numGroup = this.createGroup(whereBeforeProperties, "numText", this.text_variable);
      if (scopus.length > 0){
         let filters = scopus.map(manuscript =>QueryJson.createEqualsOperation(manuscript.id, this.manuscript_variable));
         let filterObject = QueryJson.createFilterObject(filters);
         parsedQuery.where.push(filterObject);
         totalGroup['patterns'][0].where.push(filterObject);
         numGroup['patterns'][0].where.push(filterObject);
      }
      
      parsedQuery.where.push(totalGroup);
      parsedQuery.where.push(numGroup);
      parsedQuery['order'] = [ this.orderObject ]
      return sparqlGenerator.stringify(parsedQuery);
   }
}

export class SelectableWordProperty extends BasicResultBindingElement{
   static readonly query: string = `
   PREFIX tln: <http://www.nie.org/ontology/nietzsche#>
   PREFIX rdfs: <http://www.w3.org/2000/01/rdf-schema#>
   PREFIX skos: <http://www.w3.org/2004/02/skos/core#>

   SELECT DISTINCT  ?id ?label ?propName WHERE {
      ?id rdfs:subPropertyOf tln:selectableWordProperty;
                skos:prefLabel ?label.
      #BIND(STRAFTER(STR(?id), STR(tln:)) as ?propName)
   }`;
   id: string;
   label: string;
   //propName: string;

   constructor (data: any, id?: string, service?: any) {
      super(data, id, service);
      this.id = this.getData4Key('id');
      this.label = this.getData4Key('label');
      //this.propName = this.getData4Key('propName');
   }
}
