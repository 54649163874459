import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { TLN_MULTI_INSTANCE_ZOOM_PARAM, TLN_ZOOM_PARAM } from '../constants';
import { Mapping } from '../route-reader';
import { RouteUpdater } from '../route-updater';

@Component({
  selector: 'zoom',
  templateUrl: './zoom.component.html',
  styleUrls: ['./zoom.component.css']
})
export class ZoomComponent  extends RouteUpdater {
   @Input() resetButtonOn: boolean = true;
   @Input() useViewerZoom: boolean = true;
   @Input() sliderOn: boolean = true;
   @Input() step: number = 0.25;
   zoomFactor: number = 1;
   protected mapping: Mapping = { zoomFactor: { param: TLN_ZOOM_PARAM, type: "number" }}
   

   constructor(protected router: Router, protected activatedRoute: ActivatedRoute ) { 
      super(router, activatedRoute);
   }
   ngOnInit(){
      if (!this.useViewerZoom){
         this.mapping['zoomFactor']['param'] = TLN_MULTI_INSTANCE_ZOOM_PARAM;
      }
      super.ngOnInit();
   }
   private formatLabel(value: number): string {
         return Math.round(value * 100) + '%'; 
   }
   private setZoomFactor(newZoomFactor: number){
      console.log(newZoomFactor);
      if (newZoomFactor > 0){
         this.zoomFactor = Math.round(newZoomFactor*100)/100;
      } else {
         this.zoomFactor = this.zoomFactor/2
      }
      this.updateParams();
   }
}
