import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Sort} from '@angular/material/sort';
import {MatBottomSheet, MatBottomSheetRef} from '@angular/material/bottom-sheet';
import {MatTabChangeEvent} from '@angular/material/tabs';
import {QueryErrorComponent} from './query-error.component';
import { CodemirrorComponent} from '@ctrl/ngx-codemirror';
import { DataHandler } from '../data_handler';
import { QuantitativeDataHandler } from '../quant_data_handler';
import { Manuscript4Selection} from '../datatypes/manuscript';
import { FusekiResults } from '../datatypes/basic_datatype';
import { NumericResultRow, SelectableWordProperty} from '../datatypes/quant';
import { QueryJson} from '../datatypes/query_json';
import { TlnQueryServiceInterface, QueryFile, DataProcessor} from '../models';
import { TlnCacheQueryService } from '../services';
import { TLN_QUANT_ROUTE, TLN_MANUSCRIPT_ROUTE, TLN_VIEWER_ROUTE, TLN_CONTEXT_VIEW_PARAM, TLN_FULLSCREEN_PARAM, TLN_FIND_PARAM, TLN_PAGE_PARAM, TLN_MANUSCRIPT_PARAM, TLN_QUANT_QUERY_PARAM, 
   TLN_QUERY_PARAM, TLN_RESULT_INDEX_PARAM,TLN_SELECTED_LINES_PARAM, TLN_TEXT_GENETIC_ORDER_PARAM, TLN_VIEW_OPTION_PARAM, TLN_ZOOM_PARAM, VIEW_OPTIONS, ONTOLOTY_PREFIX } from '../constants';
import { Mapping } from '../route-reader';
import { RouteUpdater } from '../route-updater';
import { CacheService } from '../common/cache.service';
import { SelectFromArray } from '../common/select-array';
//import dataQueries from '/assets/1.0/dataQueries.json';

@Component({
  selector: 'tln-data',
  templateUrl: './tln-data.component.html',
  styleUrls: ['./tln-data.component.css']
})
export class TlnDataComponent extends RouteUpdater implements DataProcessor, OnInit  {
   private readonly dataPrefix: string = '1.0';
   private readonly prefixUrl: string = 'assets/dataQueries'
   private readonly jsonUrl: string = this.prefixUrl + '.json'
   private readonly filePrefix: string = 'file:';
   /**
    * OPTIONAL pass a queryService with method 
    * {@link /interfaces/TlnQueryServiceInterface.html#getData|getData}
    * to TlnPageViewComponent.
    **/
   @Input() queryService: TlnQueryServiceInterface;
   @Input() routePrefix: string;
   @ViewChild(CodemirrorComponent,{static:false}) codemirror: CodemirrorComponent;
   tlnQueryService: TlnQueryServiceInterface;
   fusekiResults: FusekiResults;
   errorLine: number = -1;
   quantDataHandler: QuantitativeDataHandler = new QuantitativeDataHandler(this);
   isLoadingResults: boolean = false;
   resultIndex: number = 0;
   query: string = '';
   queryInput: string = '';
   current_query: string = '';
   queryHasSyntaxError: boolean = false;
   curlQuery: string = '';
   queryFiles: QueryFile[] = [];
   selectedFileName: string;
   protected mapping: Mapping = { 
      current_query: { param: TLN_QUERY_PARAM, type: "string" },
   }

   constructor(private cacheService: CacheService, private localQueryService: TlnCacheQueryService,
      protected router: Router, protected activatedRoute: ActivatedRoute, private _dialog: MatBottomSheet, private http: HttpClient) { 
      super(router, activatedRoute);
   }

  ngOnInit() {
      this.http.get(this.jsonUrl).subscribe(queryFiles =>{
         this.queryFiles = <QueryFile[]>queryFiles;
      });
      this.tlnQueryService = (this.queryService != null) ? this.queryService : this.localQueryService;
      this.quantDataHandler.setQueryService(this.tlnQueryService);
      this.quantDataHandler.start_processing.subscribe(
         (started: boolean) =>{ 
            this.isLoadingResults = true;
      });
      this.quantDataHandler.processing_finished.subscribe(
         (started: boolean) =>{ 
            this.isLoadingResults = false;
      });
      super.ngOnInit();
  }
  
  private queryChanged(resetQuery: boolean) {
      if (this.errorLine > -1){
         this.errorLine = -1;
         this.codemirror.codeMirror.setOption('lineNumberFormatter' as any, (line:number): string =>{return String(line)})
      }
      if (resetQuery && this.selectedFileName != undefined && this.selectedFileName != null){
         this.openFile();
         this.queryHasSyntaxError = false;
      } else {
         const queryChanged = this.query != this.queryInput;
         this.queryHasSyntaxError = QueryJson.hasSyntaxError(this.query);
         this.updateQuery4Ext(queryChanged);
      }

  }
  private updateQuery4Ext(setAltQuery: boolean) {
      this.curlQuery = 'curl ' + this.tlnQueryService.baseUrl + ' -X POST --data \'query=' + encodeURI(this.query) + '\''
      if (setAltQuery) {
         this.current_query = JSON.stringify(this.query);
      } else {
         this.current_query = this.filePrefix + this.selectedFileName;
      }
      this.updateParams();
  }
  private markError(error: string){
     const matches = error.match(/(.*line\s)([0-9]+)(:.*)/s)
     if (matches != null && matches.length == 4){
         this.errorLine = Number(matches[2]);
         this.codemirror.codeMirror.setOption('lineNumberFormatter' as any, (line:number): string =>{
           if (line == this.errorLine){
               return 'E>';
           }
           return String(line)
         });
     }
  }
  private send() {
     const error = QueryJson.getSyntaxError(this.query); 
     //console.log('error', error);
     if (error != ''){
        this._dialog.open(QueryErrorComponent, { data: {error: error }});        
        this.markError(error);
     } else {
        this.quantDataHandler.getRawData4Query('fusekiResults', this.query);
     }
  }
  private cancel(){
      this.quantDataHandler.stop_processing.emit(true);
      this.isLoadingResults = false;
  }
  private copyToClipboard(curlQueryInput){
      curlQueryInput.select();  
      curlQueryInput.setSelectionRange(0, 99999); /* For mobile devices */

      /* Copy the text inside the text field */
      document.execCommand("copy");
  }

  openFile(){
     this.http.get(this.prefixUrl + '/' + this.selectedFileName, {responseType: 'text'}).subscribe(content =>{
         this.query = this.queryInput = content;
         this.updateQuery4Ext(false);
     });
  }
  protected readParams(params: Params){
     const old_current_query = this.current_query;
     super.readParams(params);
     if (old_current_query != this.current_query){
         if (this.current_query.startsWith(this.filePrefix)){
            this.selectedFileName = this.current_query.replace(this.filePrefix,'');
            this.openFile();
         } else {
            this.query = JSON.parse(this.current_query);
            this.curlQuery = 'curl ' + this.tlnQueryService.baseUrl + ' -X POST --data \'query=' + encodeURI(this.query) + '\''
         }
         const results = this.cacheService.getItem(this.dataPrefix + this.current_query);
         if (results != null){
            this.fusekiResults = results;
         }
     }
  }
  processData(){
     if (this.fusekiResults != undefined && this.fusekiResults != null && this.fusekiResults.results.bindings.length > 0){
        this.cacheService.setItem(this.dataPrefix + this.current_query, this.fusekiResults);
     }
  }

  isSelected = (o1: string, o2: string): boolean => {
     return o1 == o2;
  }
}
