import { Pipe, PipeTransform } from '@angular/core';
import { DataMapping } from './models';

@Pipe({
  name: 'sortSubjectsAlpha'
})
export class SortSubjectsAlphaPipe implements PipeTransform {

  transform(dataMappings: DataMapping[]): DataMapping[] {
    if (dataMappings.length > 0){
       dataMappings.sort((a, b) =>{
            return (a.subject < b.subject) ? -1 : 1;
       });
    }
    return dataMappings;
  }

}
