import {Component, Input, OnChanges} from '@angular/core';
import {NavTree} from '../navtree-directive.directive';
import {NavigationEntity} from '../../models/models';
import {NavigationServiceService} from '../../services/navigation-service.service';
import {ActivatedRoute} from '@angular/router';
import {NAVTREE_DEFS} from '../../constants';

@Component({
  selector: 'app-navigationlist-list-component',
  templateUrl: './navigationlist-list-component.component.html',
  styleUrls: ['./navigationlist-list-component.component.scss']
})
export class NavigationlistListComponentComponent implements OnChanges {

  @Input() navTree: NavTree;

  constructor(private naviService: NavigationServiceService, private route: ActivatedRoute) {
  }

  ngOnChanges() {
    if (this.navTree && document) {
      this.scrollOnToSelectedItem(this.route.snapshot.queryParamMap.get(this.navTree.qParam));
    }
  }

  onSelectNavItem(item: NavigationEntity) {
    if (this.navTree.selectedItem !== item.tlnEntity.id) {
     this.naviService.updateRoute({[this.navTree.qParam] : item.tlnEntity.id});
     this.scrollOnToSelectedItem(item.tlnEntity.id);
      } else { // If clicked the already selected item ...
      const qParams = {};
      let relPath;
      if (this.navTree.idx < NAVTREE_DEFS.length - 1) { // if it is not the last tab/tree we switch one tab deeper
        qParams['navTabIdx'] = this.navTree.idx + 1;
        this.naviService.updateRoute(qParams);
      }
      if (this.navTree.idx === NAVTREE_DEFS.length - 1 && this.route.children[0].snapshot.routeConfig.path === 'tln-manuscript') { // if it is the last available tab/tree we change the context
        qParams['contextView'] = NAVTREE_DEFS[NAVTREE_DEFS.findIndex(tree => tree.id === this.navTree.id)].itemQParam;
        relPath = 'tln-viewer';
        this.naviService.updateRoute(qParams, relPath);
      }
    }
  }

  scrollOnToSelectedItem(itemId: string ) {
    window.setTimeout(() => {
      if (document.getElementById(itemId)) {
        const offSetTop = document.getElementById(itemId).offsetTop;
        document.getElementById('navlist').scrollTop = offSetTop - 100;
      }
    }, 100);
  }
}
