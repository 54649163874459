import { Parser, Generator } from 'sparqljs';


export interface ParsedQuery {
   where: any[];
}
export interface Arg {
   termType: string; 
   value: string;
}
export class Literal implements Arg {
   termType: string = "Literal"
   value: string;
   constructor(value: string){
      this.value = value;
   }
}
export class NamedNode implements Arg {
   termType: string = "NamedNode"
   value: string;
   constructor(value: string){
      this.value = value;
   }
}
export class Variable implements Arg {
   termType: string = "Variable"
   value: string;
   constructor(value: string){
      this.value = value;
   }
}
export interface Triple {
   subject: Arg;
   predicate: Arg;
   object: Arg;
}
export interface WhereBgp {
   type: string;
   triples: Triple[];
}
export class QueryObject {
   type: string;
   constructor(type: string){
      this.type = type;
   }
}
export class Operation extends QueryObject {
   args: Arg[];
   operator: string;
   constructor(operator: string, args: Arg[] ){
      super("operation");
      this.operator = operator;
      this.args = args;
   }
}
export class BGP extends QueryObject implements WhereBgp {
   triples: Triple[];
   constructor(triples: Triple[] ){
      super("bgp");
      this.triples = triples;
   }
}

export class QueryJson {
   public static toString(parsedQuery: ParsedQuery): string {
      const sparqlGenerator = new Generator({});
      return sparqlGenerator.stringify(parsedQuery);
   }
   public static parseQuery(query: string): ParsedQuery {
      const parser = new Parser();  
      return parser.parse(query);
   }
   public static createFilterObject(filters: Object[] ): Object{
      let filterObject = { type: "filter", expression: null };
      if (filters.length > 1){
         filterObject.expression = { type: "operation", operator: "||", args: [] }
         let filter = filters.pop()
         this.pushFilter2Args(filters, filterObject.expression.args, filter);
      } else {
         filterObject.expression = filters[0];
      }
      return filterObject;
   }
   public static createEqualsOperation(id: string, variable_name: string): Operation {
      return new Operation("=", [ new Variable(variable_name), new NamedNode(id) ]);
   }
   public static createRegexOperation(variable_name: string, regex: string, flag?: string): Operation {
      let args: Arg[] = [ new Variable(variable_name), new Literal(regex) ]
      if (flag != undefined && flag != null && flag != '') {
         args.push(new Literal(flag))
      }
      return new Operation("regex", args);
   }
   private static pushFilter2Args(filters: Object[], args: Object[], latestFilter: Object){
      if (filters.length > 1){
         let filter = filters.pop();
         let disjunction = { type: "operation", operator: "||", args: [] }
         disjunction.args.push(filter);
         disjunction.args.push(latestFilter);
         this.pushFilter2Args(filters, args, disjunction);
      } else {
         args.push(latestFilter);
         args.push(filters.pop());
      }
   }
   public static hasSyntaxError(query: string): boolean {
      const parser = new Parser();  
      try {
         const parsedQuery = parser.parse(query);
      } catch(e){
         return true;
      }
      return false;
   }
   public static getSyntaxError(query: string): string {
      const parser = new Parser();  
      try {
         const parsedQuery = parser.parse(query);
      } catch(e){
         return String(e);
      }
      return '';
   }
   public static splitWhereBGP(bgp: WhereBgp, key: string): BGP[] {
      let triplesWithKey = bgp.triples.filter(triple =>triple.subject.value == key)
      let triplesWithOutKey = bgp.triples.filter(triple =>triple.subject.value != key)
      return [ new BGP(triplesWithKey), new BGP(triplesWithOutKey) ]
   }
}
