import { Pipe, PipeTransform } from '@angular/core';
import { BasicResultBindingElement } from '../datatypes/basic_datatype';

@Pipe({
  name: 'toIds'
})
export class ToIdsPipe implements PipeTransform {

  transform(basicElements: BasicResultBindingElement[]): string[] {
     return basicElements.map(element =>element.id);
  }

}
