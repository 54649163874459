import {ExtraOptions,RouterModule, Routes} from '@angular/router';
import {SupportComponent} from './support/support.component';
import {HomeComponent} from './home.component';
import {ImpressumComponent} from './impressum.component';
import {ProjectComponent} from './project.component';
import {QueryComponent} from './query.component';
import {ConceptionComponent} from './conception.component';
import {ContentViewTabComponentComponent} from './content-view-tab-component/content-view-tab-component.component';
import {CONTENT_ROUTES} from './content-view-tab-component/content-view-routes';
import { CONTENT_VIEW_ROUTE, CONCEPTION_ROUTE, DATA_ROUTE, DOCUMENTATION_ROUTE, HELP_ROUTE, HOME_ROUTE, IMPRESSUM_ROUTE, PROJECT_ROUTE } from './constants';

const APP_ROUTES: Routes = [
  {path: '', redirectTo: HOME_ROUTE, pathMatch: 'prefix'},
  {path: HOME_ROUTE, component: HomeComponent},
  {path: HELP_ROUTE, component: SupportComponent},
  {path: CONCEPTION_ROUTE, component: ConceptionComponent},
  {path: CONTENT_VIEW_ROUTE, component: ContentViewTabComponentComponent, children: CONTENT_ROUTES},
  {path: DATA_ROUTE, component: QueryComponent},
  {path: PROJECT_ROUTE, component: ProjectComponent},
  {path: IMPRESSUM_ROUTE, component: ImpressumComponent},
  {path: '**', redirectTo: HOME_ROUTE, pathMatch: 'prefix'} // any other unknown url will be forwarded to home
];
const routerOptions: ExtraOptions = {
  useHash: false,
  anchorScrolling: 'enabled'
};

export const routing = RouterModule.forRoot(APP_ROUTES, routerOptions);
