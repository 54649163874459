import { Pipe, PipeTransform } from '@angular/core';
import { Namespace, Ontology } from './models';

@Pipe({
  name: 'ontologies2namespaces'
})
export class Ontologies2namespacesPipe implements PipeTransform {

  transform(ontologies: Ontology[], optional_namespaces?: Namespace[]): Namespace[] {
     let namespaces: Namespace[] = [];
     ontologies.forEach(ontology =>{
        namespaces.push({
           prefix: ontology.prefix,
           isExternal: false,
           iri: ontology.iri + '#' });
    });
    if (optional_namespaces != undefined && optional_namespaces != null){
      return namespaces.concat(optional_namespaces);
    }
    return namespaces;
  }

}
