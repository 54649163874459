import {NavTreeDef} from './models/models';

export const CONTENT_VIEW_ROUTE: string = 'contentView';
export const DOCUMENTATION_ROUTE: string = 'doku';
export const DATA_ROUTE: string = 'query';
export const CONCEPTION_ROUTE: string = 'conception';
export const HOME_ROUTE: string = 'home';
export const HELP_ROUTE: string = 'support';
export const IMPRESSUM_ROUTE: string = 'impressum';
export const PROJECT_ROUTE: string = 'project';
import { BASE_URL } from './tln-edition/constants';
export { BASE_URL } from './tln-edition/constants';
//export const BASE_URL: string = 'https://nietzsche.fuseki.services.dasch.swiss/nietzsche';
export const NAVTREE_DEFS: NavTreeDef[] = [
  { id: 'manuscript',
    idx: 0,
    isActive: true,
    label: 'Manuskripte',
    itemQParam: 'manuscript',
    entries: [],
    apiDef: {
      type: 0, // rdf
      baseUrl: BASE_URL,
      dataArray: 'results.bindings',
      query: 'manuscripts.rq',
      mapping: { // maps the properties of the reponse to tha NavTabDef properties, which are displayed
        id: 'manuscript.value', // Short id, iri in most cases
        iri: 'manuscript.value', // iri
        type: 'type.value',
        label: 'title.value',
        description: 'gsaSignature.value',
        avatar: 'thumbImage.value'
      }
    }
  }, {
    id: 'page',
    idx: 1,
    isActive: false,
    label: 'Seiten',
    itemQParam: 'page',
    entries: [],
    apiDef: {
      type: 0, // rdf
      baseUrl: BASE_URL,
      dataArray: 'results.bindings',
      query: 'getPageData.rq',
      mapping: {
        id: 'page.value', // Short id, iri in most cases
        iri: 'page.value', // iri
        label: 'pageNumber.value',
        thumb: 'thumb.value',
        idx: 'pageNumber.value',
        svg: 'svgUrl.value',
      }
    }
  }
];


// Queries
export const RQ_CROSSREF_TREE_MANUSCRIPTS: string = `
PREFIX data: <http://rdfh.ch/projects/0068#>
PREFIX tln: <http://www.nie.org/ontology/nietzsche#>
PREFIX rdf: <http://www.w3.org/1999/02/22-rdf-syntax-ns#>
SELECT ?manuscript ?title ?thumbImage ?gsaSignature WHERE {
  ?manuscript a tln:ManuscriptUnity;
      tln:hasTitle ?title;
      tln:hasManuscriptType "Mappe";
      tln:hasPages/rdf:first/tln:hasFaksimileImage/tln:hasThumburl ?thumbImage ;
      tln:hasGsaSignature ?gsaSignature .
      
}`;

export const RQ_CROSSREF_TREE_PAGES: string = `
PREFIX tln: <http://www.nie.org/ontology/nietzsche#>
PREFIX rdf: <http://www.w3.org/1999/02/22-rdf-syntax-ns#>
PREFIX xsd: <http://www.w3.org/2001/XMLSchema#>
SELECT DISTINCT ?page ?pageNumber ?type WHERE {
    ?s (tln:hasPages/(rdf:rest*)/rdf:first) ?page.
    ?page tln:hasNumber ?pageNumber.
    ?page a tln:Page.
    BIND (tln:page AS ?type)
    BIND (xsd:integer(REPLACE(?pageNumber, "\\\\D+", "")) AS ?sorting)
  }
  ORDER BY (?sorting)
`;

export const RQ_CROSSREF_TREE_LINES: string = `
PREFIX tln: <http://www.nie.org/ontology/nietzsche#>
PREFIX rdf: <http://www.w3.org/1999/02/22-rdf-syntax-ns#>
PREFIX xsd: <http://www.w3.org/2001/XMLSchema#>
SELECT DISTINCT ?line ?lNumber ?type WHERE {
    ?s (tln:hasLines/(rdf:rest*)/rdf:first) ?line.
    ?line a tln:Line.
    BIND (tln:line AS ?type)
  OPTIONAL {
      ?line tln:lineHasNumber ?lNumber.
    }
  }
  ORDER BY (?lNumber)
`;

export const RQ_CROSSREF_TREE_WORDS: string = `
PREFIX tln: <http://www.nie.org/ontology/nietzsche#>
PREFIX rdf: <http://www.w3.org/1999/02/22-rdf-syntax-ns#>
PREFIX xsd: <http://www.w3.org/2001/XMLSchema#>
PREFIX homotypic: <http://www.nie.org/ontology/homotypic#>
SELECT DISTINCT ?word ?line ?text ?type WHERE {
    ?word tln:wordBelongsToLine ?line.
    ?word a tln:SimpleWord.
    BIND(tln:SimpleWord AS ?type)
    OPTIONAL {
      ?word homotypic:hasText ?text.
    }
  }
  ORDER BY (?word)
`;


export const CROSSREF_TREE_DEFS: NavTreeDef[] = [
  { id: 'manuscript',
    idx: 0,
    isActive: true,
    label: 'Manuskript ',
    itemQParam: 'manuscript',
    entries: [],
    apiDef: {
      type: 0, // rdf
      baseUrl: BASE_URL,
      dataArray: 'results.bindings',
      query: RQ_CROSSREF_TREE_MANUSCRIPTS,
      mapping: { // maps the properties of the reponse to tha NavTabDef properties, which are displayed
        id: 'manuscript.value', // Short id, iri in most cases
        iri: 'manuscript.value', // iri
        type: 'type.value',
        label: 'title.value',
        description: 'gsaSignature.value',
        avatar: 'thumbImage.value'
      }
    }
  }, {
    id: 'page',
    idx: 1,
    isActive: false,
    label: 'S. ',
    itemQParam: 'page',
    entries: [],
    apiDef: {
      type: 0, // rdf
      baseUrl: BASE_URL,
      dataArray: 'results.bindings',
      query: RQ_CROSSREF_TREE_PAGES,
      mapping: {
        id: 'page.value', // Short id, iri in most cases
        iri: 'page.value', // iri
        label: 'pageNumber.value',
        type: 'type.value'
      }
    }
  }, {
    id: 'line',
    idx: 2,
    isActive: false,
    label: 'Zeile ',
    itemQParam: '',
    entries: [],
    apiDef: {
      type: 0, // rdf
      baseUrl: BASE_URL,
      dataArray: 'results.bindings',
      query: RQ_CROSSREF_TREE_LINES,
      mapping: {
        id: 'line.value', // Short id, iri in most cases
        iri: 'line.value', // iri
        label: 'lNumber.value',
        type: 'type.value'
      }
    }
  }, {
    id: 'word',
    idx: 3,
    isActive: false,
    label: '',
    itemQParam: '',
    entries: [],
    apiDef: {
      type: 0, // rdf
      baseUrl: BASE_URL,
      dataArray: 'results.bindings',
      query: RQ_CROSSREF_TREE_WORDS,
      mapping: {
        id: 'word.value', // Short id, iri in most cases
        iri: 'word.value', // iri
        label: 'text.value',
        type: 'type.value'
      },
      paramTriple: 2
    }
  }
];

// GENERIC TREES

// Queries
export const RQ_GENERIC_TREE_ROOT: string = `
PREFIX data: <http://rdfh.ch/projects/0068#>
PREFIX tln: <http://www.nie.org/ontology/nietzsche#>
PREFIX rdf: <http://www.w3.org/1999/02/22-rdf-syntax-ns#>
SELECT DISTINCT ?manuscript ?title ?type ?gsaSignature WHERE {
  ?manuscript a tln:ManuscriptUnity;
      tln:hasTitle ?title;
      tln:hasManuscriptType "Mappe";
      tln:hasGsaSignature ?gsaSignature .
      OPTIONAL {
      tln:ManuscriptUnity a ?type
      }
}`;

export const RQ_GENERIC_RDF_PROPERTY_TREE: string =  `
PREFIX rdfs: <http://www.w3.org/2000/01/rdf-schema#>
SELECT DISTINCT ?p ?type ?label ?comment WHERE {
    ?s ?p ?o.
  OPTIONAL {
    ?p rdfs:label ?label.
    ?p rdfs:comment ?comment.
    ?p a ?sth.
    }
  }
  ORDER BY (?label)
  LIMIT 10
  `;

export const RQ_GENERIC_OBJECT_TREE: string = `
PREFIX rdfs: <http://www.w3.org/2000/01/rdf-schema#>
SELECT DISTINCT ?o ?type ?label ?comment WHERE {
    ?s ?p ?o.
  OPTIONAL {
    ?o rdfs:label ?label.
    ?o rdfs:comment ?comment.
    ?o a ?sth.
    }
  }
  ORDER BY (?label)
  LIMIT 10
  `;

export const GENERIC_ROOT_TREE_DEF: NavTreeDef[] = [
  { id: 'root',
    idx: 0,
    isActive: true,
    label: 'Manuskript ',
    itemQParam: 'manuscript',
    entries: [],
    apiDef: {
      type: 0, // rdf
      baseUrl: BASE_URL,
      dataArray: 'results.bindings',
      query: RQ_GENERIC_TREE_ROOT,
      mapping: { // maps the properties of the reponse to tha NavTabDef properties, which are displayed
        id: 'manuscript.value', // Short id, iri in most cases
        iri: 'manuscript.value', // iri
        type: 'type.value',
        label: 'title.value',
        description: 'gsaSignature.value'
      }
    }
  },
  { id: 'property',
    idx: 1,
    isActive: true,
    label: 'Property ',
    itemQParam: '',
    entries: [],
    apiDef: {
      type: 0, // rdf
      baseUrl: BASE_URL,
      dataArray: 'results.bindings',
      query: RQ_GENERIC_RDF_PROPERTY_TREE,
      mapping: { // maps the properties of the reponse to tha NavTabDef properties, which are displayed
        id: 'p.value', // Short id, iri in most cases
        iri: 'p.value', // iri
        type: 'type.value',
        label: 'label.value',
        description: 'comment.value'
      }
    }
  },
  { id: 'object',
    idx: 1,
    isActive: true,
    label: 'Object ',
    itemQParam: '',
    entries: [],
    apiDef: {
      type: 0, // rdf
      baseUrl: BASE_URL,
      dataArray: 'results.bindings',
      query: RQ_GENERIC_OBJECT_TREE,
      mapping: { // maps the properties of the reponse to tha NavTabDef properties, which are displayed
        id: 'o.value', // Short id, iri in most cases
        iri: 'o.value', // iri
        type: 'type.value',
        label: 'label.value',
        description: 'comment.value'
      }
    }
  }
];
