import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { externalAssignStyle, Continuation, Identifier, Line, LineStub, TextField, Word} from '../models';
import { PageViewService } from '../page-view.service';
import { HIGHTLIGHT_CASES } from '../highlight_status';
import { createLocalZoomFactor } from '../common/shared_functions';
import { Matrix } from '../common/matrix';
/**
 * This component displays an Array of lines.
 **/
@Component({
  selector: 'margin-field',
  templateUrl: './margin-field.component.html',
  styleUrls: ['./margin-field.component.css']
})
export class MarginFieldComponent implements OnInit, OnChanges {
   /**
    * scrollable HTML-container of this textfield
    **/
   @Input() container: HTMLElement;
   /**
    * the hovered status for a line
    **/
   HOVERED_STATUS: string = HIGHTLIGHT_CASES.LINE_HOVERED
   SELECTED_STATUS: string = HIGHTLIGHT_CASES.SELECTED_LINE
   /**
    * the currently hovered line
    * */
   hoveredLine?: Line;
   /**
    * the currently hovered reference line
    * */
   hoveredReferenceLine?: LineStub;
   /**
    * the currently hovered word
    * */
   hoveredWord?: Word;
   /**
    * an Array of lines that will be displayed.
    **/
   @Input() lines: Line[];
   /**
    * the height of a line rect.
    **/
   line_height: number = 8;
   /**
    * the length of the line rect.
    **/
   line_length: number = 10;
   /**
    * x coordinate of the line rect.
    **/
   line_x: number = 5;
   /**
    * the height of the margin field.
    **/
   margin_height: number = 973.91998;
   /**
    * geometrical top position of the margin field.
    **/
   margin_top: number = 0;
   /**
    * the width of the margin field.
    **/
   margin_width: number = 30;
   /**
    * specifies reference type that should be displayed
    **/
   @Input() showReference: string = "to"
   /**
    * show all lines or even lines only (default)
    **/
   @Input() linesWithWords: Line[] = []; 
   /**
    * The area of the image that will be displayed by {@link /components/TextFieldComponent.html|TextFieldComponent}.
    * The height of the text_field determines  {@link #margin_height|margin_height}, while its top position
    * determines {@link #margin_top|margin_top}.
    **/
   @Input() text_field: TextField;
   /**
    * TODO.
    **/
   @Input() transform: string;
   @Input() rotation: number = 0;
   matrix: string = 'matrix(1 0 0 1 0 0)';
   /**
    * The viewbox of this svg component.
    **/
   viewBox: string = '';
   /**
    * initial maximum height of margin field.
    **/
   @Input() max_height: number = -1;
   /**
    * initial maximum width of margin field.
    **/
   @Input() max_width: number = -1;
   /**
    * identifiers of selected lines that should be highlighted.
    **/
   @Input() selectedLines: Identifier[] = [];
   /**
    * global zoom factor
    **/
   @Input() zoomFactor: number = 1;
   /**
    * An optional function that can be passed to this component in order to return a (svg-)style object 
    * to the line rects. This function allows the user to extend the style of this component.
    * E.g. by returning { fill: blue } the function overwrites the default behaviour and sets
    * the default highlight color to blue.
    **/
   @Input('assignStyle') extAssignStyle?: externalAssignStyle;
   /**
    * local zoom factor
    **/
   @Input() local_zoom: number = -1;
   updateLines: boolean = false;

   /**
    * @param lineservice an information source about (un-)hovered and clicked Lines/Words.
    **/
   constructor( private lineservice: PageViewService) { }

   /**
   * Initialize geometrical information and subscribe to {@link /injectables/PageViewService.html|PageViewService}.
   **/
   ngOnInit() {
      if (this.max_height == -1 && this.max_width == -1){
         this.max_height = screen.availHeight;
      }
      this.viewBox = 0 + ' ' +  this.margin_top + ' ' + this.margin_width + ' ' + this.margin_height;
      if (this.text_field != null) {
         this.updateViewBox()
      } 
      this.lineservice.onHoveredLine.subscribe(
         (changedLine: Line) => { this.hoveredLine = changedLine;}
      );
      this.lineservice.onHoveredContinuation.subscribe(
         (changedContinuation: Continuation) => { this.hoveredReferenceLine = changedContinuation.reference.line;}
      );
      this.lineservice.offHoveredContinuation.subscribe(
         (changedContinuation: Continuation) => { this.hoveredReferenceLine = null}
      );
      this.lineservice.offHoveredLine.subscribe(
       (changedLine: Line) => { this.hoveredLine = null; }
      );
      this.lineservice.onHoveredWord.subscribe(
         (changedWord: Word) => { this.hoveredWord = changedWord;}
      );
      this.lineservice.offHoveredWord.subscribe(
       (changedWord: Word) => { this.hoveredWord = null; }
      );
   }
   /**
   * Update viewBox if there is a change.
   **/
   ngOnChanges(changes: SimpleChanges) {
      if (this.text_field != null) {
         this.updateViewBox()
      } 
   }
   /**
   * Update viewBox: set 
   * {@link #margin_height|margin_height}, 
   * {@link #margin_top|margin_top},
   * {@link #viewBox|viewBox}
   * and {@link #local_zoom|local_zoom} according to 
   * {@link #text_field|text_field}.
   **/
   private updateViewBox(){
     this.lines.forEach(line =>line.datatype = "Line");
     if (this.showReference == 'to'){
       this.margin_width = (this.lines.some(line =>line.continuesTo != null || line.continuesTo != undefined)) ? this.line_length*2+30 : 30;
     } else {
       let hasReference = this.lines.some(line =>line.continuesFrom != null || line.continuesFrom != undefined)
       this.margin_width = (this.showReference == 'from' && hasReference) ? this.line_length*2+30 : 30;
       this.line_x = (this.showReference == 'from' && hasReference) ? 2*this.line_length : 5;
     }
     this.margin_height = this.text_field.height;
     this.margin_top = this.text_field.top;
     this.viewBox = 0 + ' ' +  this.margin_top + ' ' + this.margin_width + ' ' + this.margin_height;
     if (this.local_zoom == -1 && this.text_field != undefined && this.text_field != null){
         this.local_zoom = createLocalZoomFactor(this.text_field, this.max_height, this.max_width);
     }
     if (this.rotation == 180){
        this.matrix = (this.transform == null) 
           ? 'matrix(-1 0 0 -1 0 0)' 
           : 'matrix(-1 0 0 -1 0 ' + -1*(Number(this.text_field.height)-Number(this.text_field.width))*this.local_zoom*this.zoomFactor + ')';
     } else {
         this.matrix = 'matrix(1 0 0 1 0 0)'
     }
   }
   /**
   * Get the hover status of a line, i.e. whether it is hovered 
   * ({@link /miscellaneous/enumerations.html#HIGHTLIGHT_CASES|HIGHTLIGHT_CASES.LINE_HOVERED})
   * or not ({@link /miscellaneous/enumerations.html#HIGHTLIGHT_CASES|HIGHTLIGHT_CASES.DEFAULT}).
   **/
   private getHoverStatus(line: Line): string {
     if ( (this.hoveredLine != undefined && this.hoveredLine != null && line.id == this.hoveredLine.id) 
     || (this.hoveredReferenceLine != undefined && this.hoveredReferenceLine != null && line.id == this.hoveredReferenceLine.id)
     || (this.hoveredWord != undefined && this.hoveredWord != null && line.id == this.hoveredWord.line))  {
         return HIGHTLIGHT_CASES.LINE_HOVERED; 
     } else if (this.selectedLines.length > 0 && this.selectedLines.indexOf(line.id) > -1) {
        return HIGHTLIGHT_CASES.SELECTED_LINE
     } else {
         return HIGHTLIGHT_CASES.DEFAULT; 
     }
   }
   /**
    * Assign a style to the rects of a line.
    **/
   private assignStyle(line: Line, hoveredWord: Word, hoveredLine: Line, hoverStatus: string): Object {
      return (this.extAssignStyle != null) ? this.extAssignStyle(line, hoveredWord, hoveredLine, hoverStatus) : {};
   }

   private hasWordsOnLine(line: Line) : boolean {
      return this.linesWithWords.length > 0 && this.linesWithWords.indexOf(line) > -1;
   }
}
