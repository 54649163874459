import { Component, OnInit } from '@angular/core';
import { Namespace, Ontology } from '../../ontology-viewer/models';
import { BASE_URL } from '../constants';

@Component({
  selector: 'tln-ontology-viewer',
  templateUrl: './tln-ontology-viewer.component.html',
  styleUrls: ['./tln-ontology-viewer.component.css']
})
export class TlnOntologyViewerComponent implements OnInit {
   baseUrl = BASE_URL; 
   ontologies: Ontology[] = [ { prefix: 'tln', name: 'The late Nietzsche', iri: 'http://www.nie.org/ontology/nietzsche' },
                              { prefix: 'homotypic', name: 'Homotypic', iri: 'http://www.nie.org/ontology/homotypic' } , 
                              { prefix: 'standoff', name: 'Standoff Markup', iri: 'http://www.nie.org/ontology/standoff' } ] 
   namespaces: Namespace[] = [{ prefix: 'text', iri: 'http://www.e-editiones.ch/ontology/text#', isExternal: true },
                              { prefix: 'document', iri: 'https://www.e-editiones.ch/ontology/document#', isExternal: true },
                              { prefix: 'document', iri: 'http://e-editiones.ch/ontology/document#', isExternal: true },
                              { prefix: 'text', iri: 'https://www.e-editiones.ch/ontology/text#', isExternal: true }]
  constructor() { }

  ngOnInit() {
  }

}
