import { Component, EventEmitter, Input, Output, OnInit, ViewChild } from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {Sort} from '@angular/material/sort';
import { PaginatorResultStatus } from '../common/paginator-result-status';
import { FusekiResults, FusekiBindings } from '../datatypes/basic_datatype';
import { MySort} from './sort';
import { QuantQuery, PageIndexUpdater } from '../models';

@Component({
  selector: 'fuseki-table',
  templateUrl: './fuseki-table.component.html',
  styleUrls: ['./fuseki-table.component.css']
})
export class FusekiTableComponent  implements OnInit, PageIndexUpdater {
  @Input() fusekiResults: FusekiResults;
  @Input() resultIndicesIndex: number = 0;
  @Input() quantQuery: QuantQuery;
  @Input() routePrefix: string;
  @Output() quantQueryChanged = new EventEmitter<QuantQuery>();
  @Output() change = new EventEmitter<number>();
  @ViewChild(MatPaginator,{static:false}) paginator: MatPaginator;
  paginatorResultStatus: PaginatorResultStatus = new PaginatorResultStatus(10, this);
  sort: MySort = new MySort();

  constructor() {}

   ngOnInit() { 
      if (this.quantQuery != undefined && this.quantQuery != null){
         this.paginatorResultStatus.updateResultRange(this.quantQuery.resultIndices[this.resultIndicesIndex]);
      } 
   }
  private sortData(sort: Sort){
      this.paginatorResultStatus.updateResultRange(0);
      if (this.paginator != undefined && this.paginator != null){
         this.paginator.pageIndex = 0;
      }
      if (this.quantQuery != undefined && this.quantQuery != null){
         this.quantQuery.sortArray[this.resultIndicesIndex] = sort;
         this.quantQuery.resultIndices[this.resultIndicesIndex] = 0;
         this.quantQueryChanged.emit(this.quantQuery);
      }  else {
         this.fusekiResults.results.bindings = this.fusekiResults.results.bindings.sort((a, b) => {
            let valueA = <number|string>(a[sort.active]['value']);
            let valueB = <number|string>(b[sort.active]['value']);
            if ('datatype' in a[sort.active] && String(a[sort.active]['datatype']).endsWith('integer')){
               valueA = Number(valueA);
               valueB = Number(valueB);
            } 
            return this.compare(valueA, valueB, sort.direction === 'asc')
         });
      }
  }
  private compare(a: number | string, b: number | string, isAsc: boolean): number {
      return (a < b ? -1 : 1) * (isAsc ? 1: -1);
  }

}
