import { Component, Input, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { DataHandler } from '../data_handler';
import { TlnQueryServiceInterface, DataProcessor, Representation} from '../models';
import { TlnCacheQueryService } from '../services';
import { TLN_QUANT_ROUTE, TLN_MANUSCRIPT_ROUTE, TLN_VIEWER_ROUTE, TLN_CONTEXT_VIEW_PARAM, TLN_FULLSCREEN_PARAM, TLN_FIND_PARAM, TLN_PAGE_PARAM, TLN_MANUSCRIPT_PARAM, TLN_QUANT_QUERY_PARAM, 
   TLN_QUERY_PARAM, TLN_RESULT_INDEX_PARAM,TLN_SELECTED_LINES_PARAM, TLN_TEXT_GENETIC_ORDER_PARAM, TLN_VIEW_OPTION_PARAM, TLN_ZOOM_PARAM, VIEW_OPTIONS, ONTOLOTY_PREFIX } from '../constants';
import { Mapping } from '../route-reader';
import { RouteUpdater } from '../route-updater';
import { CacheService } from '../common/cache.service';
import { TlnType } from '../datatypes/type';
import { TlnPage } from '../datatypes/page';
import { WordRepresentation } from '../datatypes/word';
import { ManuscriptStub } from '../datatypes/manuscript';
import { LineRepresentation} from '../datatypes/line';
import { DeletionPathRepresentation} from '../datatypes/deletion_path';
import { DATA_PREFIX } from '../constants';


@Component({
  selector: 'tln-type',
  templateUrl: './tln-type.component.html',
  styleUrls: ['./tln-type.component.css']
})
export class TlnTypeComponent extends RouteUpdater implements DataProcessor, OnInit {
  @Input() show_reference_and_id: boolean = false;
  @Input() input: string;
  @Input() queryService: TlnQueryServiceInterface;
  @Input() routePrefix: string;
   dataHandler: DataHandler = new DataHandler(this);
   tlnQueryService: TlnQueryServiceInterface;
   dataType: TlnType;
   representation: Representation;
   showIri: boolean = false;

   constructor(private cacheService: CacheService, private localQueryService: TlnCacheQueryService,
      protected router: Router, protected activatedRoute: ActivatedRoute) { 
      super(router, activatedRoute);
   }

  ngOnInit() {
      this.tlnQueryService = (this.queryService != null) ? this.queryService : this.localQueryService;
      this.dataHandler.addHandler('dataType', { 'handler': TlnType, 'process_data': this });
      this.dataHandler.setQueryService(this.tlnQueryService);
      if (this.input != undefined && this.input != null && this.input.startsWith(DATA_PREFIX)){
         this.dataHandler.getData('dataType', this.input);
      }
  }

  public processData(){
     if (this.dataType != undefined && this.dataType != null){
        const dataType = this.dataType.type.substring(this.dataType.type.lastIndexOf('#')+1);
        let unknownDataType = false;
        switch (dataType) {
            case 'Page':{
               this.dataHandler.addHandler('representation', { 'handler': TlnPage});
               break;
            }
            case 'ArchivalManuscriptUnity':{
               this.dataHandler.addHandler('representation', { 'handler': ManuscriptStub});
               break;
            }
            case 'Word':{
               this.dataHandler.addHandler('representation', { 'handler': WordRepresentation});
               break;
            }
            case 'SimpleWord':{
               this.dataHandler.addHandler('representation', { 'handler': WordRepresentation});
               break;
            }
            case 'Line':{
               this.dataHandler.addHandler('representation', { 'handler': LineRepresentation});
               break;
            }
            case 'WordDeletionPath':{
               this.dataHandler.addHandler('representation', { 'handler': DeletionPathRepresentation});
               break;
            }
            default:{
               unknownDataType = true; 
            }
         }
         if (!unknownDataType){
            this.dataHandler.getData('representation', this.input);
         }
     }
  }
}
