import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CacheService {
  cache = {};

  constructor() { }

  public getItem(key: string): any {
      try {
         return JSON.parse(localStorage.getItem(key));
      } catch(e) {
         try {
            return this.cache[key];
         } catch(e){
            return null;
         }
      }
  }
  public removeItem(key: string){
      try { delete this.cache[key]; } catch(e){}
      try { localStorage.removeItem(key); } catch(e){}
  }
  public setItem(key: string, data: any){
      this.cache[key] = data;
      try {
         localStorage.setItem(key, JSON.stringify(data));
      } catch(e) {
         localStorage.clear();
      }
  }
}
