import { Router, ActivatedRoute, Params } from '@angular/router';
import { Mapping, RouteReader } from './route-reader';

export class RouteUpdater extends RouteReader {
  protected mapping: Mapping;
  protected routerParams: Params;
  protected currentRoute: string;
  parentActivatedRoute: ActivatedRoute;

  constructor(protected router: Router, protected activatedRoute: ActivatedRoute ) { 
      super(router, activatedRoute);
      if(this.currentRoute == undefined || this.currentRoute == null){
         this.currentRoute = (this.activatedRoute.snapshot.routeConfig != null)
            ? this.activatedRoute.snapshot.routeConfig.path : null;
      }
  }
  public setParam(key: string, value: any){
     if(this.mapping.hasOwnProperty(key)){
        this[key] = value;
        this.updateParams();
     }
  }
  private removeData(dataBearer: any) {
     if (typeof dataBearer != 'string' && typeof dataBearer != 'number' && typeof dataBearer != 'boolean'){
        if (Array.isArray(dataBearer)){
            dataBearer.forEach(data =>this.removeData(data));
        } else {
           dataBearer['data'] = null;
           Object.values(dataBearer).forEach(item =>{
               if (item != null){
                  this.removeData(item);
               }
           });
        }
     }
  }
  protected updateParams(launch?: boolean) {
     let newRouterParam = {};
     for(let key of Object.keys(this.mapping)){
         let paramsKey = this.mapping[key]['param'];
         if(this[key] != null){
            if (this.mapping[key]['type'] == 'complex' || (Array.isArray(this[key]) && this[key].length > 0)){
               this.removeData(this[key]);
               newRouterParam[paramsKey] = JSON.stringify(this[key]);
            } else if (this.mapping[key]['type'] == 'URI' || this.mapping[key]['type'] == 'URL') {
               newRouterParam[paramsKey] = encodeURI(this[key]);
            } else {
               newRouterParam[paramsKey] = this[key];
            }
         }
     }
     let parentActivatedRoute = (this.activatedRoute.parent != null) ? this.activatedRoute.parent : this.parentActivatedRoute;
     if(parentActivatedRoute != undefined && parentActivatedRoute != null){
        parentActivatedRoute.url.subscribe(url=>{
           let parentPath = url[0].path;
           if (launch != undefined && launch){
               let link = this.router.createUrlTree([ parentPath + '/' + this.currentRoute], { queryParams: newRouterParam, queryParamsHandling: 'merge' });
               window.open(link.toString(), '_blank')
           } else {
               this.router.navigate([parentPath + '/' + this.currentRoute], { queryParams: newRouterParam, queryParamsHandling: 'merge' });
           }
        });
     } else {
        if (launch != undefined && launch){
            let link = this.router.createUrlTree([ this.currentRoute], { queryParams: newRouterParam, queryParamsHandling: 'merge' });
            window.open(link.toString(), '_blank')
        } else {
            this.router.navigate([this.currentRoute], { queryParams: newRouterParam, queryParamsHandling: 'merge' });
        }
     }
  }
}
