import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml, SafeStyle, SafeScript, SafeUrl, SafeResourceUrl } from '@angular/platform-browser'
import { Router, ActivatedRoute, Params } from '@angular/router';
import { TLN_CROSSREF_ROUTE, TLN_MANUSCRIPT_ROUTE, TLN_VIEWER_ROUTE, TLN_CONTEXT_VIEW_PARAM, TLN_FULLSCREEN_PARAM, TLN_FIND_PARAM, TLN_PAGE_PARAM, TLN_MANUSCRIPT_PARAM,
   TLN_SELECTED_LINES_PARAM, TLN_TEXT_GENETIC_ORDER_PARAM, TLN_VIEW_OPTION_PARAM, TLN_ZOOM_PARAM, VIEW_OPTIONS, ONTOLOTY_PREFIX } from '../constants';
import { DataHandler } from '../data_handler';
import { ReconstructedKonvolut, TlnExtManuscript, ManuscriptEarlierDescription, ManuscriptDescription, ManuscriptLeaf } from '../datatypes/manuscript';
import { PageStub } from '../datatypes/page';
import { Mapping } from '../route-reader';
import { RouteUpdater } from '../route-updater';
import { TlnCacheQueryService } from '../services';
import { MarkupSettings, StandoffMarkup } from 'ngx-mat-standoff-markup';

@Component({
  selector: 'tln-manuscript-view',
  templateUrl: './tln-manuscript-view.component.html',
  styleUrls: ['./tln-manuscript-view.component.css']
})
export class TlnManuscriptViewComponent extends RouteUpdater implements OnInit {
   contextView: string = TLN_MANUSCRIPT_PARAM;
   protected currentRoute: string = TLN_MANUSCRIPT_ROUTE;
   current_manuscript: TlnExtManuscript;
   current_manuscript_unity: string;
   current_page_iri: string;
   dataHandler: DataHandler = new DataHandler(this);
   earlierDescriptions: ManuscriptEarlierDescription[] = [];
   fullscreen: boolean;
   manuscriptDescription?: ManuscriptDescription;
   reconstructedKonvolut?: ReconstructedKonvolut;
   manuscriptLeafs: ManuscriptLeaf[] = [];
   protected mapping: Mapping = { 
              contextView: { param: TLN_CONTEXT_VIEW_PARAM, type: "string" },
              current_page_iri: { param: TLN_PAGE_PARAM, type: "string" },
              current_manuscript_unity: { param: TLN_MANUSCRIPT_PARAM, type: "string" },
              fullscreen: { param: TLN_FULLSCREEN_PARAM, type: "boolean" },
   }
   mySettings: MarkupSettings = new MarkupSettings();
   pages: PageStub[] = [];

   constructor(private _sanitizer: DomSanitizer,private tlnQueryService: TlnCacheQueryService, protected router: Router, protected activatedRoute: ActivatedRoute ) { 
      super(router, activatedRoute);
   }
   getSafeResourceUrl(url: string): SafeResourceUrl {
      return this._sanitizer.bypassSecurityTrustResourceUrl(url);
   }
   ngOnInit() {
      this.dataHandler.addHandler('manuscript_content', ['current_manuscript','manuscriptDescription', 'earlierDescriptions', 'pages', 'reconstructedKonvolut', 'manuscriptLeafs'] );
      this.dataHandler.addHandler('current_manuscript', { 'handler': TlnExtManuscript });
      this.dataHandler.addHandler('manuscriptDescription', { 'handler': ManuscriptDescription});
      this.dataHandler.addHandler('earlierDescriptions', { 'handler': ManuscriptEarlierDescription});
      this.dataHandler.addHandler('manuscriptLeafs', { 'handler': ManuscriptLeaf});
      this.dataHandler.addHandler('pages', { 'handler': PageStub });
      this.dataHandler.addHandler('reconstructedKonvolut', { 'handler': ReconstructedKonvolut});
      this.dataHandler.setQueryService(this.tlnQueryService);
      super.ngOnInit();
   }
   protected readParams(params: Params){
     let old_manuscript_unity = this.current_manuscript_unity;
     super.readParams(params);
     if (this.dataHandler.ready){
        if (this.current_manuscript_unity != null && this.current_manuscript_unity != old_manuscript_unity){
            this.dataHandler.resetData('manuscript_content')
            this.dataHandler.getData('manuscript_content', this.current_manuscript_unity);
        }
     }
   }
   private openInViewer(pageIri: string, launch: boolean){
      this.current_page_iri = pageIri;
      this.currentRoute = TLN_VIEWER_ROUTE;
      this.fullscreen = true;
      this.updateParams(launch);
   }

}
