import { Injectable, EventEmitter, OnInit } from '@angular/core';
import {HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError, shareReplay } from 'rxjs/operators';
import { TlnQueryServiceInterface } from './models';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition, } from '@angular/material/snack-bar';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {BASE_URL} from './constants';
/**
 * This is the internal query service 
 * that communicates with the SPARQL-endpoint.
 * */
@Injectable({ providedIn: 'root'})
export class TlnQueryService implements TlnQueryServiceInterface {
  //baseUrl = 'http://localhost:3030/nietzsche/query';
  baseUrl = BASE_URL; 
  reset_data = new EventEmitter<string>();
  error_emitter = new EventEmitter<HttpErrorResponse>();
  use_cache: boolean = false;
  cache = {};

  constructor(private http: HttpClient, private _snackBar: MatSnackBar) { }

  public resetData(key: string){
      this.reset_data.emit(key);
  }
  /**
   * Gets the data from an endpoint via http post
   *
   * @param query:  The query to run.
   * @returns response
   */
   public getData(query: string): Observable<any> {
    let httpOptions = { 
        headers: new HttpHeaders(
            { 'Content-Type': 'application/sparql-query',
           'Accept': 'application/sparql-results+json; charset=UTF-8'}
        )
     };
     if (this.use_cache && this.cache[query]){
         console.log('Returning cached value!')
         return this.cache[query];
     } 
     if (!this.use_cache){
         return this.http.post(this.baseUrl, query, httpOptions).pipe(catchError(this.handleError)); 
     }
     this.cache[query] = this.http.post(this.baseUrl, query, httpOptions).pipe(
         shareReplay(1),
         catchError(error =>{
            delete this.cache[query];
            this.handleError(error);
            return EMPTY
         })
      ); 
      return this.cache[query]
  }
  private handleError(error: HttpErrorResponse) {
     this.error_emitter.emit(error);
     switch (error.status){
        case 0:
            // A client-side or network error occurred. Handle it accordingly.
            console.error('The backend data server is offline:', error.error);
            this._snackBar.open('The backend data server ' + this.baseUrl + ' is offline!');
            break;
        default:
            console.error( `Backend returned code ${error.status}, ` + `body was: ${error.error}`);
     }
     return throwError( 'Something bad happened; please try again later.');
  }

}

@Injectable({ providedIn: 'root'})
export class TlnCacheQueryService extends TlnQueryService {
  use_cache: boolean = true;
}

