import { Component, EventEmitter, Input, Output, OnInit, ViewChild } from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {MatPaginator} from '@angular/material/paginator';
import {Sort} from '@angular/material/sort';
import { NumericResultRow} from '../datatypes/quant';
import { PaginatorResultStatus } from '../common/paginator-result-status';
import { QuantQuery, PageIndexUpdater } from '../models';
import { MySort } from './sort';

@Component({
  selector: 'tln-table',
  templateUrl: './tln-table.component.html',
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
  styleUrls: ['./tln-table.component.css']
})
export class TlnTableComponent implements OnInit, PageIndexUpdater {
  @Input() results: NumericResultRow[];
  @Input() resultIndicesIndex: number = 0;
  @Input() quantQuery: QuantQuery;
  @Output() quantQueryChanged = new EventEmitter<QuantQuery>();
  @Output() change = new EventEmitter<number>();
  expandedElement: NumericResultRow | null;
  resultQuery: QuantQuery;
  paginatorResultStatus: PaginatorResultStatus = new PaginatorResultStatus(10, this);
  sort: MySort = new MySort();
  unfilteredResults: NumericResultRow[] = [];
  @Input() displayedColumns: string[];
  @ViewChild(MatPaginator,{static:false}) paginator: MatPaginator;

  constructor() { }

  ngOnInit() {
     this.resultQuery = this.quantQuery;
     this.paginatorResultStatus.updateResultRange(this.resultQuery.resultIndices[this.resultIndicesIndex]);
  }
  private addFilter(event: Event) {
     const filterValue = (event.target as HTMLInputElement).value;
     this.quantQuery.filterValue = filterValue;
     this.quantQuery.resultIndices[this.resultIndicesIndex] = 0;
     this.paginatorResultStatus.updateResultRange(0);
     this.quantQueryChanged.emit(this.quantQuery);
  }
  private sortData(sort: Sort){
      this.paginator.pageIndex = 0;
      this.paginatorResultStatus.updateResultRange(0);
      this.quantQuery.sortArray[this.resultIndicesIndex] = sort;
      this.quantQuery.resultIndices[this.resultIndicesIndex] = 0;
      this.quantQueryChanged.emit(this.quantQuery);
  }
}
