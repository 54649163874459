import { BasicResultBindingElement } from './basic_datatype';
import { Page, Representation} from '../models';

export class PageStub extends BasicResultBindingElement implements Page {
   static readonly query: string = `
   PREFIX tln: <http://www.nie.org/ontology/nietzsche#>
   PREFIX rdf: <http://www.w3.org/1999/02/22-rdf-syntax-ns#>

   SELECT ?id ?number WHERE {
      ?manuscript a tln:ArchivalManuscriptUnity;
         tln:hasPages/rdf:rest*/rdf:first ?id.
      ?id tln:hasNumber ?number.
      FILTER EXISTS { ?id tln:hasImage ?image }
   }`;
   static readonly query_key: string = 'manuscript';
   number: string;

   constructor (data: any, id?: string, service?: any) {
      super(data, id, service);
      this.number =  this.getData4Key('number');
   }
}
export class TlnPage extends PageStub implements Representation {
   readonly openInTarget: string = 'viewer';
   static readonly query: string = `
   PREFIX tln: <http://www.nie.org/ontology/nietzsche#>
   PREFIX rdf: <http://www.w3.org/1999/02/22-rdf-syntax-ns#>

   SELECT ?id ?title ?number ?manuscript WHERE {
      ?manuscript a tln:ArchivalManuscriptUnity;
         tln:hasTitle ?title;
         tln:hasPages/rdf:rest*/rdf:first ?id.
      ?id a tln:Page; tln:hasNumber ?number.
   }`;
   static readonly query_key: string = 'id';
   title: string; 
   manuscript: string;
   representation: string;

   constructor (data: any, id?: string, service?: any) {
      super(data, id, service);
      this.title =  this.getData4Key('title');
      this.manuscript =  this.getData4Key('manuscript');
      this.representation = this.title + ' ' + this.number;
      if (this.service != null) {
         this.service.setCurrentIri(this.id, this.manuscript);
         //this.service.setTitle(this.title + ", " + this.number);
      }
   }
   public getRouterInformation(launchExternally: boolean): Object {
         return { title: this.title, number: this.number, page: this.id, manuscript: this.manuscript, launchExternally: launchExternally }
   }
}
export class TlnPageWithFaksimile extends TlnPage {
   static readonly query: string = `
   PREFIX tln: <http://www.nie.org/ontology/nietzsche#>
   PREFIX rdf: <http://www.w3.org/1999/02/22-rdf-syntax-ns#>

   SELECT ?id ?title ?number ?manuscript ?image ?originalThumb WHERE {
      ?leaf tln:isDepictedBy ?faksimile. 
      ?faksimile tln:hasThumburl ?image.
      OPTIONAL { ?faksimile tln:hasOriginalThumburl ?originalThumb. }
      ?id tln:hasFaksimileImage ?faksimile;
         tln:hasNumber ?number.
      ?manuscript a tln:ArchivalManuscriptUnity;
         tln:hasTitle ?title;
         tln:hasPages/rdf:rest*/rdf:first ?id.
   }`;
   static readonly query_key: string = 'leaf';
   image: string;
   originalThumb?: string;

   constructor (data: any, id?: string, service?: any) {
      super(data, id, service);
      this.image =  this.getData4Key('image');
      this.originalThumb =  this.getData4Key('originalThumb');
   }
}

