import { BasicResultBindingElement } from './basic_datatype';
import { Identifier, Line, LineStub, Manuscript, Page, Reference, Representation } from '../models';
/**
 * This is the simple line instantiation of an element of {@link /interfaces/FusekiResults.html|FusekiResults}.
 * It extends {@link /classes/BasicResultBindingElement.html|BasicResultBindingElement}.
 **/
export class LineRepresentation extends BasicResultBindingElement  implements Representation{
   readonly openInTarget: string = 'viewer';
   /** 
    * the internal default key for replacing {@link /classes/TlnLine.html#query|query} by "id"
    * in {@link /classes/TlnLine.html#getQuery|getQuery} if "key" is omitted.
    **/
   static readonly default_key: string = 'id';
   static readonly query_key: string = 'id';
   /** 
    * the SPARQL-query of this datatype.
    **/
   static readonly query: string = `
   PREFIX tln: <http://www.nie.org/ontology/nietzsche#>
   PREFIX rdf: <http://www.w3.org/1999/02/22-rdf-syntax-ns#>

   SELECT ?lineNumber ?pageNumber ?title ?page ?manuscript WHERE {
      ?id a tln:Line; tln:lineHasNumber ?lineNumber.
      ?page tln:hasLines/rdf:rest*/rdf:first ?id;
            tln:hasNumber ?pageNumber.
      ?manuscriptId tln:hasPages/rdf:rest*/rdf:first ?page;
            tln:hasTitle ?title.
   } `;
   /** 
    * the number of this line.
    **/
   lineNumber: number;
   pageNumber: string;
   page: string;
   manuscript: string;
   title: string;
   representation: string;
   /**
    * The constructor creates a datatype from the data.
    *
    * @param id if omitted the id will be retrieved from data
    **/
   constructor (data: any, id?: string, service?: any) {
      super(data, id, service);
      this.lineNumber = this.getData4Key('lineNumber');
      this.pageNumber = this.getData4Key('pageNumber')
      this.page = this.getData4Key('page')
      this.title = this.getData4Key('title')
      this.manuscript = this.getData4Key('manuscript')
      this.representation = this.title + " " + this.pageNumber + ", " + this.lineNumber;
   }
   public getRouterInformation(launchExternally: boolean): Object {
      return { title: this.title, number: this.pageNumber, page: this.page, 
            manuscript: this.manuscript, selectedLines: [ this.id ], launchExternally: launchExternally }
   }
}

/**
 * This is the line instantiation of an element of {@link /interfaces/FusekiResults.html|FusekiResults}.
 * It extends {@link /classes/BasicResultBindingElement.html|BasicResultBindingElement}.
 **/
export class TlnLine extends BasicResultBindingElement implements Line {
   /** 
    * the internal default key for replacing {@link /classes/TlnLine.html#query|query} by "id"
    * in {@link /classes/TlnLine.html#getQuery|getQuery} if "key" is omitted.
    **/
   static readonly default_key: string = 'page';
   static readonly use_id: boolean = true;
   /** 
    * the SPARQL-query of this datatype.
    **/
   static readonly query: string = `
   PREFIX tln: <http://www.nie.org/ontology/nietzsche#>
   PREFIX rdf: <http://www.w3.org/1999/02/22-rdf-syntax-ns#>

   SELECT ?id ?number ?top ?bottom 
   ?pageNumber ?manuscriptId ?manuscriptTitle
   ?toLine ?toLineNumber ?toPage ?toPageNumber ?toManuscript ?toManuscriptTitle 
   ?fromLine ?fromLineNumber ?fromPage ?fromPageNumber ?fromManuscript ?fromManuscriptTitle 
   WHERE {
      ?page tln:hasLines/rdf:rest*/rdf:first ?id;
            tln:hasNumber ?pageNumber.
      ?id a tln:Line; tln:lineHasNumber ?number;
            tln:lineHasTopValueOnTranskription ?top;
            tln:lineHasBottomValueOnTranskription ?bottom.
      ?manuscriptId tln:hasPages/rdf:rest*/rdf:first ?page;
            tln:hasTitle ?manuscriptTitle.
      OPTIONAL { 
         ?id tln:lineContinuesOn ?toLine.
         ?toLine tln:lineHasNumber ?toLineNumber.
         ?toPage tln:hasLines/rdf:rest*/rdf:first ?toLine; tln:hasNumber ?toPageNumber.
         ?toManuscript tln:hasPages/rdf:rest*/rdf:first ?toPage; tln:hasTitle ?toManuscriptTitle.
      }
      OPTIONAL { 
         ?id ^tln:lineContinuesOn ?fromLine.
         ?fromLine tln:lineHasNumber ?fromLineNumber.
         ?fromPage tln:hasLines/rdf:rest*/rdf:first ?fromLine; tln:hasNumber ?fromPageNumber.
         ?fromManuscript tln:hasPages/rdf:rest*/rdf:first ?fromPage; tln:hasTitle ?fromManuscriptTitle.
      }
   } `;
   /** 
    * the number of this line.
    **/
   number: number;
   /** 
    * the public key for replacing {@link /classes/TlnLine.html#query|query} by "id".
    **/
   static readonly query_key: string = 'page';
   /** 
    * the geometrical top position of this line.
    **/
   top: number;
   /** 
    * the geometrical bottom position of this line.
    **/
   bottom: number;
   continuesFrom?: Reference;
   continuesTo?: Reference;
   source?: Reference;
   page?: string;

   /**
    * The constructor creates a datatype from the data.
    *
    * @param id if omitted the id will be retrieved from data
    **/
   constructor (data: any, id?: string, service?: any) {
      super(data, id, service);
      this.number = this.getData4Key('number');
      this.top = this.getData4Key('top');
      this.bottom = this.getData4Key('bottom');
      let pageNumber = this.getData4Key('pageNumber')
      let manuscriptTitle = this.getData4Key('manuscriptTitle')
      let continuesTo = this.getData4Key('toLine')
      let continuesFrom = this.getData4Key('fromLine')
      if (continuesTo != null){
         let manuscript: Manuscript = { id: this.getData4Key('toManuscript'), title: this.getData4Key('toManuscriptTitle') } 
         let page: Page = { id: this.getData4Key('toPage'), number: this.getData4Key('toPageNumber') }
         let line: LineStub = { id: continuesTo, number: this.getData4Key('toLineNumber') }
         this.continuesTo = { manuscript: manuscript, page: page, line: line }
      }
      if (continuesFrom != null){
         let manuscript: Manuscript = { id: this.getData4Key('fromManuscript'), title: this.getData4Key('fromManuscriptTitle') } 
         let page: Page = { id: this.getData4Key('fromPage'), number: this.getData4Key('fromPageNumber') }
         let line: LineStub = { id: continuesFrom, number: this.getData4Key('fromLineNumber') }
         this.continuesFrom = { manuscript: manuscript, page: page, line: line }
      }
      if (this.continuesFrom != null || this.continuesTo != null){
         let reference = (this.continuesTo != null) ? this.continuesTo : this.continuesFrom;
         let manuscript: Manuscript = (this.getData4Key('manuscriptId') == reference.manuscript.id) ? null :
                       { id: this.getData4Key('manuscriptId'), title: this.getData4Key('manuscriptTitle') }
         let page: Page = (this.page == reference.page.id) ? null:
                         { id: this.page, number: this.getData4Key('pageNumber') }
         this.source = { manuscript: manuscript, page: page, line: { id: this.id, number: this.number } }
      }
   }
}
/**
 * This is the faksimile line instantiation of an element of {@link /interfaces/FusekiResults.html|FusekiResults}.
 * It extends {@link /classes/TlnLine.html|TlnLine}.
 **/
export class FaksimileLine extends TlnLine implements Line {
   /** 
    * the SPARQL-query of this datatype.
    **/
   static readonly query: string = `
   PREFIX tln: <http://www.nie.org/ontology/nietzsche#>
   PREFIX rdf: <http://www.w3.org/1999/02/22-rdf-syntax-ns#>

   SELECT ?id ?number ?top ?bottom
   ?pageNumber ?manuscriptId ?manuscriptTitle
   ?toLine ?toLineNumber ?toPage ?toPageNumber ?toManuscript ?toManuscriptTitle 
   ?fromLine ?fromLineNumber ?fromPage ?fromPageNumber ?fromManuscript ?fromManuscriptTitle 
   WHERE {
      ?page tln:hasLines/rdf:rest*/rdf:first ?id;
            tln:hasNumber ?pageNumber.
      ?id a tln:Line; tln:lineHasNumber ?number;
         tln:lineHasInnerTopValueOnFaksimile ?top;
         tln:lineHasInnerBottomValueOnFaksimile ?i_bottom.
      ?manuscriptId tln:hasPages/rdf:rest*/rdf:first ?page;
            tln:hasTitle ?manuscriptTitle.
      BIND( (?top+?i_bottom)/2 as ?bottom).
      OPTIONAL { 
         ?id tln:lineContinuesOn ?toLine.
         ?toLine tln:lineHasNumber ?toLineNumber.
         ?toPage tln:hasLines/rdf:rest*/rdf:first ?toLine; tln:hasNumber ?toPageNumber.
         ?toManuscript tln:hasPages/rdf:rest*/rdf:first ?toPage; tln:hasTitle ?toManuscriptTitle.
      }
      OPTIONAL { 
         ?id ^tln:lineContinuesOn ?fromLine.
         ?fromLine tln:lineHasNumber ?fromLineNumber.
         ?fromPage tln:hasLines/rdf:rest*/rdf:first ?fromLine; tln:hasNumber ?fromPageNumber.
         ?fromManuscript tln:hasPages/rdf:rest*/rdf:first ?fromPage; tln:hasTitle ?fromManuscriptTitle.
      }
   } `;
}
