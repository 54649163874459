import { Injectable, EventEmitter } from '@angular/core';
import {HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError, shareReplay } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class OntologyQueryService {
  baseUrl: string;
  cache = {};

  constructor(private http: HttpClient) { }
  /**
   * Gets the data from an endpoint via http post
   *
   * @param query:  The query to run.
   * @param this.baseUrl: The URL of the endpoint
   * @returns response
   */
   public getData(query: string): Observable<any> {
      let httpOptions = { 
        headers: new HttpHeaders(
            { 'Content-Type': 'application/sparql-query',
           'Accept': 'application/sparql-results+json; charset=UTF-8'}
        )
     };
     return this.http.post(this.baseUrl, query, httpOptions);
   }
   public getOldData(query: string): Observable<any> {
    let httpOptions = { 
        headers: new HttpHeaders(
            { 'Content-Type': 'application/sparql-query',
           'Accept': 'application/sparql-results+json; charset=UTF-8'}
        )
     };
     if (this.cache[query]){
         console.log('Returning cached value!')
         return this.cache[this.baseUrl + query];
     } 
     this.cache[query] = this.http.post(this.baseUrl, query, httpOptions).pipe(
         shareReplay(1),
         catchError(error =>{
            delete this.cache[this.baseUrl + query];
            this.handleError(error);
            return EMPTY
         })
      ); 
      return this.cache[this.baseUrl + query]
  }
  private handleError(error: HttpErrorResponse) {
     switch (error.status){
        case 0:
            // A client-side or network error occurred. Handle it accordingly.
            console.error('The backend data server is offline:', error.error);
            break;
        default:
            console.error( `Backend returned code ${error.status}, ` + `body was: ${error.error}`);
     }
     return throwError( 'Something bad happened; please try again later.');
  }
}
