import { OnInit, EventEmitter} from '@angular/core';
import { first, takeUntil } from 'rxjs/operators';
import { BasicResultBindingElement, FusekiResults, AskResult, PageExists} from './datatypes/basic_datatype';
import { DataProcessor, StorageHandler, TlnQueryServiceInterface } from './models';

export interface KeyIriMapping {
   key: string;
   iri: string;
}
export interface ComplexKeyIriMapping {
   idIndex: number;
   mapping: KeyIriMapping[];
}

/**
 * This interface can be used in order to handle data 
 * of type {@link /classes/BasicResultBindingElement.html|BasicResultBindingElement}.
 **/
export interface Handler {
   /**
    * a class that instantiates data of type {@link /classes/BasicResultBindingElement.html|BasicResultBindingElement}
    **/
   handler: typeof BasicResultBindingElement;
   /**
    * data handler's next key for retrieving and instantiating data.
    **/
   next_key?: string;
   /**
    * a service that informs its listeners about its handler's data.
    **/
   service?: any;
   process_data?: DataProcessor;
   storage_handler?: StorageHandler;
}
/**
 * This class retrieves data from  a query service and instantiates it using 
 * corresponding handlers.
 **/
export class DataHandler {
   dataTimestamp: number = 0;
   /**
    * the query services with which data is retrieved
    **/
   queryService: TlnQueryServiceInterface;
   debug: boolean = false;
   /**
    * whether or not DataHandler is ready to retrieve data
    **/
   ready: boolean = false;
   stop_processing = new EventEmitter<boolean>();
   start_processing = new EventEmitter<boolean>();
   processing_finished = new EventEmitter<boolean>();
   /**
    * @param component the component that uses this data handler
    **/
   constructor(protected component: OnInit){}
   /**
    * add a {@link /interfaces/Handler.html|Handler} 
    * or an Array of handler keys to DataHandler.
    **/
   public addHandler(key: string, handler: Handler | string[]) {
      this[key] = handler;
   }
   private processData(results: FusekiResults, handler: typeof BasicResultBindingElement, key: string, is_target_array: boolean, iri?: string, next_iri?: string){
      if (is_target_array){
         this.processArrayData(results, handler, key, iri, next_iri)
      } else {
         this.processObjectData(results, handler, key, iri, next_iri)
      }
   }
   private processArrayData(results: FusekiResults, handler: typeof BasicResultBindingElement, key: string, iri?: string, next_iri?: string){
      this.component[key] = (handler.use_id) ? handler.convertData(results, iri, this[key]['service']) : handler.convertData(results,null, this[key]['service']);
      this.processing_finished.emit(true);
      if (this.component[key].length > 0 && this[key]['next_key'] != null){
          let use_next_iri = (next_iri != null) ? next_iri : this.component[key][0].id;
          this.getData(this[key]['next_key'], use_next_iri);
      } else if(this[key]['process_data'] != undefined && this[key]['process_data'] != null){
         this[key]['process_data'].processData();
      } 
   }
   private processObjectData(results: FusekiResults, handler: typeof BasicResultBindingElement, key: string, iri?: string, next_iri?: string){
      this.component[key] = handler.convertData(results, iri, this[key]['service'])[0];
      this.processing_finished.emit(true);
      if (next_iri != null && this[key]['next_key'] != null){
          this.getData(this[key]['next_key'], next_iri);
      } else if(this[key]['process_data'] != undefined && this[key]['process_data'] != null){
         this[key]['process_data'].processData();
      } 
   }
   /**
    * Retrieve and instantiate data
    * @param key data handler key
    * @param iri iri that should be passed to query
    * @param next_iri use next_iri instead of the iri of the first item in the current data array.
    **/
   public getData(key: string, iri?: string, next_iri?: string) {
      if (Array.isArray(this[key])){ 
         this[key].forEach(value =>this.getData(value, iri));
         if (next_iri != null && this[key]['next_key'] != null){
            this.getData(this[key]['next_key'], next_iri);
         }
      } else {
         this.start_processing.emit(true);
         const handler = this[key]['handler'];
         if (this.debug && key == 'status' ) {
            console.log(handler.getQuery(iri, handler.query_key))
         }
         const is_target_array = Array.isArray(this.component[key]);
         const query = handler.getQuery(iri, handler.query_key)
         const queryKey = encodeURI(query).replace('+', '');
         this.queryService.getData(handler.getQuery(iri, handler.query_key)).pipe(takeUntil(this.stop_processing)).subscribe(results => {
            this.processData(results, handler, key, is_target_array, iri, next_iri);
         });
      }
   }
   public getData4Keys(key: string, datatypeKeyIriMapping: ComplexKeyIriMapping) {
      if (Array.isArray(this[key])){ 
         this[key].forEach(value =>this.getData4Keys(value, datatypeKeyIriMapping));
      } else {
         let handler = this[key]['handler'];
         if (this.debug) {
            console.log(this[key]['handler'], datatypeKeyIriMapping);
         }
         let is_target_array = Array.isArray(this.component[key]);
         let iri = datatypeKeyIriMapping.mapping[datatypeKeyIriMapping.idIndex];
         if (!is_target_array){
            this.queryService.getData(handler.getComplexQuery(datatypeKeyIriMapping.mapping)).pipe(takeUntil(this.stop_processing) || first()).subscribe(results => {
               this.component[key] = handler.convertData(results, iri, this[key]['service'])[0];
            });
         } else {
            this.queryService.getData(handler.getQuery(datatypeKeyIriMapping.mapping)).pipe(takeUntil(this.stop_processing)).subscribe(results => {
               this.component[key] = (handler.use_id) ? handler.convertData(results, iri) : handler.convertData(results);
               if (this.component[key].length > 0 && this[key]['next_key'] != null){
                   datatypeKeyIriMapping.mapping[datatypeKeyIriMapping.idIndex] = this.component[key][0].id;
                   this.getData4Keys(this[key]['next_key'], datatypeKeyIriMapping);
               } 
             });
          }
      }
   }
   public getDataWithNewHandlerIf(key: string, subjectIri: string, typeIri: string, handlerTrue: Handler, handlerFalse: Handler, iri?: string, next_iri?: string) {
      let complexMapping: KeyIriMapping[] = [ { key: 'id', iri: subjectIri }, { key: 'type', iri: typeIri } ] 
      this.queryService.getData(AskResult.getComplexQuery(complexMapping)).pipe(first()).subscribe(result => {
         this[key] = (AskResult.getAnswer(result)) ? handlerTrue : handlerFalse;
         console.log(key, subjectIri, typeIri, result, this[key]);
         this.getData(key, iri, next_iri);
      });
   }
   public conditionalAddHandler(askQuery: string, key: string, handlerTrue: Handler, handlerFalse: Handler) {
      this.queryService.getData(askQuery).pipe(first()).subscribe(result => {
         this[key] = (AskResult.getAnswer(result)) ? handlerTrue : handlerFalse;
      });
   }
   public pageExists(pageIri: string, componentKey: string){
      this.queryService.getData(PageExists.getQuery(pageIri)).pipe(first()).subscribe(result => {
         this.component[componentKey] = PageExists.getAnswer(result);
         if (!this.component[componentKey]){
            alert('In dieser Version ist folgende Seite nicht vorhanden: ' + pageIri);
         }
      });
   }
   protected retrieveLocalData(queryKey: string): FusekiResults {
      const rawData = sessionStorage.getItem(queryKey)
      if (rawData != null){
         const data = JSON.parse(rawData); 
         if (data.timestamp > this.dataTimestamp){
            return data.results;
         } 
      }
      return null;
   }
   protected storeDataLocally(results: FusekiResults, queryKey: string){
      const data = { timestamp: Date.now(), results: results };
      try {
         sessionStorage.setItem(queryKey, JSON.stringify(data)); 
         console.log(sessionStorage.length);
      } catch(e){
         console.log(e);
         sessionStorage.clear();
      }
   }
   /**
    * reset all data belonging to key
    **/
   public resetData(key){
      this.queryService.resetData(key)
      if (Array.isArray(this[key])){ 
         this[key].forEach(value =>this.resetData(value));
      } else {
         this.component[key] = (Array.isArray(this.component[key])) ? [] : null;
      }
   }
   /**
    * set a query service to DataHandler and switch status ready to true.
    **/
   public setQueryService(queryService: TlnQueryServiceInterface){
      this.queryService = queryService;
      this.ready = true;
      this.queryService.error_emitter.subscribe(
            error =>{this.processing_finished.emit(true);
      });
   }
}
