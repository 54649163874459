import { BasicResultBindingElement } from './basic_datatype';
import { Identifier, LineStub, Manuscript, Page, Reference} from '../models';

export class TlnLineReference extends BasicResultBindingElement implements Reference {
   /** 
    * the internal default key for replacing {@link /classes/TlnLine.html#query|query} by "id"
    * in {@link /classes/TlnLine.html#getQuery|getQuery} if "key" is omitted.
    **/
   static readonly default_key: string = 'page';
   /** 
    * the SPARQL-query of this datatype.
    **/
   static readonly query: string = `
   PREFIX tln: <http://www.nie.org/ontology/nietzsche#>
   PREFIX rdf: <http://www.w3.org/1999/02/22-rdf-syntax-ns#>

   SELECT ?id ?page ?page_id ?manuscript ?title ?line_number WHERE {
      ?id a tln:Line; tln:lineHasNumber ?line_number.
      ?page_id tln:hasLines/rdf:rest*/rdf:first ?id; tln:hasNumber ?page.
      ?manuscript tln:hasPages/rdf:rest*/rdf:first ?page_id; tln:hasTitle ?title.
   } `;
   /** 
    * the public key for replacing {@link /classes/TlnLine.html#query|query} by "id".
    **/
   static readonly query_key: string = 'id';
   /** 
    * the  manuscript.
    **/
   manuscript: Manuscript;
   /** 
    * the  line.
    **/
   line: LineStub;
   /**
    * the page.
    **/
   page: Page;

   /**
    * The constructor creates a datatype from the data.
    *
    * @param id if omitted the id will be retrieved from data
    **/
   constructor (data: any, id?: string, service?: any) {
      super(data, id, service);
      this.line = { id: this.id, number: this.getData4Key('line_number') }
      this.page = { id: this.getData4Key('page_id'), number: this.getData4Key('page') }
      this.manuscript = { id: this.getData4Key('manuscript'), title: this.getData4Key('title') }
   }
}

