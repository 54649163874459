import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { TlnQueryServiceInterface, Reference, ManuscriptUnity, NavigationPage } from '../../models';
import { TlnManuscriptUnity, TlnNavigationPage} from '../../datatypes/navigation';
import { DEFAULT_VIEW_OPTION, TLN_VIEWER_ROUTE, TLN_CROSSREF_ROUTE, TLN_CONTEXT_VIEW_PARAM, TLN_FULLSCREEN_PARAM, TLN_FIND_PARAM, TLN_PAGE_PARAM, TLN_MANUSCRIPT_PARAM,
   TLN_SELECTED_LINES_PARAM, TLN_VIEW_OPTION_PARAM, TLN_ZOOM_PARAM, VIEW_OPTIONS, ONTOLOTY_PREFIX } from '../../constants';
import { IsReconstructedKonvolut } from '../../datatypes/basic_datatype';
import { TlnLine} from '../../datatypes/line';
import { TlnTextGeneticOrder} from '../../datatypes/text_version';
import { Mapping } from '../../route-reader';
import { RouteUpdater } from '../../route-updater';
import { ComplexKeyIriMapping, DataHandler, KeyIriMapping } from '../../data_handler';
import { PageViewService, TlnCacheQueryService } from '../../services';
import { TlnInformationComponent, ParentInformation } from '../../tln-information/tln-information.component';
import { PageInformation } from '../../tln-information/page-information';

@Component({
  selector: 'crossref-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.css']
})
export class NavigationComponent extends RouteUpdater {
  /**
   * OPTIONAL pass a queryService with method 
   * {@link /interfaces/TlnQueryServiceInterface.html#getData|getData}
   * to TlnPageViewComponent.
   **/
  @Input() queryService: TlnQueryServiceInterface;
  /**
   * whether or not to show page view in fullscreen mode.
   **/
  fullscreen: boolean = false;
  current_iri: string;
  current_manuscript_iri: string;
  current_page: NavigationPage;
  pageInformation: PageInformation;
  previous_page: NavigationPage;
  next_page: NavigationPage;
  showArchivalManuscriptUnity: boolean = false;
  dataHandler: DataHandler = new DataHandler(this);
  geneticOrders: TlnTextGeneticOrder[] = [];
  selectedLines: string[] = [];
  private readonly PAGE_CONTEXT_VIEW: string = TLN_PAGE_PARAM;
  private readonly MANUSCRIPT_CONTEXT_VIEW: string = TLN_MANUSCRIPT_PARAM;
  contextView: string = this.PAGE_CONTEXT_VIEW;
  private readonly increment: number = 0.333;
  private readonly decrement: number = this.increment*-1;
  protected currentRoute: string = TLN_CROSSREF_ROUTE;
  protected mapping: Mapping = { 
              contextView: { param: TLN_CONTEXT_VIEW_PARAM, type: "string" },
              current_iri: { param: TLN_PAGE_PARAM, type: "string" },
              current_manuscript_iri: { param: TLN_MANUSCRIPT_PARAM, type: "string" },
              fullscreen: { param: TLN_FULLSCREEN_PARAM, type: "boolean" }
  }
  routerParams: Params;
  selectedViewOption: string = DEFAULT_VIEW_OPTION
  updating: boolean = false;
  viewOptions: string[] = [ VIEW_OPTIONS.TRANSKRIPTION, VIEW_OPTIONS.FAKSIMILE, VIEW_OPTIONS.SYNOPSIS, VIEW_OPTIONS.SYNOPSIS_B ];

   constructor(private pageViewService: PageViewService, private localQueryService: TlnCacheQueryService, protected router: Router, protected activatedRoute: ActivatedRoute ) { 
      super(router, activatedRoute);
   }
   ngOnInit() {
      let tlnQueryService = (this.queryService != null) ? this.queryService : this.localQueryService;
      this.dataHandler.addHandler('navigation_page', ['current_page', 'geneticOrders'] );
      this.dataHandler.addHandler('current_page', { 'handler': TlnNavigationPage });
      this.dataHandler.addHandler('geneticOrders', { 'handler': TlnTextGeneticOrder});
      this.dataHandler.setQueryService(tlnQueryService);
      this.dataHandler.start_processing.subscribe(
         (started: boolean) =>{ this.updating = true;
      });
      this.dataHandler.processing_finished.subscribe(
         (finished: boolean) =>{ this.updating = false;
      });
      super.ngOnInit();
  }
  changeContext(){
      this.contextView = (this.contextView == this.PAGE_CONTEXT_VIEW) ? this.MANUSCRIPT_CONTEXT_VIEW : this.PAGE_CONTEXT_VIEW;
      //this.current_genetic_order_iri = 'none'; 
      this.updateParams();
  }
  private getPageTitle(page?: NavigationPage, numPages?: number): string {
     if (page == null){
        return '';
     }
     let indexPrefix = (numPages != null) ? page.index + '/' + numPages : page.index;
     return indexPrefix + ': ' + page.title + ' ' + page.number;
  }
  protected readParams(params: Params){
     super.readParams(params);
     if (this.dataHandler.ready && (this.current_page == null || this.current_page.id != this.current_iri)){
        this.dataHandler.resetData('navigation_page')
        this.dataHandler.getData('navigation_page', this.current_iri);
     } 
  }
  private setCurrentIri(pageIri: string){
      this.dataHandler.stop_processing.emit(true);
      this.current_iri = pageIri;
      this.currentRoute = TLN_VIEWER_ROUTE;
      this.updateParams();
  }
  private toggleFullscreen(){
     this.fullscreen = !this.fullscreen;
     this.updateParams();
  }

}
