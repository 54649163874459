import { NgModule, ModuleWithProviders } from '@angular/core';
import {RouterModule} from '@angular/router';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from "@angular/common/http";
import { MatBadgeModule, MatSnackBarModule, MatSlideToggleModule, MatTreeModule, MatSliderModule,MatProgressSpinnerModule,MatBottomSheetModule,MatButtonModule,MatCheckboxModule,MatDialogModule,MatExpansionModule,MatFormFieldModule,MatInputModule,MatListModule,MatPaginatorModule,MatRadioModule,MatSelectModule,MatSidenavModule,MatSortModule,MatTableModule,MatToolbarModule,MatButtonToggleModule,MatCardModule,MatIconModule,MatMenuModule,MatTabsModule,MatTooltipModule
} from '@angular/material';
import { OntologyViewerComponent } from './ontology-viewer.component';
import { OntologyQueryService } from './ontology-query.service';
import { OntologyDefinitionComponent } from './ontology-definition.component';
import { OntologyTableComponent } from './ontology-table.component';
import { OntologyClassesComponent } from './ontology-classes.component';
import { ContentComponent } from './content.component';
import { OntologyPropertiesComponent } from './ontology-properties.component';
import { UniqueEntryPipe } from './unique-entry.pipe';
import { Ontologies2namespacesPipe } from './ontologies2namespaces.pipe';
import { SortSubjectsAlphaPipe } from './sort-subjects-alpha.pipe';
import { OntologyDisplayComponent } from './ontology-display.component';



@NgModule({
  declarations: [OntologyViewerComponent, OntologyDefinitionComponent, OntologyTableComponent, OntologyClassesComponent, ContentComponent, OntologyPropertiesComponent, UniqueEntryPipe, Ontologies2namespacesPipe, SortSubjectsAlphaPipe, OntologyDisplayComponent],
  imports: [
   RouterModule,
   HttpClientModule,
    FormsModule,
    BrowserModule,
    CommonModule,
    MatBadgeModule,
    MatSnackBarModule,
    MatSlideToggleModule,
    MatSliderModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatDialogModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatProgressSpinnerModule,
    MatPaginatorModule,
    MatRadioModule,
    MatSelectModule,
    MatSidenavModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatTreeModule,
    MatToolbarModule,
    MatTooltipModule
  ],
  exports: [
     OntologyViewerComponent,
     Ontologies2namespacesPipe
  ],
  providers: [
     OntologyQueryService 
  ]
})
export class OntologyViewerModule { }
