import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { TlnQueryServiceInterface } from './models';
import { TlnCacheQueryService} from './tln-query.service';
import { TlnPage} from './datatypes/page';
import { FaksimileImage } from './datatypes/faksimile_image';
import { SVGImage, SVGImageClip } from './datatypes/svg_image';
import { TlnImage } from './datatypes/image';
import { TlnLine, FaksimileLine } from './datatypes/line';
import { TlnWord, FaksimileWord } from './datatypes/word';
import { TlnTextByForeignHand, FaksimileTextByForeignHand } from './datatypes/foreign_text';
import { externalAssignClass, externalAssignStyle, Configuration, Identifier, Image, Line, Word } from './models';
import { ConfigurableComponent } from './services';
import { ComplexKeyIriMapping, DataHandler } from './data_handler';
import { DEFAULT_VIEW_OPTION, VIEW_OPTIONS, TLN_FULLSCREEN_PARAM, TLN_FIND_PARAM, TLN_PAGE_PARAM, TLN_ROTATION_PARAM, TLN_SELECTED_LINES_PARAM, TLN_SELECTED_WORDS_PARAM, TLN_VIEW_OPTION_PARAM, TLN_MULTI_INSTANCE_ZOOM_PARAM, TLN_ZOOM_PARAM } from './constants';
import { Mapping, RouteReader } from './route-reader';

/**
 * Given a page IRI, this component will request all relevant information and
 * display the data with {@link /components/PageViewComponent.html|PageViewComponent}.
 **/
@Component({
  selector: 'tln-page-view',
  templateUrl: './tln-page-view.component.html',
  styleUrls: ['./tln-page-view.component.css']
})
export class TlnPageViewComponent extends RouteReader implements OnInit, OnChanges {
   @Input() configuration: Configuration = { '*': { 'useExtendedTooltip': true }};
   /**
    * IRI of the current page, the component will ignore page iri's from params if set.
    **/
   @Input('page') current_iri: string;
   /**
    * the search text of words that should be highlighted as {@link /miscellaneous/enumerations.html#HIGHTLIGHT_CASES|HIGHTLIGHT_CASES.SEARCHED_WORD}.
    **/
   @Input() findText: string;
   /**
    * whether or not to ignore the params
    **/
   private ignoreParams: boolean = false;
   /**
    * OPTIONAL pass a queryService with method 
    * {@link /interfaces/TlnQueryServiceInterface.html#getData|getData}
    * to TlnPageViewComponent.
    **/
   @Input() queryService: TlnQueryServiceInterface;
   /**
    * global zoom factor.
    **/
   @Input() zoomFactor: number = 1;
   /**
    * identifiers of selected words that should be highlighted.
    **/
   @Input() selectedWords: Identifier[] = [];
   /**
    * identifiers of selected lines that should be highlighted.
    **/
   @Input() selectedLines: Identifier[] = [];
   /**
    * the (initial) maximum height of the image.
    **/
   @Input() max_height: number = -1;
   /**
    * the (initial) maximum width of the image, if both height and width are specified prefer width.
    **/
   @Input() max_width: number = -1;
   /**
   * Rotation value, i.e. one of [ 0, 90, 180, 270 ].
   **/
   rotation: number = 0;
   dontShowReference: boolean = false;
   @Input() showAllLines: boolean = false;
   @Input() startLine: Identifier;
   @Input() endLine: Identifier;
   /**
    * should primary Url be used for image. Use secondary Url if false.
    **/
   @Input() preferPrimaryUrl: boolean = true;
   /**
    * selected view option, i.e. one of the following 
    * {@link /miscellaneous/enumerations.html#VIEW_OPTIONS|VIEW_OPTIONS}.
    * */
   @Input() selectedViewOption: string = DEFAULT_VIEW_OPTION;
   /**
    * An optional function that will be passed to {@link /components/TextFieldComponent.html|TextFieldComponent} 
    * in order to return a further highlight class
    * to the word rects when the internal function would return 'textfield unhighlighted'. 
    **/
   @Input('assignClass') assignClass?: externalAssignClass;
   /**
    * An optional function that will be passed to {@link /components/TextFieldComponent.html|TextFieldComponent}
    * and {@link /components/MarginFieldComponent.html|MarginFieldComponent}
    * in order to return a (svg-)style object 
    * to the word and line rects. This function allows the user to extend the style of this component.
    * E.g. by returning { fill: blue } the function overwrites the default behaviour and sets
    * the default highlight color to blue.
    **/
   @Input('assignStyle') assignStyle?: externalAssignStyle;
   /**
    * the data handler of this component that retrieves
    * data and instantiates it according to their proper 
    * datatypes.
    **/
   dataHandler: DataHandler = new DataHandler(this);
   /**
    * whether or not to show page view in fullscreen mode.
    **/
   fullscreen: boolean = false;
   /**
    * texts written by foreign hand 
    **/
   foreignTexts: TlnTextByForeignHand[] = [];
   /**
    * the (first) image to be displayed by 
    * {@link /components/PageViewComponent.html|PageViewComponent}.
    **/
   image: Image;
   /**
    * the Array of lines of the first image that will be displayed by {@link /components/MarginFieldComponent.html|MarginFieldComponent}.
    **/
   lines: Line[] = [];
   /**
    * texts written by foreign hand 
    **/
   second_foreignTexts: FaksimileTextByForeignHand[] = [];
   /**
    * the second image that will be displayed by {@link /components/TextFieldComponent.html|TextFieldComponent}.
    **/
   second_image: Image;
   /**
    * the Array of lines of the second image that will be displayed by {@link /components/MarginFieldComponent.html|MarginFieldComponent}.
    **/
   second_lines: Line[] = [];
   /**
    * the Array of words of the second image that will be displayed by {@link /components/TextFieldComponent.html|TextFieldComponent}.
    **/
   second_words: Word[] = [];
   /**
    * the Array of words of the first image that will be displayed by {@link /components/TextFieldComponent.html|TextFieldComponent}.
    **/
   words: Word[] = [];
   @Input() multiInstanceMode: boolean = false;
   protected mapping: Mapping = { 
      findText: { param: TLN_FIND_PARAM, type: "string" },
      fullscreen: { param: TLN_FULLSCREEN_PARAM, type: "boolean" },
      rotation: { param: TLN_ROTATION_PARAM, type: "number" },
      selectedViewOption: { param: TLN_VIEW_OPTION_PARAM, type: "string" },
      selectedLines: { param: TLN_SELECTED_LINES_PARAM, type: "string" },
      selectedWords: { param: TLN_SELECTED_WORDS_PARAM, type: "string" },
      current_iri: { param: TLN_PAGE_PARAM, type: "string" },
      zoomFactor: { param: TLN_ZOOM_PARAM, type: "number" }
   }

   /**
    * @param localQueryService internal query service that will be used if no external queryService is passed 
    * to input.
    **/
   constructor(private localQueryService: TlnCacheQueryService, protected router: Router, protected activatedRoute: ActivatedRoute){ 
      super(router, activatedRoute);
   }

   ngOnInit() {
      if (this.multiInstanceMode){
         this.mapping['current_iri']['ignore'] = (this.current_iri != null);
         this.mapping['selectedViewOption']['ignore'] = true;
         this.mapping['zoomFactor']['param'] = TLN_MULTI_INSTANCE_ZOOM_PARAM;
         this.dontShowReference = true;
         //this.configuration['*']['useExtendedTooltip'] = false;
      }
      super.ngOnInit();
      this.localQueryService.use_cache = true;
      let tlnQueryService = (this.queryService != null) ? this.queryService : this.localQueryService;
      //console.log(tlnQueryService['use_cache']);
      let queryConfiguration: Configuration = { '*': {  'tlnQueryService': tlnQueryService }}
      this.configuration = ConfigurableComponent.updateConfiguration(this.configuration, queryConfiguration)
      this.dataHandler.addHandler('image', { 'handler': SVGImage });
      this.dataHandler.addHandler('words', { 'handler': TlnWord });
      this.dataHandler.addHandler('foreignTexts', { 'handler': TlnTextByForeignHand});
      this.dataHandler.addHandler('lines',       { 'handler': TlnLine });
      this.dataHandler.addHandler('second_image',{ 'handler': FaksimileImage });
      this.dataHandler.addHandler('second_words',{ 'handler': FaksimileWord });
      this.dataHandler.addHandler('second_lines',{ 'handler': FaksimileLine });
      this.dataHandler.addHandler('second_foreignTexts', { 'handler': FaksimileTextByForeignHand});
      this.dataHandler.addHandler('page_content',[ 'image', 'lines', 'words', 'foreignTexts' ]);
      this.dataHandler.addHandler('second_page_content', [ 'second_image', 'second_lines', 'second_words', 'second_foreignTexts' ] );
      this.dataHandler.setQueryService(tlnQueryService);
      this.updatePageData();
   }
   ngOnChanges(change: SimpleChanges) {
      if (this.dataHandler.ready 
         && (change.current_iri != undefined && change.current_iri != null && !change.current_iri.firstChange)
            || (change.selectedViewOption != undefined && change.selectedViewOption != null && !change.selectedViewOption.firstChange)) {
         this.updatePageData();
      } 
   }
   protected readParams(params: Params){
      let old_page_iri = this.current_iri;
      let old_selectedViewOption = this.selectedViewOption;
      let old_fullscreen = this.fullscreen
      super.readParams(params);
      if (this.dataHandler.ready 
         && (old_page_iri != this.current_iri || old_selectedViewOption != this.selectedViewOption)){
         this.updatePageData();
      }
      if (old_fullscreen != this.fullscreen && !this.multiInstanceMode){
         //this.dontShowReference = !this.fullscreen; 
         //this.configuration['*']['useExtendedTooltip'] = this.fullscreen;
      }
   }
   /**
    * This function updates the page data by setting the handlers for the current 
    * {@link #selectedViewOption|selectedViewOption} and by retrieving the data.
    **/
   private updatePageData(){
      this.dataHandler['image']['handler'] = (this.selectedViewOption != VIEW_OPTIONS.FAKSIMILE && this.selectedViewOption != VIEW_OPTIONS.SYNOPSIS_B) ? SVGImage : FaksimileImage ; 
      this.dataHandler['words']['handler'] =  (this.selectedViewOption != VIEW_OPTIONS.FAKSIMILE && this.selectedViewOption != VIEW_OPTIONS.SYNOPSIS_B) ? TlnWord : FaksimileWord ; 
      this.dataHandler['lines']['handler'] =  (this.selectedViewOption != VIEW_OPTIONS.FAKSIMILE && this.selectedViewOption != VIEW_OPTIONS.SYNOPSIS_B) ? TlnLine : FaksimileLine ; 
      this.dataHandler['foreignTexts']['handler'] =  (this.selectedViewOption != VIEW_OPTIONS.FAKSIMILE && this.selectedViewOption != VIEW_OPTIONS.SYNOPSIS_B) 
                                                     ? TlnTextByForeignHand : FaksimileTextByForeignHand ; 
      if (this.dataHandler['page_content'][this.dataHandler['page_content'].length-1] == 'second_page_content'){
         this.dataHandler['page_content'].pop();   
         this.second_image = null;
         this.second_foreignTexts = [];
         this.second_words = [];
         this.second_lines = [];
      }
      if (this.selectedViewOption == VIEW_OPTIONS.SYNOPSIS || this.selectedViewOption == VIEW_OPTIONS.SYNOPSIS_B){
         this.dataHandler['second_image']['handler'] = (this.selectedViewOption == VIEW_OPTIONS.SYNOPSIS_B) ? SVGImage : FaksimileImage ; 
         this.dataHandler['second_words']['handler'] =  (this.selectedViewOption == VIEW_OPTIONS.SYNOPSIS_B) ? TlnWord : FaksimileWord ; 
         this.dataHandler['second_lines']['handler'] =  (this.selectedViewOption == VIEW_OPTIONS.SYNOPSIS_B) ? TlnLine : FaksimileLine ; 
         this.dataHandler['second_foreignTexts']['handler'] =  (this.selectedViewOption == VIEW_OPTIONS.SYNOPSIS_B) ? TlnTextByForeignHand : FaksimileTextByForeignHand ; 
         this.dataHandler['page_content'].push('second_page_content');
      }  
      if (this.current_iri != null){
         this.dataHandler.resetData('page_content');
         if (this.startLine != null && this.startLine != undefined){
            this.dataHandler['image']['handler'] = SVGImageClip ; 
            let endLine = (this.endLine != null && this.endLine != undefined) ? this.endLine : this.startLine;
            let complex: ComplexKeyIriMapping = { idIndex: 0, 
               mapping: [ { key: 'page', iri: this.current_iri}, { key: 'startLine', iri: <string>this.startLine}, {key: 'endLine', iri: <string>endLine} ]
            }
            this.dataHandler['page_content'] = this.dataHandler['page_content'].filter(key =>key != 'image')
            this.dataHandler.getData4Keys('image', complex);
         } else if (this.dataHandler['page_content'].indexOf('image') == -1){
            this.dataHandler['page_content'].splice(0, 0, 'image')
         }
         this.dataHandler.getData('page_content', this.current_iri);
      }
   }

}
