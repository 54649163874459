import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Params } from '@angular/router';

@Component({
  selector: 'app-rhizome-view-component',
  templateUrl: './rhizome-view-component.component.html',
  styleUrls: ['./rhizome-view-component.component.css']
})
export class RhizomeViewComponentComponent implements OnInit {
  fullscreen: boolean;

  constructor(private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe( (queryParams: Params)  => this.fullscreen = (queryParams.fullscreen === 'true') );

  }

}
