import { TlnImage } from './image';
import { Copyright, Image } from '../models';
/**
 * This is the faksimile image instantiation of an element of {@link /interfaces/FusekiResults.html|FusekiResults}.
 * It extends {@link /classes/TlnImage.html|TlnImage}.
 **/
export class FaksimileImage extends TlnImage {
   /**
    * the SPARQL-query of this datatype
    **/
   static readonly query: string = `
   PREFIX tln: <http://www.nie.org/ontology/nietzsche#>

   SELECT ?id ?filename ?URL ?secondaryURL ?width ?height ?t_left ?t_top ?t_width ?t_height ?page ?transform WHERE {
      ?id a tln:FaksimileImage;
           tln:hasFileName ?filename;
           tln:hasPrimaryurl ?URL;
           tln:hasSecondaryurl ?secondaryURL;
           tln:hasWidth ?width;
           tln:hasHeight ?height;
           tln:hasTextField ?textfield.
      ?textfield tln:hasLeft ?t_left;
           tln:hasTop ?t_top;
           tln:hasWidth ?t_width;
           tln:hasHeight ?t_height;
           ^tln:pageIsOnFaksimileTextField ?page.
      OPTIONAL { ?id tln:hasTransform ?transform. }
   }`;
   /**
    * Copyright information of image
    **/
   copyright: Copyright;

   constructor (data: any, id?: string, service?: any) {
      super(data, id, service);
      this.copyright = { 
         originalUrl: 'http://www.nietzschesource.org/DFGA/' + this.filename.replace('.jpg', ''),
         licenseTextUrl: 'http://creativecommons.org/licenses/by-nc-nd/4.0/deed.de',
         license: 'CC BY-NC-ND 4.0',
         text: 'Dieses Faksimile wird von <a target="_new" href="http://www.nietzschesource.org">nietzschesource.org</a>  gehostet und wird hier unver&auml;ndert dargestellt.'
      }
   }
}
