import { BasicResultBindingElement, FusekiResults } from './basic_datatype';
import { Line } from '../models';
/**
 * This is the 'editor comment' stub instantiation of an element of {@link /interfaces/FusekiResults.html|FusekiResults}.
 **/
export class TlnImprint extends BasicResultBindingElement  {
   static readonly default_key: string = 'page';
   static readonly query_key: string = 'page';
   /** 
    * the SPARQL-query of this datatype.
    **/
   static readonly query: string = `
   PREFIX tln: <http://www.nie.org/ontology/nietzsche#>
   PREFIX rdf: <http://www.w3.org/1999/02/22-rdf-syntax-ns#>
   PREFIX rdfs: <http://www.w3.org/2000/01/rdf-schema#>

   SELECT ?id ?reference ?line ?lineNumber WHERE {
      ?page a tln:Page;
         tln:hasImprints/rdf:rest*/rdf:first ?id.
     ?id tln:imprintHasReference ?reference.
     OPTIONAL { ?id tln:imprintRefersToLines/rdf:rest*/rdf:first ?line.
                ?line tln:lineHasNumber ?lineNumber .
     }
   }`;
   reference: string;
   lines: Line[] = [];
    /**
    * The constructor creates a datatype from the data.
    *
    * @param id if omitted the id will be retrieved from data
    **/
   constructor(data: any, id?: string, service?: any){
      super(data, id, service)
      this.reference = this.getData4Key('reference');
   }

   public static convertData<T extends typeof BasicResultBindingElement>(this: T, data: FusekiResults, id?: string, service?: any): Array<InstanceType<T>> {
      let elements = []; 
      let content = this.getContent(data);
      for (var i = 0; i < content.length; i++){
         let element = new TlnImprint(content[i], id, service);
         if(content[i]['line'] != undefined && content[i]['line'] != null)  {
            element.lines.push({
               id: content[i]['line']['value'],
               number: Number(content[i]['lineNumber']['value']),
               bottom: -1,
               top: -1
            });
         }
         if (elements.length > 0 && elements[elements.length-1].id == element.id
            && element.lines.length > 0){
            elements[elements.length-1].lines.push(element.lines[0])
         } else {
            elements.push(element)
         }
      }
      //console.log(elements)
      return elements;
   }

}

