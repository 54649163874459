import { Component, Input, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { TLN_MANUSCRIPT_ROUTE, TLN_FULLSCREEN_PARAM, TLN_MANUSCRIPT_PARAM, TLN_SELECTED_WORDS_PARAM } from '../constants';
import { Mapping } from '../route-reader';
import { RouteUpdater } from '../route-updater';
import { NavigationPage } from '../models';

export interface ManuscriptRouteInformation {
   title: string;
   manuscript: string;
   launchExternally?: boolean;
}
@Component({
  selector: 'open-in-manuscript',
  templateUrl: './open-in-manuscript.component.html',
  styleUrls: ['./open-in-manuscript.component.css']
})
export class OpenInManuscriptComponent extends RouteUpdater {
   @Input() routerInformation: ManuscriptRouteInformation;
   @Input() routePrefix: string;
   protected currentRoute: string = TLN_MANUSCRIPT_ROUTE;
   current_manuscript_unity: string;
   protected mapping: Mapping = { current_manuscript_unity: { param: TLN_MANUSCRIPT_PARAM, type: "string" }}

   constructor(protected router: Router, protected activatedRoute: ActivatedRoute ) { 
      super(router, activatedRoute);
   }
  ngOnInit() {
      super.ngOnInit();
      if (this.routePrefix != undefined && this.routePrefix != null){
         this.currentRoute = this.routePrefix + '/' + this.currentRoute;
      }
  }
   private openInManuscript(launchExternally: boolean){
      this.current_manuscript_unity = this.routerInformation.manuscript;
      this.updateParams(launchExternally);
   }

}
