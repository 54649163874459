import { BasicResultBindingElement } from './basic_datatype';
import { Image, TextField} from '../models';

export class TlnImage extends BasicResultBindingElement implements Image {
   static readonly default_key: string = 'page';
   static readonly query: string = `
   PREFIX tln: <http://www.nie.org/ontology/nietzsche#>

   SELECT ?id ?filename ?width ?height ?URL ?secondaryURL ?t_left ?t_top ?t_width ?t_height ?page ?transform WHERE {
      ?id a tln:Image;
           tln:hasPrimaryurl ?URL;
           tln:hasSecondaryurl ?secondaryURL;
           tln:hasFileName ?filename;
           tln:hasWidth ?width;
           tln:hasHeight ?height;
           tln:hasTextField ?textfield.
      ?textfield tln:hasLeft ?t_left;
           tln:hasTop ?t_top;
           tln:hasWidth ?t_width;
           tln:hasHeight ?t_height;
           ^tln:pageIsOnTextField ?page.
      OPTIONAL { ?id tln:hasTransform ?transform. }
   }`;
   filename: string;
   x: number;
   y: number;
   width: number;
   height: number;
   text_field: TextField;
   URL: string;
   secondaryURL: string;
   transform: string;

   constructor (data: any, id?: string, service?: any) {
      super(data, id, service);
      this.x = 0;
      this.y = 0;
      this.filename = this.getData4Key('filename');
      this.width = this.getData4Key('width');
      this.height = this.getData4Key('height');
      this.URL = this.getData4Key('URL');
      this.secondaryURL = this.getData4Key('secondaryURL');
      this.transform = this.getData4Key('transform')
      this.text_field = new TlnTextField(this.getData4Key('t_left'),
                                       this.getData4Key('t_top'),
                                       this.getData4Key('t_width'),
                                       this.getData4Key('t_height'));
   }
}

export class TlnTextField implements TextField {
   left: number;
   top: number;
   width: number;
   height: number;
   bottom: number;
   
   constructor(left: number, top: number, width: number, height: number){
      this.left = left;
      this.top = top;
      this.width = width;
      this.height = height;
   }
}
