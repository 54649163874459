import { Pipe, PipeTransform } from '@angular/core';
import { NumericResultRow } from '../datatypes/quant';
import { QuantQuery } from '../models';

@Pipe({
  name: 'quantResultPipe'
})
export class ResultPipe implements PipeTransform {

  transform(numResultRows: NumericResultRow[], quantQuery: QuantQuery): string {
    const total = (numResultRows.length > 0) ? numResultRows[0].total : 0;
    let numWordWithPropertiesTotal = 0
    numResultRows.forEach(result =>{ numWordWithPropertiesTotal += result.numProperties});
    let wordPropString = (numWordWithPropertiesTotal > 1) ? 'Wörter' : 'Wort';
    if (quantQuery.altQuery == null && quantQuery.selectedWordProperties.length > 0) {
      if (quantQuery.selectedWordProperties.length > 1) {
         const propStrings = quantQuery.selectedWordProperties.map(prop =>'"' + prop.label.replace('.', '') + '"');
         wordPropString = wordPropString + ' haben die Eigenschaften ' + propStrings.join(', ')
      } else {
         wordPropString = wordPropString + ' haben die Eigenschaft "' + quantQuery.selectedWordProperties[0].label.replace('.', '') + '"'; 
      }
    }
    let filterString = ''
     if (quantQuery.altQuery == null 
        && ((quantQuery.text != null && quantQuery.text != '') 
           || (quantQuery.filterValue != null && quantQuery.filterValue != ''))){
       filterString = (quantQuery.text != null && quantQuery.text != '') ? ' und den Worttext "' + quantQuery.text + '"' : ' und den Filtertext "' + quantQuery.filterValue +'"';
    }
    //const filterString = (quantQuery.altQuery == null && quantQuery.text != null && quantQuery.text != '') ? ' und den Worttext "' + quantQuery.text + '"': '';
    return numWordWithPropertiesTotal + '/' + total + ' ' + wordPropString + filterString;
  }

}
