import {PageEvent} from '@angular/material/paginator';
import { FoundPage} from '../datatypes/search';
import { PageIndexUpdater } from '../models';

export interface ResultRange {
   start: number;
   end: number;
}

export class PaginatorResultStatus {
   resultLength: number;
   resultRange: ResultRange;
   resultIndex: number = 0;
   pageIndexUpdater: PageIndexUpdater;
   pageSizeOptions: number[] = [5, 10, 25, 100];

   constructor(resultLength: number, pageIndexUpdater?: PageIndexUpdater){
      this.resultLength = resultLength;
      this.resultRange = { start: 0, end: this.resultLength-1 };
      this.pageIndexUpdater = pageIndexUpdater;
   }
   updateResultRange(index: number){
      this.resultRange = null;
      this.resultIndex = index;
      let newStart = index*this.resultLength
      let newEnd = newStart+this.resultLength;
      this.resultRange = { start: newStart, end: newEnd };
      //console.log(this.resultRange);
   }
   showResults(event: PageEvent){
      this.resultLength = event.pageSize;
      this.updateResultRange(event.pageIndex);
      if (this.pageIndexUpdater != null){
         this.pageIndexUpdater.change.emit(event.pageIndex)
      }
   }

}
