import { Component, Input, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { MatBottomSheetRef} from '@angular/material/bottom-sheet';
import { MarkupSettings, StandoffMarkup } from 'ngx-mat-standoff-markup';
import { PageViewService } from '../services';
import { EditorComment, Line } from '../models';
import { TlnEditorComment } from '../datatypes/editor_comment';
import { Mapping } from '../route-reader';
import { RouteUpdater } from '../route-updater';
import { TLN_SELECTED_WORDS_PARAM, TLN_SELECTED_LINES_PARAM } from '../constants';

@Component({
  selector: 'footnote',
  templateUrl: './footnote.component.html',
  styleUrls: ['./footnote.component.css']
})
export class FootnoteComponent extends RouteUpdater implements OnInit {
  @Input() editor_comment: EditorComment;
  @Input() bottomSheetRef: MatBottomSheetRef;
  @Input() parentActivatedRoute: ActivatedRoute;
  @Input() targetRoute: string;
  protected currentRoute: string;// = TLN_CROSSREF_ROUTE;
  highlighted: boolean = false;
  mySettings: MarkupSettings = new MarkupSettings();
  editorType: string;
  selectedLines: string[] = [];
  selectedWords: string[] = [];
  protected mapping: Mapping = { 
     selectedWords: { param: TLN_SELECTED_WORDS_PARAM, type: "string" },
     selectedLines: { param: TLN_SELECTED_LINES_PARAM, type: "string" }
  };

  constructor(private pageViewService: PageViewService, protected router: Router, protected activatedRoute: ActivatedRoute ){ 
      super(router, activatedRoute);
  }

  ngOnInit() {
     if (this.targetRoute != undefined && this.targetRoute != null){
         this.currentRoute = this.targetRoute;
     }
     if (this.editor_comment != undefined && this.editor_comment != null && this.editor_comment.editorClass != null 
        && this.editor_comment.editorClass.split('#').length > 1 &&this.editor_comment.editorClass.split('#')[1] != 'EditorComment'){ 
        this.editorType = this.editor_comment.editorClass.split('#')[1];
     }
     super.ngOnInit();
  }
  private selectItem(){
     if (this.editor_comment.word != null){
        const id = <string>this.editor_comment.word.id;
        if(this.selectedWords.indexOf(id) == -1){    
           this.selectedWords.push(id);
        }
     } else if (this.editor_comment.tLine != null){
         const id = <string>this.editor_comment.tLine.id;
         if(this.selectedLines.indexOf(id) == -1){    
           this.selectedLines.push(id);
        }
     }
     this.updateParams();
     if(this.bottomSheetRef != undefined && this.bottomSheetRef != null){
         this.bottomSheetRef.dismiss();
     }
  }
  private onHover(){
     this.highlighted = true;
     if (this.editor_comment.word != null){
         this.pageViewService.onHoveredWord.emit(this.editor_comment.word);
     } else if (this.editor_comment.tLine != null){
         this.pageViewService.onHoveredLine.emit(this.editor_comment.tLine);
     }
  }
  private offHover(){
     this.highlighted = false;
     if (this.editor_comment.word != null){
         this.pageViewService.offHoveredWord.emit(this.editor_comment.word);
     } else if (this.editor_comment.tLine != null){
         this.pageViewService.offHoveredLine.emit(this.editor_comment.tLine);
     }
  }

}
