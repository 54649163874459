import {RouterModule} from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from "@angular/common/http";
import { NgModule, ModuleWithProviders  } from '@angular/core';
import { MatBadgeModule, MatSnackBarModule, MatSlideToggleModule, MatTreeModule, MatSliderModule,MatProgressSpinnerModule,MatBottomSheetModule,MatButtonModule,MatCheckboxModule,MatDialogModule,MatExpansionModule,MatFormFieldModule,MatInputModule,MatListModule,MatPaginatorModule,MatRadioModule,MatSelectModule,MatSidenavModule,MatSortModule,MatTableModule,MatToolbarModule,MatButtonToggleModule,MatCardModule,MatIconModule,MatMenuModule,MatTabsModule,MatTooltipModule
} from '@angular/material';
import { CodemirrorModule } from '@ctrl/ngx-codemirror';
import { NgxMatStandoffMarkupModule} from "ngx-mat-standoff-markup";
import { CacheService } from './common/cache.service';
import { PageViewService } from '../page-view/page-view.service';
import { PageViewModule  } from '../page-view/page-view.module';
import { OntologyViewerModule } from '../ontology-viewer/ontology-viewer.module';
import { TlnPageViewComponent } from './tln-page-view.component';
import { TlnQueryService, TlnCacheQueryService } from './tln-query.service';
import { ToolTipComponent } from './tooltip/tool-tip.component';
import { TlnViewerNavigation } from './tln-viewer-navigation/tln-viewer-navigation.component';
import { TlnInformationComponent } from './tln-information/tln-information.component';
import { TlnCrossrefComponent } from './tln-crossref/tln-crossref.component';
import { VersionViewComponent } from './tln-crossref/version-view/version-view.component';
import { PageVersionViewComponent } from './tln-crossref/page-version-view/page-version-view.component';
import { NavigationComponent } from './tln-crossref/navigation/navigation.component';
import { GeneticOrderFilterPipe} from './tln-crossref/page-version-view/filter.pipe';
import { TlnPageVersionViewComponent } from './tln-crossref/page-version-view/tln-page-version-view.component';
import { TlnManuscriptViewComponent } from './tln-manuscript-view/tln-manuscript-view.component';
import { TlnFulltextComponent } from './tln-fulltext/tln-fulltext.component';
import { FulltextNavigationComponent } from './tln-fulltext/navigation/navigation.component';
import { ResultPipePipe } from './tln-fulltext/result-pipe.pipe';
import { PageResultFilterPipe} from './common/page-result-filter.pipe';
import { SearchComponent } from './tln-navigation-elements/search.component';
import { ToggleNavigationComponent } from './tln-navigation-elements/toggle-navigation.component';
import { ZoomComponent } from './tln-navigation-elements/zoom.component';
import { ZoomPipe } from './tln-navigation-elements/zoom.pipe';
import { OpenInViewerComponent } from './tln-navigation-elements/open-in-viewer.component';
import { DebugPipe } from './debug.pipe';
import { TlnHeightDirective } from './tln-height.directive';
import { TlnQuantComponent } from './tln-quant/tln-quant.component';
import { TlnTableComponent } from './tln-quant/tln-table.component';
import { ResultPipe } from './tln-quant/result.pipe';
import { WordPresentationComponent } from './tln-quant/word-presentation.component';
import { ToIdsPipe } from './tln-quant/2-ids.pipe';
import { SortByLinePipe } from './tln-quant/sort-by-line.pipe';
import { QueryErrorComponent } from './tln-quant/query-error.component';
import { ExportComponent } from './tln-quant/export.component';
import { FusekiTableComponent } from './tln-quant/fuseki-table.component';
import { TlnOntologyViewerComponent } from './tln-quant/tln-ontology-viewer.component';
import { EditorCommentComponent } from './tooltip/editor-comment.component';
import { MergeEditorCommentPipe } from './tooltip/merge-editor-comment.pipe';
import { OverwrittenComponent } from './tooltip/overwritten.component';
import { FootnoteComponent } from './tln-information/footnote.component';
import { ImprintComponent } from './tln-information/imprint.component';
import { TlnStatusComponent } from './tln-status/tln-status.component';
import { TlnLeafDescriptionComponent } from './tln-manuscript-view/tln-leaf-description.component';
import { TlnDataComponent } from './tln-quant/tln-data.component';
import { TlnTypeComponent } from './tln-quant/tln-type.component';
import { OpenInManuscriptComponent } from './tln-navigation-elements/open-in-manuscript.component';


@NgModule({
  declarations: [FulltextNavigationComponent, TlnPageViewComponent, ToolTipComponent, TlnViewerNavigation, TlnInformationComponent, TlnCrossrefComponent, VersionViewComponent, PageVersionViewComponent, NavigationComponent, GeneticOrderFilterPipe, TlnPageVersionViewComponent, TlnManuscriptViewComponent, TlnFulltextComponent, PageResultFilterPipe, ResultPipePipe, SearchComponent, ToggleNavigationComponent, ZoomComponent, ZoomPipe, OpenInViewerComponent, DebugPipe, TlnHeightDirective, TlnQuantComponent, TlnTableComponent, ResultPipe, WordPresentationComponent, ToIdsPipe, SortByLinePipe, QueryErrorComponent, ExportComponent, FusekiTableComponent, TlnOntologyViewerComponent, EditorCommentComponent, MergeEditorCommentPipe, OverwrittenComponent, FootnoteComponent, ImprintComponent, TlnStatusComponent, TlnLeafDescriptionComponent, TlnDataComponent, TlnTypeComponent, OpenInManuscriptComponent],
  imports: [
    RouterModule,
    CodemirrorModule,
     MatBadgeModule,
     MatSnackBarModule,
     MatSlideToggleModule,
     MatSliderModule,
     MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatDialogModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatProgressSpinnerModule,
    MatPaginatorModule,
    MatRadioModule,
    MatSelectModule,
    MatSidenavModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatTreeModule,
    MatToolbarModule,
    MatTooltipModule,
    BrowserModule,
    CommonModule,
    FormsModule,
    NgxMatStandoffMarkupModule,
     PageViewModule,
     OntologyViewerModule
  ],
   exports: [
      DebugPipe,
      FulltextNavigationComponent,
      NavigationComponent,
      ToggleNavigationComponent,
      ToolTipComponent,
      TlnStatusComponent,
      TlnCrossrefComponent,
      TlnHeightDirective,
      TlnManuscriptViewComponent,
      TlnPageViewComponent,
      TlnViewerNavigation,
      TlnQuantComponent,
      TlnDataComponent
   ], 
   providers: [
      PageViewService,
      CacheService
      //TlnQueryService
   ],
   entryComponents: [TlnInformationComponent, QueryErrorComponent]
})
export class TlnEditionModule { 
}
