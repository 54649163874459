import { Component, Input, OnInit, OnChanges, SimpleChanges} from '@angular/core';
import { externalAssignClass, externalAssignStyle, Configuration, Identifier, Image, Line, TextField, TextByForeignHand, Word} from './models';
import { Matrix} from './common/matrix';
import { createLocalZoomFactor } from './common/shared_functions';
/**
 * This component displays one or two {@link /components/TextFieldComponent.html|TextFieldComponent(s)} 
 * and its or their {@link /components/MarginFieldComponent.html|MarginFieldComponent(s)}.
 **/
@Component({
  selector: 'page-view',
  templateUrl: './page-view.component.html',
  styleUrls: ['./page-view.component.css']
})
export class PageViewComponent implements OnInit, OnChanges {
   @Input() configuration: Configuration;
   /**
    * the search text of words that should be highlighted as {@link /miscellaneous/enumerations.html#HIGHTLIGHT_CASES|HIGHTLIGHT_CASES.SEARCHED_WORD}.
    **/
   @Input() findText: string;
   /**
    * first texts written by foreign hand 
    **/
   @Input() first_foreign_texts: TextByForeignHand[] = [];
   /**
    * the first image that will be displayed by {@link /components/TextFieldComponent.html|TextFieldComponent}.
    **/
   @Input() first_image: Image;
   /**
    * the Array of lines of the first image that will be displayed by {@link /components/MarginFieldComponent.html|MarginFieldComponent}.
    **/
   @Input() first_lines: Line[];
   first_unevenlines: Line[] = [];
   /**
    * Identification of first textfield.
    **/
   first_textfield_id: string = 'first textfield'
   /**
    * the Array of words of the first image that will be displayed by {@link /components/TextFieldComponent.html|TextFieldComponent}.
    **/
   @Input() first_words: Word[];
   /**
    * the zoom factor for the first image that will be used by {@link /components/TextFieldComponent.html|TextFieldComponent}.
    **/
   first_local_zoom_factor: number = -1;
   /**
    * the (initial) maximum height of the image(s).
    **/
   @Input() max_height: number = -1;
   /**
    * the (initial) maximum width of the image(s).
    **/
   @Input() max_width: number = -1;
   /**
    * should primary url be used for image. use secondary url if false.
    **/
   @Input() preferPrimaryUrl: boolean = true;
   /**
    * Rotation value, i.e. one of [ 0, 90, 180, 270 ].
    **/
   @Input() rotation: number = 0;
   /**
    * second texts written by foreign hand 
    **/
   @Input() second_foreign_texts: TextByForeignHand[] = [];
   /**
    * the second image that will be displayed by {@link /components/TextFieldComponent.html|TextFieldComponent}.
    **/
   @Input() second_image: Image;
   /**
    * the Array of lines of the second image that will be displayed by {@link /components/MarginFieldComponent.html|MarginFieldComponent}.
    **/
   @Input() second_lines: Line[];
   second_unevenlines: Line[] = [];
   /**
    * Identification of second textfield.
    **/
   second_textfield_id: string = 'second textfield'
   /**
    * the Array of words of the second image that will be displayed by {@link /components/TextFieldComponent.html|TextFieldComponent}.
    **/
   @Input() second_words: Word[];
   /**
    * the zoom factor for the second image that will be used by {@link /components/TextFieldComponent.html|TextFieldComponent}.
    **/
   second_local_zoom_factor: number = -1;
   /**
    * An optional function that will be passed to {@link /components/TextFieldComponent.html|TextFieldComponent} 
    * in order to return a further highlight class
    * to the word rects when the internal function would return 'textfield unhighlighted'. 
    **/
   @Input('assignClass') assignClass?: externalAssignClass;
   /**
    * An optional function that will be passed to {@link /components/TextFieldComponent.html|TextFieldComponent}
    * and {@link /components/MarginFieldComponent.html|MarginFieldComponent}
    * in order to return a (svg-)style object 
    * to the word and line rects. This function allows the user to extend the style of this component.
    * E.g. by returning { fill: blue } the function overwrites the default behaviour and sets
    * the default highlight color to blue.
    **/
   @Input('assignStyle') assignStyle?: externalAssignStyle;
   /**
    * global zoom factor.
    **/
   @Input() zoomFactor: number = 1;
   /**
    * identifiers of selected words that should be highlighted.
    **/
   @Input() selectedWords: Identifier[] = [];
   /**
    * identifiers of selected lines that should be highlighted.
    **/
   @Input() selectedLines: Identifier[] = [];
   @Input('startLine') startLineId: Identifier;
   @Input('endLine') endLineId: Identifier;
   @Input() showAllLines: boolean = false;
   @Input() dontShowReference: boolean;
   showReferenceLeft: string = 'from';
   showReferenceRight: string = 'to';

  constructor() {}
  
  /**
   * sets {@link /components/PageViewComponent.html#max_height|max_height} if it is unset.
   **/
  ngOnInit() {
     if (this.max_height == -1 && this.max_width == -1){
         this.max_height = screen.availHeight;
     }
     this.checkImages();
  }
  ngOnChanges(){
     if (this.showAllLines){
         this.dontShowReference = true;
         let allLinesFirst = new Set(this.first_words.map(word =>word.line));
         let allLinesSecond = new Set(this.second_words.map(word =>word.line));
         this.first_unevenlines = this.first_lines.filter(line => (line.number % 2 == 1 && allLinesFirst.has(line.id)));
         this.second_unevenlines = this.second_lines.filter(line => (line.number % 2 == 1 && allLinesSecond.has(line.id)));
     } 
     if (this.dontShowReference != undefined && this.dontShowReference != null && this.dontShowReference){
         this.showReferenceLeft = '';    
         this.showReferenceRight = '';
     } else {
         this.showReferenceLeft = 'from';    
         this.showReferenceRight = 'to';
     }
     this.checkImages();
     if (this.first_image != null && this.first_image != undefined){
         this.first_local_zoom_factor = createLocalZoomFactor(this.first_image.text_field, this.max_height, this.max_width);
     }
     if (this.second_image != null && this.second_image != undefined){
        this.second_local_zoom_factor = createLocalZoomFactor(this.second_image.text_field, this.max_height, this.max_width);
     }
 }
 /**
  * Check whether only part of image should be displayed and update TextField
  **/
 private checkImages(){
      if (this.first_image != null && this.first_image != undefined && this.startLineId != null && this.startLineId != undefined){
         if(this.first_lines != null && this.first_lines != undefined && this.first_lines.length > 0){
            this.first_image = this.updateTextField(this.first_image, this.first_lines);
         }
         if(this.second_lines != null && this.second_lines != undefined && this.second_lines.length > 0){
            this.second_image = this.updateTextField(this.second_image, this.second_lines);
         }
     }
  }
  /**
   * Return whether transfomation of image resulting from the transform matrix and the 
   * current rotation angle (i.e. one of [ 0, 90, 180, 270 ]) requires
   * a dimension switch, i.e. replacement of width and height value.
   * */
  private switchDimensions(transform?: string): boolean {
     return (new Matrix(transform, 1, 0, 0, this.rotation)).get90DegreeRotation() % 180 == 90
  }
  private updateTextField(image: Image, lines: Line[]): Image {
      let endLineId = (this.endLineId != null && this.endLineId != undefined) ? this.endLineId : this.startLineId;
      let startLines = lines.filter(line =>line.id == this.startLineId)
      let endLines = lines.filter(line =>line.id == endLineId)
      if (startLines.length > 0 && endLines.length > 0){
         let top = (startLines[0].top > 10) ? startLines[0].top-10 : startLines[0].top;
         let height =  (endLines[0].bottom-top)+10;
         let text_field: TextField = { top: top, left: image.text_field.left, width: image.text_field.width, height: height }
         return { x: image.x, y: image.y, width: image.width, height: image.height, filename: image.filename,
               URL: image.URL, secondaryURL: image.secondaryURL, text_field: text_field, transform: image.transform, 
               copyright: image.copyright }
      }
      return image;
   }
  /**
   * Returns whether the two images can be displayed as columns.
   **/
  private hasColumnStyle(): boolean {
     if (this.zoomFactor <= 1 || this.first_image == null || this.second_image == null){
        return true 
     }
     let newLeftWidth = this.max_height/this.first_image.text_field.height*this.zoomFactor*this.first_image.text_field.width;
     let newRightWidth = this.max_height/this.second_image.text_field.height*this.zoomFactor*this.second_image.text_field.width;
     return newLeftWidth + newRightWidth < screen.availWidth;
  }
}
