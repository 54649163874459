import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { TLN_SEARCH_ROUTE, TLN_FIND_PARAM } from '../constants';
import { Mapping } from '../route-reader';
import { RouteUpdater } from '../route-updater';

@Component({
  selector: 'search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})
export class SearchComponent extends RouteUpdater {
  findText: string;
  protected mapping: Mapping = { findText: { param: TLN_FIND_PARAM, type: "string" }}

  constructor(protected router: Router, protected activatedRoute: ActivatedRoute ) { 
      super(router, activatedRoute);
  }
  openFulltextSearch(){
     this.currentRoute = TLN_SEARCH_ROUTE
     this.updateParams();
  }
  private clearFindText() {
     this.findText = '';
     this.updateParams();
  }

}
