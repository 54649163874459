import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Link } from './models';

@Component({
  selector: 'content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.css']
})
export class ContentComponent implements OnInit {
  @Input() content: string|Link;
  text: string;
  @Input() links: Link[] = [];
  queryParams: Params;

   constructor(private activatedRoute: ActivatedRoute ) { }

  ngOnInit() {
     this.activatedRoute.queryParams.subscribe( (queryParams: Params ) => {
         this.queryParams = queryParams;
     });
     if (this.links.length == 0){
        if(typeof this.content == 'string'){
            this.text = this.content;
        } else {
            this.links = [ this.content ] ;
        }
     }
  }
}
