import { Component, Input, OnInit } from '@angular/core';
import { OntologyQueryService } from './ontology-query.service';
import { DataMapping, QueryDataMapping, Ontology, OntologyBearer } from './models';
import { SparqlParser } from './sparqljs';
import { OntologyRetriever } from './ontology-retriever';

@Component({
  selector: 'ontology-classes',
   template: `<ontology-display *ngIf="dataMappings.length > 0" [dataMappings]="dataMappings | sortSubjectsAlpha"></ontology-display>`,
  styleUrls: []
})
export class OntologyClassesComponent extends OntologyRetriever {
  query: string = `
  PREFIX rdfs: <http://www.w3.org/2000/01/rdf-schema#>
  PREFIX owl: <http://www.w3.org/2002/07/owl#>

  SELECT ?class ?label ?comment ?superClass WHERE {
      ?class a owl:Class;
         rdfs:isDefinedBy ?ontology.
         OPTIONAL { ?class rdfs:label ?label }
         OPTIONAL { ?class rdfs:comment ?comment }
         OPTIONAL { ?class rdfs:subClassOf ?superClass.
               FILTER(!isBlank(?superClass))
               FILTER (?class != ?superClass)
         }
   }`

   constructor(protected queryService: OntologyQueryService) { 
      super(queryService);
   }

}
