import { Component, Input, OnInit } from '@angular/core';
import { OntologyQueryService } from './ontology-query.service';
import { OntologyRetriever } from './ontology-retriever';

@Component({
  selector: 'ontology-properties',
  template: `<ontology-display *ngIf="dataMappings.length > 0" [dataMappings]="dataMappings | sortSubjectsAlpha"></ontology-display>`,
  styleUrls: []
})
export class OntologyPropertiesComponent extends OntologyRetriever {
  @Input() query: string;

   constructor(protected queryService: OntologyQueryService) { 
      super(queryService);
   }

}
