import { Component, OnInit, Input } from '@angular/core';
import { TextVersion} from '../../models';

@Component({
  selector: 'version-view',
  templateUrl: './version-view.component.html',
  styleUrls: ['./version-view.component.css']
})
export class VersionViewComponent implements OnInit {
   @Input() version: TextVersion;
   @Input() current_iri: string;

  constructor() { }

  ngOnInit() {
  }

}
