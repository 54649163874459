import { Pipe, PipeTransform } from '@angular/core';
import { PresentationWord } from '../datatypes/word_presentation';

@Pipe({
  name: 'sortByLine'
})
export class SortByLinePipe implements PipeTransform {

  transform(words: PresentationWord[]): PresentationWord[] {
    return words.sort((n1,n2) => n1.line_number - n2.line_number);
  }

}
