import { OnInit } from '@angular/core';
import { DataProcessor } from '../models';


export interface CompareMapping {
   compareValueKey: string;
   sourceArrayKey: string;
   targetArrayKey: string[];
   commonPropertyKey: string;
   arrayUpdated: string;
}
export class SelectFromArray implements DataProcessor {
  constructor(private onInit: OnInit, private compareMapping: CompareMapping){}

  private getItem(item: any, path: string[]): any {
      if (path.length == 1){
         return item[path[0]];
      } else {
         return this.getItem(item[path[0]], path.slice(1));
      }
  }
  public processData(){
     const target = this.getItem(this.onInit, this.compareMapping.targetArrayKey.slice());
     const compareValue = this.onInit[this.compareMapping.compareValueKey]
     this.onInit[this.compareMapping.sourceArrayKey].forEach(item =>{
        if(item[this.compareMapping.commonPropertyKey] === compareValue && target.indexOf(item) == -1){
            target.push(item)
        }
     });
     this.onInit[this.compareMapping.arrayUpdated]();
  }
}
