import { Component, OnInit, Input, ElementRef } from '@angular/core';
import {MatBottomSheet, MatBottomSheetRef} from '@angular/material/bottom-sheet';
import { PageViewService } from '../page-view.service';
import { CopyrightSheetComponent } from './copyright-sheet/copyright-sheet.component';
import { Copyright, Point } from '../models';


@Component({
  selector: 'copyright',
  templateUrl: './copyright.component.html',
  styleUrls: ['./copyright.component.css']
})
export class CopyrightComponent implements OnInit {
   @Input() dimension: number;
   highlight: boolean = false;
   @Input() copyright: Copyright;

  constructor(private bottomSheet: MatBottomSheet, private pageViewService: PageViewService) { }

  ngOnInit() {
  }
  private setHighlight(highlight: boolean){
      this.highlight = highlight;
  }
  private showCopyrightInformation(e: MouseEvent){
     /*let point: Point = { visible: true, clientX: e.clientX, clientY: e.clientY, layerX: e.layerX, layerY: e.layerY }
     this.pageViewService.copyrightService(this.copyright, point);*/
     this.bottomSheet.open(CopyrightSheetComponent, {
        data: this.copyright 
     });

  }
}
