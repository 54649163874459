import { Directive, ElementRef, OnInit, Input } from '@angular/core';
import { TlnPageViewComponent } from './tln-page-view.component';

@Directive({
  selector: '[setMaxHeightTo]'
})
export class TlnHeightDirective implements OnInit{
   @Input('setMaxHeightTo') pageView: TlnPageViewComponent;
   constructor(public el: ElementRef) { }

   ngOnInit(){
      if (this.pageView != undefined && this.pageView != null){
         this.pageView.max_height = window.innerHeight - this.el.nativeElement.offsetHeight -15;
      }
   }
}
