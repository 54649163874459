import { OnInit, EventEmitter} from '@angular/core';
import { first, takeUntil } from 'rxjs/operators';
import { BasicResultBindingElement, AskResult} from './datatypes/basic_datatype';
import { DataHandler, Handler } from './data_handler';
import { DataProcessor } from './models';

/**
 * This class retrieves quantitative data from  a query service and instantiates it using 
 * corresponding handlers.
 **/
export class QuantitativeDataHandler extends DataHandler {
   /**
    * @param component the component that uses this data handler
    **/
   constructor(protected component: OnInit){
      super(component)
   }
   /**
    * Retrieve and instantiate data
    * @param key data handler key
    * @param query iri that should be passed to query
    **/
   public getData4Query(key: string, query: string, raw_data_key?: string) {
      this.start_processing.emit(true);
      let handler = this[key]['handler'];
      this.queryService.getData(query).pipe(takeUntil(this.stop_processing)).subscribe(results => {
            this.component[key] = handler.convertData(results);
            if (raw_data_key != undefined && raw_data_key != null){
               this.component[raw_data_key] = results;
            }
            this.processing_finished.emit(true);
            if (this[key]['process_data'] != undefined && this[key]['process_data'] != null){
               this[key]['process_data'].processData(); 
            }
      });
   }
   /**
    * Retrieve raw data
    * @param raw_data_key where to store raw data in component
    * @param query query that should be executed 
    **/
   public getRawData4Query(raw_data_key: string, query: string, dataProcessor?: DataProcessor) {
      this.start_processing.emit(true);
      this.queryService.getData(query).pipe(takeUntil(this.stop_processing)).subscribe(results => {
            this.component[raw_data_key] = results;
            this.processing_finished.emit(true);
            if (dataProcessor != undefined && dataProcessor != null){
               dataProcessor.processData();
            }
      });
   }

   /**
    * Retrieve and instantiate data
    * @param key data handler key
    * @param query iri that should be passed to query
    * @param searchTermsString string containing the search terms 
    **/
   public getSearchData(key: string, query: string, searchTermsString: string) {
      this.start_processing.emit(true);
      let handler = this[key]['handler'];
      this.queryService.getData(query).pipe(takeUntil(this.stop_processing)).subscribe(results => {
            this.component[key] = handler.convertData(results, null, this[key]['service']);
            this.processing_finished.emit(true);
            if (this[key]['process_data'] != undefined && this[key]['process_data'] != null){
               this[key]['process_data'].processData(); 
            }
      });
   }
}
