import { Component, EventEmitter, Input, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import {PageEvent} from '@angular/material/paginator';
import { TLN_CROSSREF_ROUTE, TLN_SEARCH_ROUTE, TLN_MANUSCRIPT_ROUTE, TLN_VIEWER_ROUTE, TLN_CONTEXT_VIEW_PARAM, TLN_FULLSCREEN_PARAM, TLN_FIND_PARAM, TLN_PAGE_PARAM, TLN_MANUSCRIPT_PARAM,
   TLN_SEARCH_QUERY_PARAM, TLN_RESULT_INDEX_PARAM,TLN_SELECTED_LINES_PARAM, TLN_TEXT_GENETIC_ORDER_PARAM, TLN_VIEW_OPTION_PARAM, TLN_ZOOM_PARAM, VIEW_OPTIONS, ONTOLOTY_PREFIX } from '../constants';
import { ResultRange, PaginatorResultStatus } from '../common/paginator-result-status';
import { CacheService } from '../common/cache.service';
import { Handler, DataHandler } from '../data_handler';
import { QuantitativeDataHandler } from '../quant_data_handler';
import { FoundPage} from '../datatypes/search';
import { Manuscript4Selection} from '../datatypes/manuscript';
import { Mapping } from '../route-reader';
import { RouteUpdater } from '../route-updater';
import { TlnCacheQueryService } from '../services';
import {DataProcessor, PageIndexUpdater, QueryProperties, StorageHandler, TlnQueryServiceInterface} from '../models';
//TODO: german language support for paginator, see: https://github.com/ngx-translate/core
@Component({
  selector: 'tln-fulltext',
  templateUrl: './tln-fulltext.component.html',
  styleUrls: ['./tln-fulltext.component.css']
})
export class TlnFulltextComponent extends RouteUpdater implements DataProcessor, OnInit {
   /**
    * OPTIONAL pass a queryService with method 
    * {@link /interfaces/TlnQueryServiceInterface.html#getData|getData}
    * to TlnPageViewComponent.
    **/
   @Input() queryService: TlnQueryServiceInterface;
   tlnQueryService: TlnQueryServiceInterface;
   //protected currentRoute: string = TLN_SEARCH_ROUTE;
   change = new EventEmitter<number>();
   current_page_iri: string;
   current_manuscript_unity: string;
   expandOptions: boolean = false;
   dataHandler: QuantitativeDataHandler = new QuantitativeDataHandler(this);
   fullscreen: boolean;
   max_width: number = -1;
   max_height: number = -1;
   manuscriptPages: Manuscript4Selection[] = [];
   readonly manuscript_variable = "manuscript";
   searchTerm: string;
   resultIndex: number = 0;
   paginatorResultStatus: PaginatorResultStatus = new PaginatorResultStatus(5);
   resultsReceived: boolean = false;
   selectedViewOption: string = VIEW_OPTIONS.TRANSKRIPTION
   startSearch: boolean = false;
   protected mapping: Mapping = { 
              current_page_iri: { param: TLN_PAGE_PARAM, type: "string" },
   //         resultIndex: { param: TLN_RESULT_INDEX_PARAM, type: "number" },
              searchTerm: { param: TLN_FIND_PARAM, type: "string" },
              queryProps: { param: TLN_SEARCH_QUERY_PARAM, type: "complex" },
              current_manuscript_unity: { param: TLN_MANUSCRIPT_PARAM, type: "string" },
              fullscreen: { param: TLN_FULLSCREEN_PARAM, type: "boolean" },
   }
   pages: FoundPage[] = [];
   queryProps: QueryProperties = { ignoreCase: false, selectedManuscripts: [], resultIndex: 0 }
   private readonly margin_width: number = 280;
   private readonly initialPreviewWidth : number = 300;
   previewWidth: number = this.initialPreviewWidth;

   constructor(private cacheService: CacheService, private localQueryService: TlnCacheQueryService, protected router: Router, protected activatedRoute: ActivatedRoute ) { 
      super(router, activatedRoute);
   }

   ngOnInit() {
      if (screen.availWidth - this.initialPreviewWidth - this.margin_width > 1000){
         this.previewWidth = screen.availWidth - this.initialPreviewWidth - 1000;
      }
      this.tlnQueryService = (this.queryService != null) ? this.queryService : this.localQueryService;
      this.max_width = screen.availWidth - this.previewWidth - this.margin_width;
      this.max_height = screen.availHeight - 200;
      this.dataHandler.addHandler('manuscriptPages', { 'handler': Manuscript4Selection});
      this.dataHandler.addHandler('pages', { 'handler': FoundPage, 'process_data': this });
      this.dataHandler['pages']['service'] = this
      this.dataHandler.setQueryService(this.tlnQueryService);
      this.dataHandler.start_processing.subscribe(
         (started: boolean) =>{ 
            this.resultsReceived = false;
            this.startSearch = true;
      });
      this.dataHandler.processing_finished.subscribe(
         (finished: boolean) =>{ 
            this.resultsReceived = true;
            this.startSearch = false;
      });
      this.dataHandler.getData('manuscriptPages');
      super.ngOnInit();
      this.expandOptions = (this.queryProps.selectedManuscripts.length > 0 || this.queryProps.ignoreCase)
   }
  private clearFindText() {
     this.searchTerm = '';
     this.pages = [];
     this.queryProps.resultIndex = 0;
     super.updateParams();
  }
  private search(){
      this.queryProps.resultIndex = 0;
      this.paginatorResultStatus.updateResultRange(this.queryProps.resultIndex);
      super.updateParams();
      if (this.searchTerm != undefined && this.searchTerm != null && this.searchTerm != ''){
         this.dataHandler.resetData('pages');
         let query = this.dataHandler['pages'].handler.getSeachQuery(this.searchTerm, this.queryProps.ignoreCase, this.queryProps.selectedManuscripts, this.manuscript_variable)
         this.dataHandler.getSearchData('pages', query, this.searchTerm);
      }
  }
  protected readParams(params: Params){
      let oldSearchTerm = this.searchTerm;
      super.readParams(params);
      if (this.searchTerm != undefined && this.searchTerm != null && this.searchTerm != '' && this.searchTerm != oldSearchTerm){
         let results = (this.queryProps != null && this.queryProps.dataKey != undefined && this.queryProps.dataKey != null)
            ? this.cacheService.getItem(this.queryProps.dataKey + this.searchTerm) : null;
         if (results != null){
            this.pages = results;
         } else {
            this.dataHandler.resetData('pages');
            let query = this.dataHandler['pages'].handler.getSeachQuery(this.searchTerm, this.queryProps.ignoreCase, this.queryProps.selectedManuscripts, this.manuscript_variable)
            this.dataHandler.getSearchData('pages', query, this.searchTerm);
         }
      } 
      if(this.pages.length > 0 && this.queryProps.resultIndex > 0 ){
         this.paginatorResultStatus.updateResultRange(this.queryProps.resultIndex);
      }
   }
   getSearchTerms(): string[] {
      return this.searchTerm.split(' '); 
   }
   getIgnoreCaseFlag(): string {
      return (this.queryProps.ignoreCase) ? 'i' : '';
   }
   isSelected = (o1: any, o2: any): boolean => {
     return o1.id == o2.id;
  }
   private updateResultIndex(event: PageEvent){
      this.paginatorResultStatus.showResults(event);
      this.queryProps.resultIndex = event.pageIndex;
      this.updateParams();
   }
  public processData(){
     if (this.queryProps != null 
        && this.queryProps.dataKey != undefined 
        && this.queryProps.dataKey != null){
        this.cacheService.removeItem(this.queryProps.dataKey + this.searchTerm);
     }
     const dataKey = TLN_SEARCH_ROUTE + Date.now().toString();
     this.cacheService.setItem(dataKey + this.searchTerm, this.pages);
     this.queryProps['dataKey'] = dataKey;
     this.updateParams();
  }

}
