import { Parser, Generator } from 'sparqljs';
import { KeyIriMapping } from '../data_handler';
/** 
 * this interface specifies the head of {@link /interfaces/FusekiResults.html|FusekiResults}.
 **/
interface FusekiVars {
   vars: string[];
}
/** 
 * this interface specifies the bindings of {@link /interfaces/FusekiResults.html|FusekiResults}.
 **/
export interface FusekiBindings {
   bindings: [];
}
/** 
 * this interface specifies the results as they are retrieved from an Apache Jena Fuseki server.
 **/
export interface FusekiResults {
   results: FusekiBindings; 
   head: FusekiVars
}
export interface FusekiBoolean {
   head: any;
   boolean: boolean;
}
export class FusekiResultsInstance {
   public static fixFusekiResults(fusekiResults: FusekiResults){
      if (fusekiResults.results.bindings.length > 0 
         && Object.keys(Array.of(...fusekiResults.results.bindings)[0]).length < fusekiResults.head.vars.length) {
         fusekiResults.head.vars = fusekiResults.head.vars.filter(key => Object.keys(Array.of(...fusekiResults.results.bindings)[0]).includes(key));
      }
   }
}
/**
 * This is the basic datatype that instantiates an element of {@link /interfaces/FusekiResults.html|FusekiResults}.
 *
 * All datatypes can be subclassed from this type in order to create SPARQL-queries, retrieve data and convert it
 * to the corresponding datatypes.
 **/
export class BasicResultBindingElement {
   /** 
    * the internal default key for replacing {@link /classes/BasicResultBindingElement.html#query|query} by "id"
    * in {@link /classes/BasicResultBindingElement.html#getQuery|getQuery} if "key" is omitted.
    **/
   protected static readonly default_key: string = 'id';
   /** 
    * the SPARQL-query of this datatype.
    **/
   static readonly query: string = `SELECT ?id ?p ?o WHERE { ?id ?p ?o. }`;
   /** 
    * the public key for replacing {@link /classes/BasicResultBindingElement.html#query|query} by "id".
    **/
   public static readonly query_key: string = null;
   /**
    * the id of this datatype.
    **/
   public id: string;
   /**
    * the raw data of this datatype, i.e. a singular bindings element of {@link /interfaces/FusekiBindings.html|FusekiBindings}.
    **/
   protected data: any;
   /**
    * whether or not to pass the id used for the query to the constructor and 
    * use it as the value of the property specified by query_key.
    **/
   public static readonly use_id: boolean = false; 
   /**
    * a service that this datatype can use in order to communicate with its data holder.
    **/
   protected service: any;

   /**
    * The constructor creates a datatype from the data.
    *
    * @param id if omitted the id will be retrieved from data
    **/
   constructor(data: any, id?: string, service?: any){
      this.data = data;
      this.service = service;
      if (id != undefined && id != null && id != ''){
         let key = (Object.getPrototypeOf(this).constructor.use_id 
                   && Object.getPrototypeOf(this).constructor.query_key != null) 
                   ? Object.getPrototypeOf(this).constructor.query_key : 'id';
         this[key] = id;
      } 
      if (this.id == null){
         this.id = this.getData4Key('id'); 
      }
   }
   /**
    * This function returns the value of the content specified by "key" from  {@link /classes/BasicResultBindingElement.html#data|data}.
    * 
    * @param key the key that specifies the content
    *
    * @returns {any} the value of the content if key exists else null
    **/
   protected getData4Key(key: string): any {
      if (!this.data.hasOwnProperty(key)) {
         return null;
      }
      if (this.data[key].datatype == 'http://www.w3.org/2001/XMLSchema#boolean'){
         return JSON.parse(this.data[key].value);
      } else if (this.data[key].datatype == 'http://www.w3.org/2001/XMLSchema#integer'){
         return Number(this.data[key].value);
      }
      return this.data[key].value;
   }
   public removeService(){
      this.service = null;
   }
   /**
    * This method returns the SPARQL query of this BasicResultBindingElement.
    * The query can be modified by providing an "id" and "key" such that every "key" in
    * the query will be replaced by "id".
    *
    * If "key" is omitted {@link /classes/BasicResultBindingElement.html#default_key|default_key} will be used.
    *
    * @param id will replace key in query
    * @param key will be replaced by id. 
    **/
   public static getQuery(id?: string, key?: string): string {
      if (typeof(id) === 'undefined' || id === null || id == ''){
         return this.query;
      } else {
         if (key == null || key == ''){
            key = this.default_key;
         }
         let parser = new Parser();
         let sparqlGenerator = new Generator({});
         let parsedQuery = parser.parse(this.query)
         for (var k = 0; k < parsedQuery.where.length; k++){
            if (parsedQuery.where[k].patterns != undefined){
               for (var j = 0; j < parsedQuery.where[k].patterns.length; j++){
                  if (parsedQuery.where[k].patterns[j].triples != undefined) {
                     for (var i = 0; i < parsedQuery.where[k].patterns[j].triples.length; i++){
                        if(parsedQuery.where[k].patterns[j].triples[i]['subject']['value'] == key){
                           parsedQuery.where[k].patterns[j].triples[i]['subject'] = { termType: "NamedNode", value: id };
                        } else if(parsedQuery.where[k].patterns[j].triples[i]['object']['value'] == key){
                           parsedQuery.where[k].patterns[j].triples[i]['object'] = { termType: "NamedNode", value: id };
                        } else if(parsedQuery.where[k].patterns[j].triples[i]['predicate']['value'] == key){
                           parsedQuery.where[k].patterns[j].triples[i]['predicate'] = { termType: "NamedNode", value: id };
                        }
                     }
                  }
               }
            } else if (parsedQuery.where[k].triples != undefined){
               for (var i = 0; i < parsedQuery.where[k].triples.length; i++){
                  if(parsedQuery.where[k].triples[i]['subject']['value'] == key){
                     parsedQuery.where[k].triples[i]['subject'] = { termType: "NamedNode", value: id };
                  } else if (parsedQuery.where[k].triples[i]['object']['value'] == key){
                     parsedQuery.where[k].triples[i]['object'] = { termType: "NamedNode", value: id };
                  } else if (parsedQuery.where[k].triples[i]['predicate']['value'] == key){
                     parsedQuery.where[k].triples[i]['predicate'] = { termType: "NamedNode", value: id };
                  }
               }
            }
         }
         return sparqlGenerator.stringify(parsedQuery);
      }
   }
   public static contentConforms2Type(data: FusekiResults): boolean {
      let parser = new Parser();
      let parsedQuery = parser.parse(this.query)
      let variableCounter =  parsedQuery['variables'].length;
      parsedQuery['variables'].forEach(item =>{
         if(data.head.vars.includes(item.value)){
            variableCounter--;
         }
      });
      return variableCounter == 0; 
   }
   public static getComplexQuery(keyIriMapping: KeyIriMapping[]): string {
     let parser = new Parser();
     let sparqlGenerator = new Generator({});
     let parsedQuery = parser.parse(this.query)
     for (let mapping of keyIriMapping){
         let key = mapping.key;
         let id = mapping.iri;
         for (var i = 0; i < parsedQuery.where[0].triples.length; i++){
            if(parsedQuery.where[0].triples[i]['subject']['value'] == key){
               parsedQuery.where[0].triples[i]['subject'] = { termType: "NamedNode", value: id };
            } else if (parsedQuery.where[0].triples[i]['object']['value'] == key){
               parsedQuery.where[0].triples[i]['object'] = { termType: "NamedNode", value: id };
            } else if (parsedQuery.where[0].triples[i]['predicate']['value'] == key){
               parsedQuery.where[0].triples[i]['predicate'] = { termType: "NamedNode", value: id };
            }
         }
      }
      return sparqlGenerator.stringify(parsedQuery);
   }
   /**
    * This function returns 'results.bindings' of {@link /interfaces/FusekiResults.html|FusekiResults}.
    **/
   public static getContent(data: FusekiResults): [] {
      return data['results']['bindings'];
   }
   /**
    * This static function instantiates the subclasses of {@link /classes/BasicResultBindingElement.html|BasicResultBindingElement} from
    * the data retrieved by executing the query that is provided by {@link /classes/BasicResultBindingElement.html#getQuery|getQuery}.
    *
    * @param this a subclass of BasicResultBindingElement
    * @param data the fuseki result json
    * @param id the id that has been used in order to retrieve the data and that will identify the instantiation of the subclass.
    * @param service a means to communicate with the data holder.
    *
    * @returns Array of subclass instantiations
    **/
   public static convertData<T extends typeof BasicResultBindingElement>(this: T, data: FusekiResults, id?: string, service?: any): Array<InstanceType<T>> {
      let elements = []; 
      let content = this.getContent(data);
      for (var i = 0; i < content.length; i++){
         let element = new this(content[i], id, service) as InstanceType<T>;
         elements.push(element);
      }
      return elements;
   }
}
export class AskResult extends BasicResultBindingElement {
   static readonly query: string = `
   PREFIX tln: <http://www.nie.org/ontology/nietzsche#>
   ASK { 
      ?id a ?type.
   }`;

   public static getAnswer(answer: FusekiBoolean): boolean {
      return answer.boolean;
   }
}
export class PageExists extends AskResult {
   static readonly query: string = `
   PREFIX tln: <http://www.nie.org/ontology/nietzsche#>
   ASK { 
      ?id a tln:Page.
   }`;

   public static readonly query_key: string = 'id';
}

export class IsReconstructedKonvolut extends AskResult {
   static readonly query: string = `
   PREFIX tln: <http://www.nie.org/ontology/nietzsche#>
   ASK { 
      ?id a tln:ReconstructedKonvolut.
   }`;

   public static readonly query_key: string = 'id';
}


