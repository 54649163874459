import { Pipe, PipeTransform } from '@angular/core';
import { Content } from './models';

@Pipe({
  name: 'addContent'
})
export class AddContentPipe implements PipeTransform {

  transform(index: number, titles: Content[]): string{
     //titles.push({ title: title, link: title.replace(/ /g, '_')});
     //return title;
      console.log(titles.length, index);
      if (titles == undefined || titles == null || titles.length <= index) return '';
      return '#' + titles[index].link;
  }

}
