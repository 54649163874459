import { Component, OnInit } from '@angular/core';
import {Subscription} from 'rxjs';
import {ActivatedRoute, Params} from '@angular/router';
import { CONTENT_VIEW_ROUTE, CONCEPTION_ROUTE, DATA_ROUTE, DOCUMENTATION_ROUTE, HELP_ROUTE, HOME_ROUTE, IMPRESSUM_ROUTE, PROJECT_ROUTE } from '../constants';

@Component({
  selector: 'app-main-menu-component',
  templateUrl: './main-menu-component.component.html',
  styleUrls: ['./main-menu-component.component.scss']
})
export class MainMenuComponentComponent implements OnInit {
  contentViewLink: string[] = [ CONTENT_VIEW_ROUTE ];
  fullScreen: string;
  impressumLink: string[] = [ IMPRESSUM_ROUTE ];
  homeLink: string[] = [ HOME_ROUTE ];
  supportLink: string[] = [ HELP_ROUTE ];
  projectLink: string[] = [ PROJECT_ROUTE];
  dataLink: string[] = [ DATA_ROUTE];
  conceptionLink: string[] = [ CONCEPTION_ROUTE];
  queryParamSub: Subscription;
  queryParams: Params;

  constructor(private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    this.queryParamSub = this.activatedRoute.queryParams.subscribe( (queryParams: Params ) => {
      this.fullScreen = queryParams.fullscreen;
      this.queryParams = queryParams;

    });
  }

}
