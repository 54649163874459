import {Sort} from '@angular/material/sort';
import { FusekiResults } from '../datatypes/basic_datatype';

export class MySort {
   public sortFusekiData(sort: Sort, fusekiResults: FusekiResults){
      let bindings = fusekiResults.results.bindings;
      let data = bindings.slice();
      if (!sort.active || sort.direction === '') {
         fusekiResults.results.bindings = <[]>data;
         return;
      }
      fusekiResults.results.bindings = <[]>data.sort((a, b) =>{
            let valueA = <number|string>(a[sort.active]['value']);
            let valueB = <number|string>(b[sort.active]['value']);
            if ('datatype' in a[sort.active] && String(a[sort.active]['datatype']).endsWith('integer')){
               valueA = Number(valueA);
               valueB = Number(valueB);
            } 
            return this.compare(valueA, valueB, sort.direction === 'asc')
      });
   }
   public compare(a: number | string, b: number | string, isAsc: boolean): number {
      return (a < b ? -1 : 1) * (isAsc ? 1: -1);
   }
}
