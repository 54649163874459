import {Injectable} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';

@Injectable()
export class NavigationServiceService {

  constructor( private router: Router,
               private activatedRoute: ActivatedRoute ) {
  }

  /**
   * updateRoute
   * routes to the active url with the actual or passed query params of this class.
   */
  updateRoute(qParams?: Params, relPath?: string) {
    if ( relPath ) {
      this.router.navigate([relPath], { relativeTo: this.activatedRoute, queryParams: qParams, queryParamsHandling: 'merge' });
    } else {
      this.router.navigate([], { relativeTo: this.activatedRoute, queryParams: qParams, queryParamsHandling: 'merge' });
    }
  }
}
