import { Component, OnInit, OnDestroy, HostListener, Input, ElementRef } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import {MatBottomSheet, MatBottomSheetRef} from '@angular/material/bottom-sheet';
import { EditorComment, TlnQueryServiceInterface, Reference, ManuscriptUnity, NavigationPage } from '../models';
import { TlnManuscriptUnity, TlnNavigationPage} from '../datatypes/navigation';
import { DEFAULT_VIEW_OPTION, TLN_VIEWER_ROUTE, TLN_FULLSCREEN_PARAM, TLN_FIND_PARAM, TLN_NAV_BAR_OPEN_STATE_PARAM, TLN_PAGE_PARAM, TLN_MANUSCRIPT_PARAM,
   TLN_ROTATION_PARAM, TLN_SELECTED_LINES_PARAM, TLN_SELECTED_WORDS_PARAM, TLN_VIEW_OPTION_PARAM, TLN_ZOOM_PARAM, VIEW_OPTIONS, ONTOLOTY_PREFIX } from '../constants';
import { IsReconstructedKonvolut } from '../datatypes/basic_datatype';
import { TlnLine} from '../datatypes/line';
import { TlnWord} from '../datatypes/word';
import { TlnTextGeneticOrder} from '../datatypes/text_version';
import { TlnEditorComment, TlnPageEditorCommentStub, TlnOverwrittenWordEditorComment, TlnLineEditorComment } from '../datatypes/editor_comment';
import { TlnImprint } from '../datatypes/imprint';
import { ManuscriptInfoLeaf } from '../datatypes/manuscript';
import { Mapping } from '../route-reader';
import { RouteUpdater } from '../route-updater';
import { ComplexKeyIriMapping, DataHandler, KeyIriMapping } from '../data_handler';
import { PageViewService, TlnCacheQueryService } from '../services';
import { TlnInformationComponent, ParentInformation } from '../tln-information/tln-information.component';
import { PageInformation } from '../tln-information/page-information';

@Component({
  selector: 'tln-viewer-navigation',
  templateUrl: './tln-viewer-navigation.component.html',
  providers: [ TlnCacheQueryService ],
  styleUrls: ['./tln-viewer-navigation.component.css']
})
export class TlnViewerNavigation extends RouteUpdater implements OnDestroy {
  /**
   * OPTIONAL pass a queryService with method 
   * {@link /interfaces/TlnQueryServiceInterface.html#getData|getData}
   * to TlnPageViewComponent.
   **/
  @Input() queryService: TlnQueryServiceInterface;
  /**
   * whether or not to show page view in fullscreen mode.
   **/
  fullscreen: boolean = false;
  findText: string;
  current_iri: string;
  current_manuscript_iri: string;
  manuscript_unity: ManuscriptUnity;
  current_page: NavigationPage;
  pageInformation: PageInformation;
  previous_page: NavigationPage;
  /**
   * page editor comments 
  **/
  page_editor_comments: TlnPageEditorCommentStub[] = [];
  /**
   * editor comments 
  **/
  editor_comments: TlnEditorComment[] = [];
  /**
   * overwritten editor comments 
  **/
  overwritten_comments: TlnEditorComment[] = [];
  /**
   * line editor comments 
  **/
  line_comments: TlnLineEditorComment[] = [];
  /**
   * page imprints 
  **/
  imprints: TlnImprint[] = [];
  /**
   * Rotation value, i.e. one of [ 0, 90, 180, 270 ].
   **/
  rotation: number = 0;
  next_page: NavigationPage;
  navBarOpenState: boolean;
  showArchivalManuscriptUnity: boolean = false;
  dataHandler: DataHandler = new DataHandler(this);
  geneticOrders: TlnTextGeneticOrder[] = [];
  selectedLines: string[] = [];
  selectedWords: string[] = [];
  manuscript_leaf: ManuscriptInfoLeaf;
  private readonly increment: number = 0.333;
  private readonly decrement: number = this.increment*-1;
  //protected currentRoute: string = TLN_VIEWER_ROUTE;
  protected mapping: Mapping = { findText: { param: TLN_FIND_PARAM, type: "string" },
              current_iri: { param: TLN_PAGE_PARAM, type: "string" },
              navBarOpenState: { param: TLN_NAV_BAR_OPEN_STATE_PARAM, type: "boolean" },
              current_manuscript_iri: { param: TLN_MANUSCRIPT_PARAM, type: "string" },
              fullscreen: { param: TLN_FULLSCREEN_PARAM, type: "boolean" },
              rotation: { param: TLN_ROTATION_PARAM, type: "number" },
              selectedViewOption: { param: TLN_VIEW_OPTION_PARAM, type: "string" },
              selectedWords: { param: TLN_SELECTED_WORDS_PARAM, type: "string" },
              selectedLines: { param: TLN_SELECTED_LINES_PARAM, type: "string" } }
  routerParams: Params;
  selectedViewOption: string = DEFAULT_VIEW_OPTION
  pageExists: boolean = false;
  updating: boolean = false;
  viewOptions: string[] = [ VIEW_OPTIONS.TRANSKRIPTION, VIEW_OPTIONS.FAKSIMILE, VIEW_OPTIONS.SYNOPSIS, VIEW_OPTIONS.SYNOPSIS_B ];
  subscriptions: any[] = [];

   constructor(el: ElementRef, private bottomSheet: MatBottomSheet, private pageViewService: PageViewService, private localQueryService: TlnCacheQueryService, protected router: Router, protected activatedRoute: ActivatedRoute ) { 
      super(router, activatedRoute);
   }
   ngOnInit() {
      let tlnQueryService = (this.queryService != null) ? this.queryService : this.localQueryService;
      this.dataHandler.addHandler('manuscript_unity', { 'handler': TlnManuscriptUnity, 'next_key': 'navigation_page'});
      this.dataHandler.addHandler('navigation_page', ['current_page', 'geneticOrders', 'page_editor_comments','editor_comments', 'overwritten_comments', 'line_comments', 'imprints','manuscript_leaf'] );
      this.dataHandler.addHandler('current_page', { 'handler': TlnNavigationPage });
      this.dataHandler.addHandler('manuscript_leaf', { 'handler': ManuscriptInfoLeaf});
      this.dataHandler.addHandler('geneticOrders', { 'handler': TlnTextGeneticOrder});
      this.dataHandler.addHandler('page_editor_comments', { 'handler': TlnPageEditorCommentStub});
      this.dataHandler.addHandler('editor_comments', { 'handler': TlnEditorComment});
      this.dataHandler.addHandler('overwritten_comments', { 'handler': TlnOverwrittenWordEditorComment});
      this.dataHandler.addHandler('line_comments', { 'handler': TlnLineEditorComment});
      this.dataHandler.addHandler('imprints', { 'handler': TlnImprint});
      this.dataHandler.setQueryService(tlnQueryService);
      this.subscriptions.push(this.dataHandler.start_processing.subscribe(
         (started: boolean) =>{ this.updating = true;
      }));
      this.subscriptions.push(this.dataHandler.processing_finished.subscribe(
         (finished: boolean) =>{ this.updating = false;
      }));
      super.ngOnInit();
      this.subscriptions.push(this.pageViewService.reference.subscribe(
         (newReference: Reference) => {
            this.updatePageToReference(newReference)
      })); 
      this.subscriptions.push(this.pageViewService.onClickedLine.subscribe(
         (clickedLine: TlnLine) => { 
            let index = this.selectedLines.indexOf(clickedLine.id)
            if (index > -1){
               this.selectedLines.splice(index, 1);
            } else {
               this.selectedLines.push(clickedLine.id);
            }
            this.updateParams();
      }));
      this.subscriptions.push(this.pageViewService.onClickedWord.subscribe(
         (clickedWord: TlnWord) => { 
            this.clickWord(clickedWord)
      }));
  }
  ngOnDestroy() {
      this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
  private clearFindText() {
     this.findText = '';
     this.updateParams();
  }
  private clickWord(clickedWord: TlnWord){
      let wordIndex = this.selectedWords.indexOf(clickedWord.id)
      let lineIndex = this.selectedLines.indexOf(clickedWord.line)
      if (wordIndex > -1){
         this.selectedWords.splice(wordIndex, 1);
      } else if (lineIndex > -1){
         this.selectedLines.splice(lineIndex, 1);
      } else {
         this.selectedWords.push(clickedWord.id);
      }
      this.updateParams();
  }

  private getPageTitle(page?: NavigationPage, numPages?: number): string {
     if (page == null){
        return '';
     }
     let indexPrefix = (numPages != null) ? page.index + '/' + numPages : page.index;
     return indexPrefix + ': ' + page.title + ' ' + page.number;
  }
  protected readParams(params: Params){
     super.readParams(params);
     if (this.dataHandler.ready && (this.current_page == null && this.current_iri != undefined || this.current_page != null && this.current_page.id != this.current_iri)){
        this.dataHandler.resetData('navigation_page')
        this.dataHandler.pageExists(this.current_iri, 'pageExists');
        if(this.current_manuscript_iri != null){
            this.dataHandler.getData('manuscript_unity', this.current_manuscript_iri, this.current_iri);
        } else {
            this.dataHandler.getData('current_page', this.current_iri);
        }
     } 
  }
  
  private rotate(addRotation: number){
      this.rotation = (this.rotation+addRotation < 0) ? 360 + this.rotation+addRotation : (this.rotation+addRotation) % 360;
      this.updateParams();
  }
  private setCurrentIri(pageIri: string, manuscriptIir?: string){
      this.dataHandler.stop_processing.emit(true);
      this.current_iri = pageIri;
      this.updateParams();
  }
  private showInformation() {
     let editor_comments = this.line_comments.concat(this.editor_comments)
     editor_comments = editor_comments.concat(this.overwritten_comments)
     let parentData: ParentInformation = {
        editor_comments: editor_comments.sort((a, b)=>{
           return (a.tLine.number <= b.tLine.number) ? -1 : 1;
        }),
        page_editor_comments: this.page_editor_comments,
        imprints: this.imprints,
        geneticOrders: this.geneticOrders,
        page: this.current_page,
        manuscript_iri: this.current_manuscript_iri,
        manuscript_leaf: this.manuscript_leaf,
        parentActivatedRoute: this.activatedRoute.parent
     }
     this.bottomSheet.open(TlnInformationComponent, {
        data: parentData,
        backdropClass: (parentData.editor_comments.length > 0) ? 'backdropNonFadingClass' : ''
     });
  }
  private updatePageToReference(reference: Reference){
     this.current_iri = <string>reference.page.id;
     this.selectedLines = [ <string>reference.line.id ]
     this.updateParams();
  }
}
