import { Component, Input, OnInit } from '@angular/core';
import { OntologyQueryService } from './ontology-query.service';
import { Namespace, Ontology } from './models';
import { SparqlParser } from './sparqljs';

@Component({
  selector: 'ontology-viewer',
  templateUrl: './ontology-viewer.component.html',
  styleUrls: ['./ontology-viewer.component.css']
})
export class OntologyViewerComponent implements OnInit {
  @Input() baseUrl: string;
  @Input() ontology: Ontology;
  @Input() namespaces: Namespace[];
  query: string = `
  PREFIX rdfs: <http://www.w3.org/2000/01/rdf-schema#>
  PREFIX rdf: <http://www.w3.org/1999/02/22-rdf-syntax-ns#>
  PREFIX owl: <http://www.w3.org/2002/07/owl#>
  PREFIX xsd: <http://www.w3.org/2001/XMLSchema#>

  SELECT ?property ?label ?comment ?domain ?range ?superProp WHERE {
      ?property  a ?propertyType;
                 rdfs:isDefinedBy ?ontology.
         OPTIONAL { ?property rdfs:label ?label }
         OPTIONAL { ?property rdfs:domain ?domain.
         ?domain  rdfs:isDefinedBy ?ontology}
         OPTIONAL { ?property rdfs:range ?range }
         OPTIONAL { ?property rdfs:comment ?comment }
         OPTIONAL { ?property rdfs:subPropertyOf ?superProp.
                    ?superProp rdfs:isDefinedBy ?ontology.
                    FILTER(?property != ?superProp)
         }
         FILTER(!isBlank(?range))
   }`
  objectPropertyQuery: string;
  datatypePropertyQuery: string;

  constructor(private queryService: OntologyQueryService) { }

  ngOnInit() {
     this.objectPropertyQuery = SparqlParser.getQuery(this.query, 'propertyType', 'http://www.w3.org/2002/07/owl#ObjectProperty');
     this.datatypePropertyQuery = SparqlParser.getQuery(this.query, 'propertyType', 'http://www.w3.org/2002/07/owl#DatatypeProperty');
     this.queryService.baseUrl = this.baseUrl;
  }

}
