import { Component, OnInit } from '@angular/core';
import { CONTENT_VIEW_ROUTE } from './constants';

@Component({
  selector: 'app-query',
  templateUrl: './query.component.html',
  styleUrls: ['./query.component.scss']
})
export class QueryComponent implements OnInit {
   routePrefix: string = CONTENT_VIEW_ROUTE;

  constructor() { }

  ngOnInit() {
  }

}
