import { Component, Input, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { TLN_VIEWER_ROUTE, TLN_FULLSCREEN_PARAM, TLN_PAGE_PARAM, TLN_MANUSCRIPT_PARAM, TLN_SELECTED_WORDS_PARAM, TLN_SELECTED_LINES_PARAM } from '../constants';
import { Mapping } from '../route-reader';
import { RouteUpdater } from '../route-updater';
import { NavigationPage } from '../models';

export interface ViewerRouteInformation {
   title: string;
   number: string;
   page: string;
   manuscript?: string;
   selectedWords?: string[];
   selectedLines?: string[];
   launchExternally?: boolean;
}
@Component({
  selector: 'open-in-viewer',
  templateUrl: './open-in-viewer.component.html',
  styleUrls: ['./open-in-viewer.component.scss']
})
export class OpenInViewerComponent extends RouteUpdater {
   @Input() routerInformation: ViewerRouteInformation;
   @Input() title: string;
   @Input() launchTitle: string;
   @Input() routePrefix: string;
   launch: boolean = false;
   page: NavigationPage;
   protected currentRoute: string = TLN_VIEWER_ROUTE;
   current_iri: string;
   current_manuscript_unity: string;
   selectedWords: string[];
   selectedLines: string[];
   protected mapping: Mapping = { current_iri: { param: TLN_PAGE_PARAM, type: "string" },
              selectedWords: { param: TLN_SELECTED_WORDS_PARAM, type: "string" },
              selectedLines: { param: TLN_SELECTED_LINES_PARAM, type: "string" },
              current_manuscript_unity: { param: TLN_MANUSCRIPT_PARAM, type: "string" }}

   constructor(protected router: Router, protected activatedRoute: ActivatedRoute ) { 
      super(router, activatedRoute);
   }
   ngOnInit(){
      super.ngOnInit();
      if (this.routePrefix != undefined && this.routePrefix != null){
         this.currentRoute = this.routePrefix + '/' + this.currentRoute;
      }
      this.page = { id: this.routerInformation.page, 
         number: this.routerInformation.number,
         title: this.routerInformation.title };
      this.launch = (this.routerInformation.launchExternally != undefined 
                     && this.routerInformation.launchExternally != null
                     && this.routerInformation.launchExternally)
      if (this.routerInformation.selectedWords != undefined 
         && this.routerInformation.selectedWords != null
         && this.routerInformation.selectedWords.length > 0){
         this.selectedWords = this.routerInformation.selectedWords;
      }
      if (this.routerInformation.selectedLines != undefined 
         && this.routerInformation.selectedLines != null
         && this.routerInformation.selectedLines.length > 0){
         this.selectedLines = this.routerInformation.selectedLines;
      }
      if (this.title == undefined || this.title == null || this.title == ''){
         this.title = this.page.title + ' ' + this.page.number + '  in Seitenansicht öffnen'
      }
      if (this.launchTitle == undefined || this.launchTitle == null || this.launchTitle == ''){
         this.launchTitle = this.page.title + ' ' + this.page.number + '  in neuem Tab in Seitenansicht öffnen'
      }
   }
   private openInViewer(){
      if (this.routerInformation != undefined && this.routerInformation != null){
         this.current_iri = this.routerInformation.page;
         if (this.routerInformation.manuscript != undefined && this.routerInformation != null){
            this.current_manuscript_unity = this.routerInformation.manuscript;
         }
         this.updateParams(this.launch);
      }
   }

}
