import { Routes } from '@angular/router';
import {ManuscriptViewComponentComponent} from "../manuscript-view-component/manuscript-view-component.component";
import {RhizomeViewComponentComponent} from "../rhizome-view-component/rhizome-view-component.component";
import {PageViewWrapperComponent} from "../page-view-wrapper-component/page-view-wrapper.component";
import { TLN_QUANT_ROUTE, TLN_CROSSREF_ROUTE, TLN_MANUSCRIPT_ROUTE, TLN_SEARCH_ROUTE, TLN_VIEWER_ROUTE, TLN_CROSSREF_EDITOR_ROUTE } from '../tln-edition/constants';
import { TlnFulltextComponent } from '../tln-edition/tln-fulltext/tln-fulltext.component';
import { QuantComponent } from '../quant/quant.component';

export const CONTENT_ROUTES: Routes = [
  { path: TLN_MANUSCRIPT_ROUTE, component: ManuscriptViewComponentComponent },
  { path: TLN_VIEWER_ROUTE, component: PageViewWrapperComponent },
  { path: TLN_CROSSREF_ROUTE, component: RhizomeViewComponentComponent },
  { path: TLN_SEARCH_ROUTE, component: TlnFulltextComponent},
  { path: TLN_QUANT_ROUTE, component: QuantComponent},
  { path: '', redirectTo: TLN_MANUSCRIPT_ROUTE, pathMatch: 'prefix' }
];
