export class Matrix {
   private readonly A: number = 0
   private readonly B: number = 1
   private readonly C: number = 2
   private readonly D: number = 3
   private readonly E: number = 4
   private readonly F: number = 5
   matrix: number[] = [ 1, 0, 0, 1, 0, 0 ]
   private readonly degree_matrix_mapping = {
      0: [ 1, 0, 0, 1, 0, 0 ],
      90: [ 0, 1, -1, 0, 0, 0 ],
      180: [ -1, 0, 0, -1, 0, 0 ],
      270: [ 0, -1, 1, 0, 0, 0 ]
   }

   constructor(matrix_string?: string, zoomFactor?: number, height?: number, width?: number, rotation?: number){
      if (matrix_string != null){
         this.parseMatrixString(matrix_string);
      }
      if (rotation != undefined && rotation != null){
         this.updateRotation(rotation);
      }
      if (zoomFactor != null && height != null && width != null){
         this.updateOffset(zoomFactor, height, width);
      }
   }
   public get90DegreeRotation(): number {
      let filtered = Object.entries(this.degree_matrix_mapping).filter(key_value =>JSON.stringify(key_value[1].slice(0, -2)) == JSON.stringify(this.matrix.slice(0, -2)))
      return (filtered.length > 0) ? Number(filtered[0][0]) : -1;
   }
   private parseMatrixString(matrix_string: string){
      if (matrix_string.startsWith('matrix')){
         this.matrix = matrix_string.replace('matrix(','').replace(')','').split(' ').map(n =>Number(n))
      } else if (matrix_string.startsWith('rotation')){
         let angle = Number(matrix_string.replace('rotation(','').replace(')',''))
         let radians = Math.PI/180*angle
         this.matrix[this.A] = Math.round(Math.cos(radians))
         this.matrix[this.B] = Math.sin(radians)
         this.matrix[this.C] = Math.sin(radians)*-1
         this.matrix[this.D] = Math.round(Math.cos(radians))
      }
   }
   private updateOffset(zoomFactor: number, height: number, width: number){
      if (this.matrix[this.B] == 0 && this.matrix[this.C] == 0){
         this.matrix[this.E] = 0 
         this.matrix[this.F] = 0 
      } else {
         this.matrix[this.E] = zoomFactor*(height-width)/2
         this.matrix[this.F] = -1*zoomFactor*(height-width)/2 
      }
   }
   private updateRotation(addRotation: number){
      let rotation = (this.get90DegreeRotation() + addRotation) % 360;   
      this.matrix = (this.degree_matrix_mapping[rotation] != undefined) ? this.degree_matrix_mapping[rotation] : this.degree_matrix_mapping[0];
   }
   /**
    * Return transform matrix as a string in the format 'matrix(1.0 0.0 0.0 1.0 0.0 0.0)'
    * */
   public toString(): string {
      return 'matrix(' + this.matrix.map(n =>String(n)).join(' ') + ')'
   }
}
