import { Component, OnInit } from '@angular/core';
import {Subscription} from 'rxjs';
import {ActivatedRoute, Params} from '@angular/router';
import { CONTENT_VIEW_ROUTE, PROJECT_ROUTE} from './constants';

@Component({
  selector: 'app-conception',
  templateUrl: './conception.component.html',
  styleUrls: ['./conception.component.scss']
})
export class ConceptionComponent implements OnInit {
  projectLink: string =  '/' + PROJECT_ROUTE;
  contentViewLink: string = '/' + CONTENT_VIEW_ROUTE;
  queryParamSub: Subscription;
  queryParams: Params;

  constructor(private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    this.queryParamSub = this.activatedRoute.queryParams.subscribe( (queryParams: Params ) => {
      this.queryParams = queryParams;

    });
  }


}
