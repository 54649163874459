import { OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';

export interface ParamContent {
   param: string;
   type: string;
   debug?: string;
   ignore?: boolean;
}
export interface Mapping {
  [name: string]: ParamContent; 
}

export class RouteReader implements OnInit {
  protected mapping: Mapping; 
  protected routerParams: Params;

  constructor(protected router: Router, protected activatedRoute: ActivatedRoute ) { }

  ngOnInit() {
      this.activatedRoute.queryParams.subscribe(params => {
         this.readParams(params)
      });
  }
  protected readParams(params: Params) {
      this.routerParams = params;
      for(let key of Object.keys(this.mapping)){
         if (this.mapping[key]['ignore'] == undefined || !this.mapping[key]['ignore']){
             let paramsKey = this.mapping[key]['param'];
             if (this.routerParams[paramsKey] != null){
                if (this.mapping[key]['type'] == 'number'){
                  this[key] = Number(this.routerParams[paramsKey])
                } else if (this.mapping[key]['type'] == 'boolean'){
                  this[key] = (this.routerParams[paramsKey] == 'true')
                } else if (this.mapping[key]['type'] == 'URI' || this.mapping[key]['type'] == 'URL'){
                  this[key] = decodeURI(this.routerParams[paramsKey])
                } else if (this.mapping[key]['type'] == 'complex'){
                  this[key] = JSON.parse(this.routerParams[paramsKey])
                } else if (Array.isArray(this[key]) && !Array.isArray(this.routerParams[paramsKey])) {
                   this[key] = JSON.parse(this.routerParams[paramsKey])
                } else {
                   this[key] = this.routerParams[paramsKey];
                } 
                if (this.mapping[key]['debug'] != null){
                    console.log(this.mapping[key]['debug'], this[key]);
                }
             }
         }
      }
  }
}
