import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute, Params} from '@angular/router';
import { DataHandler } from '../data_handler';
import { TLN_VIEWER_ROUTE, TLN_PAGE_PARAM, TLN_CONTEXT_VIEW_PARAM, TLN_VIEW_OPTION_PARAM, VIEW_OPTIONS } from '../constants';
import { ReconstructedKonvolut, TlnExtManuscript, ManuscriptEarlierDescription, ManuscriptDescription, ManuscriptLeaf } from '../datatypes/manuscript';
import { TlnPageWithFaksimile  } from '../datatypes/page';
import { TlnCacheQueryService } from '../services';
import { Mapping } from '../route-reader';
import { RouteUpdater } from '../route-updater';

@Component({
  selector: 'tln-leaf-description',
  templateUrl: './tln-leaf-description.component.html',
  styleUrls: ['./tln-leaf-description.component.css']
})
export class TlnLeafDescriptionComponent  extends RouteUpdater implements OnInit {
   @Input() current_manuscript: TlnExtManuscript;
   @Input() manuscriptLeaf: ManuscriptLeaf;
   dataHandler: DataHandler = new DataHandler(this);
   pages: TlnPageWithFaksimile[] = [];
   current_page_iri: string;
   contextView: string;
   viewMode: string;
   protected currentRoute: string = TLN_VIEWER_ROUTE;
   protected mapping: Mapping = { 
              contextView: { param: TLN_CONTEXT_VIEW_PARAM, type: "string" },
              current_page_iri: { param: TLN_PAGE_PARAM, type: "string" },
              viewMode: { param: TLN_VIEW_OPTION_PARAM, type: "string" },
   }

  constructor(private tlnQueryService: TlnCacheQueryService, protected activatedRoute: ActivatedRoute, protected router: Router ) { 
      super(router, activatedRoute);
  }

  ngOnInit() {
      this.dataHandler.addHandler('pages', { 'handler': TlnPageWithFaksimile });
      this.dataHandler.setQueryService(this.tlnQueryService);
      if (this.manuscriptLeaf != undefined && this.manuscriptLeaf != null){
         this.dataHandler.getData('pages', this.manuscriptLeaf.id);
      }
  }
  private navigate(pageIri: string){
      this.current_page_iri = pageIri;
      this.contextView = TLN_PAGE_PARAM;
      this.updateParams();
  }
}
