import { Component, EventEmitter, OnInit, OnDestroy, Output, Input } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { DataProcessor, TlnQueryServiceInterface, TextVersion, NavigationPage} from '../models';
import { TLN_CROSSREF_ROUTE, TLN_VIEWER_ROUTE, TLN_CONTEXT_VIEW_PARAM, TLN_FULLSCREEN_PARAM, TLN_FIND_PARAM, TLN_PAGE_PARAM, TLN_MANUSCRIPT_PARAM,
   TLN_SELECTED_LINES_PARAM, TLN_TEXT_GENETIC_ORDER_PARAM, TLN_VIEW_OPTION_PARAM, TLN_ZOOM_PARAM, VIEW_OPTIONS, ONTOLOTY_PREFIX } from '../constants';
import { IsReconstructedKonvolut } from '../datatypes/basic_datatype';
import { TlnNavigationPage} from '../datatypes/navigation';
import { TlnLine} from '../datatypes/line';
import { TlnWord} from '../datatypes/word';
import { ManuscriptStub } from '../datatypes/manuscript';
import { TlnPageWithTextGeneticOrder, TlnTextGeneticOrder, TlnStandaloneTextVersion} from '../datatypes/text_version';
import { Mapping } from '../route-reader';
import { RouteUpdater } from '../route-updater';
import { ComplexKeyIriMapping, DataHandler, KeyIriMapping } from '../data_handler';
import { PageViewService, TlnCacheQueryService } from '../services';
import { TlnInformationComponent, ParentInformation } from '../tln-information/tln-information.component';
import { PageInformation } from '../tln-information/page-information';

@Component({
  selector: 'tln-crossref',
  templateUrl: './tln-crossref.component.html',
  providers: [ TlnCacheQueryService],
  styleUrls: ['./tln-crossref.component.css']
})
export class TlnCrossrefComponent extends RouteUpdater implements DataProcessor, OnDestroy {
   /**
    * OPTIONAL pass a queryService with method 
    * {@link /interfaces/TlnQueryServiceInterface.html#getData|getData}
    * to TlnPageViewComponent.
    **/
  @Input() queryService: TlnQueryServiceInterface;
  @Output() textGenesisSelected = new EventEmitter<string>();
  @Output() textVersionSelected = new EventEmitter<string>();
  @Output() textVersionUnselected = new EventEmitter<string>();
  tlnQueryService: TlnQueryServiceInterface;
  private readonly PAGE_CONTEXT_VIEW: string = TLN_PAGE_PARAM;
  private readonly MANUSCRIPT_CONTEXT_VIEW: string = TLN_MANUSCRIPT_PARAM;
  zoomFactor: number = 1;
  findText: string;
  dataHandler: DataHandler = new DataHandler(this);
  textVersions: TextVersion[] = [];
  geneticOrders: TlnTextGeneticOrder[] = [];
  contextView: string = this.PAGE_CONTEXT_VIEW;
  current_genetic_order_iri: string;
  current_manuscript_unity: string;
  current_manuscript: ManuscriptStub;
  current_iri: string;
  current_page: NavigationPage;
  fullscreen: boolean = false;
  pagesWithGeneticOrder: TlnPageWithTextGeneticOrder[] = [];
  max_width: number = -1;
  max_height: number = -1;
  next_page_iri: string;
  each_version_height: number = -1;
  selectedLines: string[] = [];
  selectedTextVersions: TextVersion[] = []
  private readonly increment: number = 0.333;
  private readonly decrement: number = this.increment*-1;
  private readonly margin_width: number = 280;
  private readonly initialPreviewWidth : number = 400;
  previewWidth: number = this.initialPreviewWidth;
  //protected currentRoute: string = TLN_CROSSREF_ROUTE;
  protected mapping: Mapping = { findText: { param: TLN_FIND_PARAM, type: "string" },
              fullscreen: { param: TLN_FULLSCREEN_PARAM, type: "boolean" },
              contextView: { param: TLN_CONTEXT_VIEW_PARAM, type: "string" },
              current_iri: { param: TLN_PAGE_PARAM, type: "string" },
              current_manuscript_unity: { param: TLN_MANUSCRIPT_PARAM, type: "string" },
              current_genetic_order_iri: { param: TLN_TEXT_GENETIC_ORDER_PARAM, type: "string" },
              selectedLines: { param: TLN_SELECTED_LINES_PARAM, type: "string" },
              zoomFactor: { param: TLN_ZOOM_PARAM, type: "number" } }
  routerParams: Params;
  selectedViewOption: string = VIEW_OPTIONS.TRANSKRIPTION
  updating: boolean = false;
  viewOptions: string[] = [ VIEW_OPTIONS.TRANSKRIPTION, VIEW_OPTIONS.FAKSIMILE ];
  subscriptions: any[] = [];

   constructor(private pageViewService: PageViewService, private localQueryService: TlnCacheQueryService, protected router: Router, protected activatedRoute: ActivatedRoute ) { 
      super(router, activatedRoute);
   }
   ngOnInit() {
      if (screen.availWidth - this.initialPreviewWidth - this.margin_width > 1000){
         this.previewWidth = screen.availWidth - this.initialPreviewWidth - 1000;
      }
      this.max_width = screen.availWidth - this.previewWidth - this.margin_width;
      this.max_height = screen.availHeight - 200;
      this.tlnQueryService = (this.queryService != null) ? this.queryService : this.localQueryService;
      this.dataHandler.addHandler('page_content', ['current_page', 'geneticOrders'] );
      this.dataHandler.addHandler('manuscript_content', ['current_manuscript', 'pagesWithGeneticOrder'] );
      this.dataHandler.addHandler('current_manuscript', { 'handler': ManuscriptStub});
      this.dataHandler.addHandler('current_page', { 'handler': TlnNavigationPage });
      this.dataHandler.addHandler('geneticOrders', { 'handler': TlnTextGeneticOrder});
      this.dataHandler.addHandler('pagesWithGeneticOrder', { 'handler': TlnPageWithTextGeneticOrder });
      this.dataHandler.addHandler('textVersions', { 'handler': TlnStandaloneTextVersion, 'process_data':  this });
      this.dataHandler.setQueryService(this.tlnQueryService);
      this.subscriptions.push(this.dataHandler.start_processing.subscribe(
         (started: boolean) =>{ this.updating = true;
      }));
      this.subscriptions.push(this.dataHandler.processing_finished.subscribe(
         (finished: boolean) =>{ this.updating = false;
      }));
      super.ngOnInit();
      this.subscriptions.push(this.pageViewService.onClickedWord.subscribe(
         (clickedWord: TlnWord) => { 
            this.addWordText2FindText(clickedWord)
      }));
   }
   ngOnDestroy() {
      this.subscriptions.forEach(subscription => subscription.unsubscribe());
   }
   private addWordText2FindText(word: TlnWord){
      let text = (word.edited_text != null && word.edited_text != '') ? word.edited_text : word.text;
      if (this.findText == null || !this.findText.includes(text)){
         this.findText = (this.findText == null || this.findText == '') ? text : this.findText + ' ' + text;
      } else {
         let index = this.findText.indexOf(text)
         let startText = this.findText.substring(0, index)
         let endText = (this.findText.length > index + text.length) ? this.findText.substring(index + text.length) : '';
         let findText = startText + endText;
         this.findText = findText.replace('  ', ' ').trim();
         
      }
      this.updateParams();
   }
   public processData(): void {
      this.selectedTextVersions = [];
      let index = 0;
      while (index < this.textVersions.length && this.selectedTextVersions.length < 1){
         if(this.textVersions[index].textUnities != undefined && this.textVersions[index].textUnities.length > 0){
            this.selectedTextVersions.push(this.textVersions[index]);
         }
         index++
      }
   }
   private addOrRemove(textVersion: TextVersion) {
      let index = this.selectedTextVersions.indexOf(textVersion);
      if (index == -1){
         this.textVersionSelected.emit(textVersion.id);
         this.selectedTextVersions.push(textVersion);
      } else {
         this.textVersionUnselected.emit(textVersion.id);
         this.selectedTextVersions.splice(index, 1);
      }
      this.each_version_height = (this.selectedTextVersions.length > 0) ? this.max_height/this.selectedTextVersions.length : this.max_height;
   }
   private clearFindText() {
     this.findText = '';
     this.updateParams();
   }
   private getButtonTitle(textVersion: TextVersion): string {
      return (this.selectedTextVersions.indexOf(textVersion) == -1) ? 'Textstelle anzeigen' : 'Textstelle ausblenden';
   }
   private isNewPagePartOfOldTextVersions(): boolean {
      if (this.current_manuscript_unity == undefined || this.current_manuscript_unity == null){
         return false
      }
      for (let textVersion of this.textVersions){
         if (textVersion.manuscript != undefined 
            && textVersion.manuscript != null 
            && textVersion.manuscript == this.current_manuscript_unity){
            for (let textUnity of textVersion.textUnities){
               if (textUnity.id == this.current_iri || textUnity.belongsToPage == this.current_iri){
                  console.log(textUnity.id, textUnity.belongsToPage, this.current_iri);
                  return true
               }
            }
         }
      }
      return false
   }
   protected readParams(params: Params){
     let old_genetic_order_iri = this.current_genetic_order_iri;
     let old_current_iri = this.current_iri;
     let old_manuscript_unity = this.current_manuscript_unity;
     let old_context_view = this.contextView;
     super.readParams(params);
     if (this.dataHandler.ready){
        if (old_current_iri != null 
           && this.current_iri != old_current_iri && !this.isNewPagePartOfOldTextVersions()){
           this.selectedTextVersions = [];
           this.dataHandler.resetData('textVersions')
           if (old_genetic_order_iri == this.current_genetic_order_iri){
               this.current_genetic_order_iri = ''
               this.updateParams()
           }
        }
        if (this.contextView == this.PAGE_CONTEXT_VIEW 
              && (this.contextView != old_context_view 
                 || (this.current_iri != null && this.current_iri != old_current_iri))){
            this.dataHandler.resetData('page_content')
            this.dataHandler.getData('page_content', this.current_iri);
        }
        if (this.current_manuscript_unity != old_manuscript_unity){
            this.dataHandler.resetData('manuscript_content')
            this.dataHandler.getData('manuscript_content', this.current_manuscript_unity);
        }
        if (this.contextView == this.PAGE_CONTEXT_VIEW 
           && (this.contextView != old_context_view || 
           (this.textVersions.length == 0 || this.current_genetic_order_iri != old_genetic_order_iri))){
            this.dataHandler.resetData('textVersions')
            if (this.current_genetic_order_iri != null && this.current_genetic_order_iri != ''){
               this.dataHandler.getData('textVersions', this.current_genetic_order_iri);
            }
        }
     }
   }
   private openInViewer(pageIri: string, belongsToPageIri?: string, manuscriptIri?: string, launch?: boolean){
      this.current_iri = (belongsToPageIri != null) ? belongsToPageIri : pageIri;
      if(manuscriptIri != null){
         this.current_manuscript_unity = manuscriptIri;
      }
      this.currentRoute = TLN_VIEWER_ROUTE;
      this.updateParams(launch);
   }
   private setZoomFactor(newZoomFactor: number){
     if (newZoomFactor > 0){
         this.zoomFactor = Math.round(newZoomFactor*100)/100;
     } else {
         this.zoomFactor = this.zoomFactor/2
     }
     this.updateParams();
   }
}
