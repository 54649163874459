import { Component, Input, OnInit } from '@angular/core';
import { DataMapping, QueryDataMapping, Ontology } from './models';
import { SparqlParser } from './sparqljs';


@Component({
  selector: 'ontology-table',
  templateUrl: './ontology-table.component.html',
  styleUrls: ['./ontology-table.component.css']
})
export class OntologyTableComponent implements OnInit {
   @Input() dataMapping: DataMapping;

  constructor() { }

  ngOnInit() {
  }

}
