import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: 'img'
})

export class LazyImageLoadDirectiveDirective {

  constructor({ nativeElement }: ElementRef<HTMLImageElement>) {
    const supports = 'loading' in HTMLImageElement.prototype;
    if (supports) {
      nativeElement.setAttribute('loading', 'lazy');
    }
  }
}

// IF NOT SUPPORTED IN CHROME: Open chrome://flags in Chromium or Chrome Canary.
//   Search for lazy.
//   Enable both the “Enable lazy image loading” and the “Enable lazy frame loading” flag.
//   Restart the browser with the button in the lower right corner of the screen.
