import { FusekiResults, BasicResultBindingElement } from './basic_datatype';
import { TextGeneticOrder, TextUnity, TextVersion } from '../models';
import { PageStub } from './page';
export class TlnTextVersion extends BasicResultBindingElement implements TextVersion {
   id: string;
   title: string; 
   extUrl?: string;
   manuscript?: string;
   textUnities: TlnTextUnity[] = [];

   constructor (data: any, id?: string, service?: any) {
      super(data, id, service);
      this.title = this.getData4Key('title');
      this.extUrl = this.getData4Key('extUrl');
      this.manuscript = this.getData4Key('manuscript');
   }
}
export class TlnTextUnity extends BasicResultBindingElement implements TextUnity {
   number: string;
   startLine?: number;
   endLine?: number;
   belongsToPage?: string;
   endLineIri?: string;
   startLineIri? : string;
   hasFaksimileImage?: boolean;
   constructor (data: any, id?: string, service?: any) {
      super(data, id, service);
      this.number = this.getData4Key('number');
      this.startLine = this.getData4Key('startLine');
      this.endLine = this.getData4Key('endLine');
      this.belongsToPage = this.getData4Key('text_page');
      this.startLineIri = this.getData4Key('startLineIri');
      this.endLineIri = this.getData4Key('endLineIri');
      this.hasFaksimileImage = this.getData4Key('hasFaksimileImage');
   }
}
/**
 * This is the text version instantiation of an element of {@link /interfaces/FusekiResults.html|FusekiResults}.
 * It extends {@link /classes/BasicResultBindingElement.html|BasicResultBindingElement}.
 **/
export class TlnTextGeneticOrder extends BasicResultBindingElement implements TextGeneticOrder {
   /** 
    * the internal default key for replacing {@link /classes/TlnLine.html#query|query} by "id"
    * in {@link /classes/TlnLine.html#getQuery|getQuery} if "key" is omitted.
    **/
   static readonly default_key: string = 'page';
   /** 
    * the SPARQL-query of this datatype.
    **/
   static readonly query: string = `
   PREFIX tln: <http://www.nie.org/ontology/nietzsche#>
   PREFIX rdf: <http://www.w3.org/1999/02/22-rdf-syntax-ns#>
   
   SELECT distinct ?id ?text_version ?text_unity ?text_page ?number ?startLine ?endLine ?title WHERE {
      {?version a tln:IdentifiedTextVersion;
  		   tln:identifiesAsVersion/rdf:rest*/rdf:first ?page.
      } UNION {
      ?version a tln:IdentifiedTextVersion;
  		   tln:identifiesAsVersion/rdf:rest*/rdf:first/tln:belongsToPage ?page.
      }
      ?id tln:hasGeneticOrder/rdf:rest*/rdf:first ?version;
          tln:hasGeneticOrder/rdf:rest*/rdf:first ?text_version.
   
      OPTIONAL { ?text_version tln:identifiesAsVersion/rdf:rest*/rdf:first ?text_unity.
         OPTIONAL { ?text_unity tln:hasNumber ?number.
                    ?manuscript a tln:ArchivalManuscriptUnity;
                        tln:hasTitle ?title;
                        tln:hasPages/rdf:rest*/rdf:first ?text_unity.
         }
         OPTIONAL { ?text_unity tln:belongsToPage ?text_page; 
                           tln:startLine/tln:lineHasNumber?startLine;
                           tln:endLine/tln:lineHasNumber ?endLine.
      			   ?text_page tln:hasNumber ?number.
      			   ?manuscript a tln:ArchivalManuscriptUnity;
                     tln:hasTitle ?title;
                     tln:hasPages/rdf:rest*/rdf:first ?text_page.
         }
      }
      OPTIONAL { ?text_version tln:textUnitHasTitle ?title.}
   }`;
   /** 
    * the public key for replacing {@link /classes/TlnLine.html#query|query} by "id".
    **/
   static readonly query_key: string = 'page';
   textVersions: TlnTextVersion[] = []; 

   /**
    * The constructor creates a datatype from the data.
    *
    * @param id if omitted the id will be retrieved from data
    **/
   constructor (data: any, id?: string, service?: any) {
      super(data, id, service);
   }
   public static convertData<T extends typeof BasicResultBindingElement>(this: T, data: FusekiResults, id?: string, service?: any): Array<InstanceType<T>> {
      let elements = []; 
      let content = this.getContent(data);
      for (var i = 0; i < content.length; i++){
         let element = new TlnTextGeneticOrder(content[i], service);
         let versions = TlnTextVersion.convertData({ head: { vars: []}, results: { bindings: <any>[ content[i] ] } }, content[i]['text_version']['value'])
         let text_unities = (content[i]['text_unity'] != undefined) ? 
            TlnTextUnity.convertData({ head: { vars: []}, results: { bindings: <any>[ content[i] ] } }, content[i]['text_unity']['value']) : [];
         /*if (text_unities.length > 0){
            console.log(text_unities[0]);
         }*/
         if (elements.length > 0 && elements[elements.length-1].id == element.id){
            if (elements[elements.length-1].textVersions.length > 0 
               && elements[elements.length-1].textVersions[elements[elements.length-1].textVersions.length-1].id == versions[0].id){
               if (text_unities.length > 0){
                  elements[elements.length-1].textVersions[elements[elements.length-1].textVersions.length-1].textUnities.push(text_unities[0]);
               }
            } else {
               if (text_unities.length > 0){
                  versions[0].textUnities.push(text_unities[0])
               }
               elements[elements.length-1].textVersions.push(versions[0]);
            }
         } else {
            if (text_unities.length > 0){
               versions[0].textUnities.push(text_unities[0])
            }
            element.textVersions.push(versions[0])
            elements.push(element)
         }
      }
      //console.log(elements)
      return elements;
   }

}
export class TlnStandaloneTextVersion extends TlnTextVersion {
   /** 
    * the internal default key for replacing {@link /classes/TlnLine.html#query|query} by "id"
    * in {@link /classes/TlnLine.html#getQuery|getQuery} if "key" is omitted.
    **/
   static readonly default_key: string = 'geneticOrder';
   /** 
    * the SPARQL-query of this datatype.
    **/
   static readonly query: string = `
   PREFIX tln: <http://www.nie.org/ontology/nietzsche#>
   PREFIX rdf: <http://www.w3.org/1999/02/22-rdf-syntax-ns#>
   
   SELECT distinct ?id ?text_unity ?text_page ?manuscript ?number ?startLine ?startLineIri ?endLine ?endLineIri ?title ?extUrl ?hasFaksimileImage WHERE {
      ?geneticOrder a tln:TextGenesis;
          tln:hasGeneticOrder/rdf:rest*/rdf:first ?id.
   
      OPTIONAL { ?id tln:identifiesAsVersion/rdf:rest*/rdf:first ?text_unity.
         OPTIONAL { ?text_unity tln:hasNumber ?number.
                    ?manuscript a tln:ArchivalManuscriptUnity;
                        tln:hasTitle ?title;
                        tln:hasPages/rdf:rest*/rdf:first ?text_unity.
            BIND(exists{ ?text_unity tln:hasFaksimileImage ?image } as ?hasFaksimileImage)
         }
         OPTIONAL { ?text_unity tln:belongsToPage ?text_page; 
                           tln:startLine ?startLineIri;
                           tln:endLine ?endLineIri.
      			   ?text_page tln:hasNumber ?number.
                  ?startLineIri tln:lineHasNumber?startLine.
                  ?endLineIri tln:lineHasNumber ?endLine.
      			   ?manuscript a tln:ArchivalManuscriptUnity;
                     tln:hasTitle ?title;
                     tln:hasPages/rdf:rest*/rdf:first ?text_page.
            BIND(exists{ ?text_page tln:hasFaksimileImage ?image } as ?hasFaksimileImage)
         }
      }
      OPTIONAL { ?id tln:textUnitHasTitle ?title; tln:textUnitHasUrl ?extUrl.}
   }`;
   /** 
    * the public key for replacing {@link /classes/TlnLine.html#query|query} by "id".
    **/
   static readonly query_key: string = 'geneticOrder';

   constructor (data: any, id?: string, service?: any) {
      super(data, id, service);
      if(this.service != null){
         this.service.initSelecteTextVersion(this);
      }
   }

   public static convertData<T extends typeof BasicResultBindingElement>(this: T, data: FusekiResults, id?: string, service?: any): Array<InstanceType<T>> {
      let elements = []; 
      let content = this.getContent(data);
      for (var i = 0; i < content.length; i++){
         let element = new TlnStandaloneTextVersion(content[i], id, service);
         let text_unities = (content[i]['text_unity'] != undefined) ? 
            TlnTextUnity.convertData({ head: { vars: []}, results: { bindings: <any>[ content[i] ] } }, content[i]['text_unity']['value']) : [];
         if (elements.length > 0 && elements[elements.length-1].id == element.id){
            if (text_unities.length > 0){
               elements[elements.length-1].textUnities.push(text_unities[0]);
            }
         } else {
            if (text_unities.length > 0){
               element.textUnities.push(text_unities[0]);
            }
            elements.push(element)
         }
      }
      //console.log(elements)
      return elements;
   }

}
export class TlnPageWithTextGeneticOrder extends PageStub {
   /** 
    * the internal default key for replacing {@link /classes/TlnLine.html#query|query} by "id"
    * in {@link /classes/TlnLine.html#getQuery|getQuery} if "key" is omitted.
    **/
   static readonly default_key: string = 'manuscript';
   /** 
    * the SPARQL-query of this datatype.
    **/
   static readonly query: string = `
   PREFIX tln: <http://www.nie.org/ontology/nietzsche#>
   PREFIX rdf: <http://www.w3.org/1999/02/22-rdf-syntax-ns#>
   
   SELECT distinct ?id ?number WHERE {
      ?manuscript tln:hasPages/rdf:rest*/rdf:first ?id.
      {?version a tln:IdentifiedTextVersion;
  		   tln:identifiesAsVersion/rdf:rest*/rdf:first ?id.
      } UNION {
      ?version a tln:IdentifiedTextVersion;
  		   tln:identifiesAsVersion/rdf:rest*/rdf:first/tln:belongsToPage ?id.
      }
      ?id tln:hasNumber ?number.
     }`;
   /** 
    * the public key for replacing {@link /classes/TlnLine.html#query|query} by "id".
    **/
   static readonly query_key: string = 'manuscript';

}

