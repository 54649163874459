import { Component, Inject, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef} from '@angular/material/bottom-sheet';
import { EditorComment, ManuscriptUnity, NavigationPage} from '../models';
import { Mapping, RouteReader } from '../route-reader';
import { RouteUpdater } from '../route-updater';
import { TlnTextGeneticOrder} from '../datatypes/text_version';
import { TlnEditorComment } from '../datatypes/editor_comment';
import { TlnImprint } from '../datatypes/imprint';
import { ManuscriptLeaf } from '../datatypes/manuscript';
import { TLN_PAGE_PARAM, TLN_MANUSCRIPT_PARAM, TLN_TEXT_GENETIC_ORDER_PARAM, TLN_CROSSREF_ROUTE, TLN_VIEWER_ROUTE } from '../constants';
import { TlnViewerNavigation } from '../tln-viewer-navigation/tln-viewer-navigation.component';

export interface ParentInformation {
   editor_comments: EditorComment[];
   page_editor_comments: EditorComment[];
   imprints: TlnImprint[];
   geneticOrders: TlnTextGeneticOrder[];
   page: NavigationPage;
   manuscript_iri: string;
   manuscript_leaf?: ManuscriptLeaf;
   parentActivatedRoute?: ActivatedRoute;
}

@Component({
  selector: 'app-tln-information',
  templateUrl: './tln-information.component.html',
  styleUrls: ['./tln-information.component.css']
})
export class TlnInformationComponent extends RouteUpdater implements OnInit {
   /**
    * IRI of the current page TODO: for later use
    **/
   current_iri: string;
   /**
    * IRI of the current manuscript TODO: for later use
    **/
   current_manuscript_iri: string;
   /**
    * IRI of the current geneticOrder TODO: for later use
    **/
   current_genetic_order: string;
   /**
    * the route to the crossref app
    **/
   private crossrefRoute: string = TLN_CROSSREF_ROUTE;
   /**
    * the route to the viewer app
    **/
   private viewerRoute: string = TLN_VIEWER_ROUTE;

   /**
    * the param mapping TODO: for later use
    **/
   protected mapping: Mapping = { 
              current_iri: { param: TLN_PAGE_PARAM, type: "string" },
      current_manuscript_iri: { param: TLN_MANUSCRIPT_PARAM, type: "string" },
      current_genetic_order: { param: TLN_TEXT_GENETIC_ORDER_PARAM, type: "string" } 
   }

   constructor(@Inject(MAT_BOTTOM_SHEET_DATA) private data: ParentInformation, private bottomSheetRef: MatBottomSheetRef<TlnViewerNavigation>, protected router: Router, protected activatedRoute: ActivatedRoute ){ 
      super(router, activatedRoute);
   }

   /*ngOnInit() { //TODO: for later use
     super.ngOnInit();
  }*/

}
