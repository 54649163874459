import { Pipe, PipeTransform } from '@angular/core';

interface ZoomObject {
   zoomFactor: number;
   changeValue: number;
}

@Pipe({
  name: 'zoomTitle'
})
export class ZoomPipe implements PipeTransform {

  transform(zoom: ZoomObject): string {
     return (zoom.zoomFactor+zoom.changeValue < 0) ? Math.round(zoom.zoomFactor*50) + '%' : Math.round((zoom.zoomFactor+zoom.changeValue)*100) + '%';
  }

}
