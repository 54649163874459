import { Pipe, PipeTransform } from '@angular/core';
import { TlnTextGeneticOrder} from '../../datatypes/text_version';

@Pipe({
  name: 'geneticOrderFilter'
})
export class GeneticOrderFilterPipe implements PipeTransform {

  transform(geneticOrders: TlnTextGeneticOrder[], iri?: string): TlnTextGeneticOrder[] {
     if(!iri){
         return geneticOrders
     }
     return geneticOrders.filter(geneticOrder =>geneticOrder.id != iri);
  }

}
