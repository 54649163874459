import { TextField } from '../models';

/**
 * Create the local zoom factor for a textfield according to the maximum height or width.
 **/
export function createLocalZoomFactor(text_field: TextField, max_height, max_width): number {
  if (text_field != null && text_field != undefined){
     let t_height = Number(text_field.height)
     let t_width = Number(text_field.width)
     let local_zoom = max_height/t_height
     if(max_height == -1 && max_width != -1){
         local_zoom = max_width/t_width;
     } else if (max_width == -1 && t_height < t_width){
         local_zoom = (window.innerWidth/2-100)/t_width;
     }
     return local_zoom
  }
  return 1
} 
