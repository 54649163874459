import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Content } from './models';

interface Sectioning {
   prefix: string;
   sectionNumber: number;
   subsectionNumber?: number;
   href: string;
}

@Component({
  selector: 'heading',
  templateUrl: './heading.component.html',
  styleUrls: ['./heading.component.scss']
})
export class HeadingComponent implements OnInit {
  @Input() title: string;
  @Input() href: string;
  @Input() tableOfContents: Content[];
  @Output() tableOfContentsChanged = new EventEmitter<Content[]>();
  @Input() level: number = 0;
  @Output() indexChanged = new EventEmitter<number>();
   prefix: string;

  constructor() { }

  ngOnInit() {
     const sectioning = this.getSectioning();
     this.prefix = sectioning.prefix;
     if (this.href == undefined || this.href == null){
         this.href =  sectioning.href;
     }
     this.tableOfContents.push({ title: this.title, link: this.href, level: this.level, 
        sectionNumber: sectioning.sectionNumber, subsectionNumber: sectioning.subsectionNumber, prefix: sectioning.prefix  });
     this.indexChanged.emit(this.tableOfContents.length);
     this.tableOfContentsChanged.emit(this.tableOfContents);

  }
  getSectioning(): Sectioning{
      if (this.tableOfContents.length == 0){
         return { prefix: '1.', sectionNumber: 1, href: this.title.replace(/ /g, '_') }
      }
      let sectionNumber = this.tableOfContents[this.tableOfContents.length-1].sectionNumber;
      if (this.level == 0){
         sectionNumber += 1;
         return { prefix: String(sectionNumber) + '.', sectionNumber: sectionNumber, href: this.title.replace(/ /g, '_') };
      } 
      let parents = this.tableOfContents.filter(content =>content.level == 0);
      const href = parents[parents.length-1].title.replace(/ /g, '_') + '_' + this.title.replace(/ /g, '_');
      const subsectionNumber = (this.tableOfContents[this.tableOfContents.length-1].subsectionNumber != undefined) 
         ? this.tableOfContents[this.tableOfContents.length-1].subsectionNumber+1
         : 1;
      return { prefix: String(sectionNumber) + '.' + String(subsectionNumber), sectionNumber: sectionNumber, subsectionNumber: subsectionNumber, href: href };
  }

}
