import { Component, OnInit } from '@angular/core';
import { HeadingComponent } from './heading.component';

@Component({
  selector: 'subheading',
  templateUrl: './heading.component.html',
  styleUrls: ['./heading.component.scss']
})
export class SubheadingComponent extends HeadingComponent {
  level: number = 1;
  
   constructor() { 
      super();
   }
  

}
