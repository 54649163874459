import { BasicResultBindingElement, FusekiResults} from './basic_datatype';
import { TLN_SEARCH_ROUTE } from '../constants';
import { QueryJson } from './query_json';
import { ManuscriptPages } from './manuscript';
import { TlnWord } from './word';
export class TlnExtWord extends TlnWord {
   startLine?: string; 
   endLine?: string;

   constructor(data: any, id?: string, service?: any){
      super(data, id, service)
      this.startLine = this.getData4Key('startLine');
      this.endLine = this.getData4Key('endLine');
   }
}
export class PageResult {
   words: TlnExtWord[] = [];

   constructor(words: TlnExtWord[]){
      this.words = words;
   }
   public getWords(): string[] {
      return this.words.map(word =>word.id);
   }
   public getStartLine(): string {
      let sortedWords = this.words.sort((word0, word1) =>word0.line_number - word1.line_number)
      if (sortedWords.length == 0){
         return '';
      }
      return (sortedWords[0].startLine != undefined && sortedWords[0].startLine != null) ? sortedWords[0].startLine : sortedWords[0].line;
   }
   public getEndLine(): string {
      let sortedWords = this.words.sort((word0, word1) =>word0.line_number - word1.line_number)
      if (sortedWords.length == 0){
         return '';
      }
      return (sortedWords[sortedWords.length-1].endLine != undefined && sortedWords[sortedWords.length-1].endLine != null) 
         ? sortedWords[sortedWords.length-1].endLine : sortedWords[sortedWords.length-1].line;
   }
}
export class FoundPage extends BasicResultBindingElement{
   static readonly query: string = `
   PREFIX tln: <http://www.nie.org/ontology/nietzsche#>
   PREFIX rdf: <http://www.w3.org/1999/02/22-rdf-syntax-ns#>

   SELECT DISTINCT ?id ?manuscript ?title ?number ?word ?text ?line ?line_number ?startLine ?endLine WHERE {
      ?id tln:hasPseudoText ?fulltext.
      FILTER(?fulltext != "none").
      ?manuscript a tln:ArchivalManuscriptUnity;
         tln:hasManuscriptType "Mappe";
         tln:hasPages/rdf:rest*/rdf:first ?id;
         tln:hasTitle ?title.
      FILTER(?manuscript != "none").
      ?id a tln:Page;
          tln:hasNumber ?number;
          tln:hasWords/rdf:rest*/rdf:first ?word.
      ?word tln:hasOutputText ?text;
          tln:wordBelongsToLine ?line.
      ?line tln:lineHasNumber ?line_number.
      OPTIONAL{ ?previouseNode rdf:rest/rdf:first ?line;
                     rdf:first ?startLine.}
      OPTIONAL{ ?myNode rdf:first ?line;
                     rdf:rest/rdf:first ?endLine.}
   } order by ?id ?line_number`;
   title: string
   number: string;
   manuscript: string
   results: PageResult[] = [];


   constructor (data: any, id?: string, service?: any) {
      super(data, id, service);
      this.title = this.getData4Key('title'); 
      this.number =  this.getData4Key('number');
      this.manuscript =  this.getData4Key('manuscript');
   }
   public removeIncompleteResults(searchTerms: string[], ignoreCaseFlag: string) {
      this.results = this.results.filter(result =>searchTerms.every(
         searchText =>result.words.filter(word =>word.text.match(new RegExp('^[^\w\s]*' + searchText + '.*', ignoreCaseFlag))).length > 0))
   }
   /**
    * This method returns the parametrized SPARQL query of this FoundPage
    *
    * If "key" is omitted {@link /classes/BasicResultBindingElement.html#default_key|default_key} will be used.
    *
    * @param find the search text
    * @param korpus (optional) restrict search on selected ManuscriptPages. 
    **/
   public static getSeachQuery(find: string, ignoreCase: boolean, korpus: ManuscriptPages[], manuscript_variable?: string): string {
      let words = find.split(' ')
      let find_regex = words.join('.*') + '.*'
      let or_find_regex = '^[^\\w]?(' + words.join('.*|') + '.*)';
      let parsedQuery = QueryJson.parseQuery(this.query)
      let ignoreCaseFlag = (ignoreCase) ? 'i' : '';
      let join_filters = [ QueryJson.createRegexOperation('fulltext', find_regex, 's' + ignoreCaseFlag) ]
      parsedQuery.where[1] = QueryJson.createFilterObject(join_filters);
      if (korpus.length > 0 && manuscript_variable != undefined && manuscript_variable != null){
         let equal_filters = korpus.map(manuscript =>QueryJson.createEqualsOperation(manuscript.id, manuscript_variable));
         parsedQuery.where[3] =  QueryJson.createFilterObject(equal_filters);
      }
      let or_filters = [ QueryJson.createRegexOperation('text', or_find_regex, ignoreCaseFlag) ]
      parsedQuery.where.push(QueryJson.createFilterObject(or_filters));
      return QueryJson.toString(parsedQuery);
   }
   public static convertData<T extends typeof BasicResultBindingElement>(this: T, data: FusekiResults, id?: string, service?: any): Array<InstanceType<T>> {
      let elements = []; 
      let pages = []; 
      let searchTerms = service.getSearchTerms();
      let content = this.getContent(data);
      let currentResult: PageResult = null;
      let currentPage: FoundPage = null;
      for (var i = 0; i < content.length; i++){
         let page = new FoundPage(content[i], id, service);
         if(content[i]['word'] != undefined && content[i]['word'] != null) { 
            let words = TlnExtWord.convertData({ head: { vars: []}, results: { bindings: <any>[ content[i] ] } }, content[i]['word']['value']);
            if (pages.length == 0 || pages.map(page =>page.id).indexOf(page.id) == -1){
               currentResult = new PageResult(words);
               currentPage = page;
               currentPage.results.push(currentResult)
               pages.push(currentPage)
            } else {
               if (currentResult.words.indexOf(words[0]) == -1){
                  if (currentResult.words.length > 0 
                     && (Math.abs(Math.min(...currentResult.words.map(word =>word.line_number)) - words[0].line_number) > 4
                        || Math.abs(Math.max(...currentResult.words.map(word =>word.line_number)) - words[0].line_number) > 4)){
                     currentResult = new PageResult(words);
                     currentPage.results.push(currentResult);
                  } else {
                     currentResult.words.push(words[0]);
                  }
               }
            }
         }
      }
      pages.forEach(page =>page.removeIncompleteResults(searchTerms, service.getIgnoreCaseFlag()));
      return pages.filter(page =>page.results.length > 0);
   }
}
