import { BasicResultBindingElement } from './basic_datatype';
import { PositionalObject } from '../models';
/**
 * This is the 'text by foreign hand' instantiation of an element of {@link /interfaces/FusekiResults.html|FusekiResults}.
 * It extends {@link /classes/BasicResultBindingElement.html|BasicResultBindingElement}.
 **/
export class TlnPositionalObject extends BasicResultBindingElement implements PositionalObject {
   /** 
    * the internal default key for replacing {@link /classes/TlnWord.html#query|query} by "id"
    * in {@link /classes/TlnWord.html#getQuery|getQuery} if "key" is omitted.
    **/
   static readonly default_key: string = 'page';
   /** 
    * the public key for replacing {@link /classes/TlnWord.html#query|query} by "id".
    **/
   static readonly query_key: string = 'page';
   /**
    * the geometrical left position of this word's rect.
    **/
   left: number;
   /**
    * the geometrical top position of this word's rect.
    **/
   top: number;
   /**
   * the width of this word's rect.
   **/
   width: number;
   /**
   * the height of this word's rect.
   **/
   height: number;
   /** 
    * the matrix transformation string of the geometrical position of this word's rect.
    * */
   transform?: string;
      
    /**
    * The constructor creates a datatype from the data.
    *
    * @param id if omitted the id will be retrieved from data
    **/
   constructor(data: any, id?: string, service?: any){
      super(data, id, service)
      this.left = this.getData4Key('left');
      this.top = this.getData4Key('top');
      this.width = this.getData4Key('width');
      this.height = this.getData4Key('height');
      this.transform = this.getData4Key('transform');
   }
}
