import { Component, Input, OnInit, OnChanges, ElementRef, ViewChild} from '@angular/core';
import { MarkupSettings, StandoffMarkup } from 'ngx-mat-standoff-markup';
import { ConfigurableComponent, PageViewService, TlnCacheQueryService } from '../services';
import { Configuration, Continuation, Copyright, DataProcessor, Point, Position, PositionalObject, Reference, TextByForeignHand, TlnQueryServiceInterface, Word } from '../models';
import { TlnEarlierVersionStub, TlnOverwrittenStub } from '../datatypes/earlier_version';
import { TlnLineReference } from '../datatypes/line_reference';
import { TlnPreviewWord } from '../datatypes/preview_data';
import { TlnPositionalStyleMarkup, TlnFilteredPositionalStyleMarkup } from '../datatypes/positional-markup';
import { TlnEditorCommentStub } from '../datatypes/editor_comment';
import { DataHandler } from '../data_handler';

/**
 * This component can be used to show extended tooltips, i.e. with deleted words and distinguished
 * words if they are by foreign hand.
 **/
@Component({
  selector: 'tool-tip',
  templateUrl: './tool-tip.component.html',
  providers: [ TlnCacheQueryService ],
  styleUrls: ['./tool-tip.component.css']
})
export class ToolTipComponent extends ConfigurableComponent implements OnInit, OnChanges, DataProcessor {
   @Input('fullscreen') fullscreen: boolean = false;
   @Input() hasMenuIssues: boolean = false;
   @Input() menuOffsetPosition: Position;
   @Input() container: HTMLElement;
   //fullscreen: boolean = false;
   /**
    * content keys
    **/
   private readonly contentKeys: string[] = [ 'word', 'foreignHand', 'copyright', 'continuation' ]
   /**
    * copyright information to be shown in the tooltip.
    **/
   copyright: Copyright;
   /**
    * current key of contentKeys
    **/
   currentKey: string;
   debug: boolean = false;
   position: number = 0;
   /**
    * the data handler of this component that retrieves
    * data and instantiates it according to their proper 
    * datatypes.
    **/
   dataHandler: DataHandler = new DataHandler(this);
   /**
    * text by foreign hand to be shown in tooltip
    **/
   foreignHand: TextByForeignHand;
   /**
    * earlier version of word
    **/
   earlier_version: TlnEarlierVersionStub;
   earlier_version_list: TlnEarlierVersionStub[] = [];
   /**
    * overwritten word
    **/
   overwrittenWord: TlnOverwrittenStub;
   /**
    * editor comments 
    **/
   editor_comments: TlnEditorCommentStub[] = [];
   /**
    * a line continuation
    **/
   continuation: Continuation;
   /**
    * a list of positional style markups.
    **/
   positionalStyleMarkups: StandoffMarkup[] = [];
   continuation_words: TlnPreviewWord[] = [];
   mySettings: MarkupSettings = new MarkupSettings();
   width: number = 300;
   /**
    * line reference from
    **/
   sourceLineReference: TlnLineReference;
   /**
    * actual tooltip position
    **/
   tooltipPosition: Point = { visible: false, clientX: -1, clientY: -1, layerX: -1, layerY: -1 };
   /**
    * whether or not to show extended tooltips
    **/
   useExtendedTooltip: boolean = true;
   /**
    * word to be shown in tooltip
    **/
   word: Word;
   /**
    * y offset for tooltip position relative to mouse position
    **/
   private readonly yOffset: number = 25;
   private readonly menuIssueOffset: number = 80;
   private readonly menuIssueXOffset: number = 55;
   topOffset: number = 0; 
   leftOffset: number = 0; 
   constructor(protected pageViewService: PageViewService, private tlnQueryService: TlnCacheQueryService) { 
      super()
   }
   ngOnChanges(){
      super.ngOnChanges();
      /*if((this.fullscreen && this.fullscreenString != 'true') || (!this.fullscreen && this.fullscreenString == 'true')){
         this.fullscreen = (this.fullscreenString == 'true')
      }*/
      if (this.tlnQueryService != null && !this.dataHandler.ready){
         this.dataHandler.addHandler('earlier_version', { 'handler': TlnEarlierVersionStub, 'process_data': this });
         this.dataHandler.addHandler('overwrittenWord', { 'handler': TlnOverwrittenStub});
         this.dataHandler.addHandler('positionalStyleMarkups', { 'handler': TlnFilteredPositionalStyleMarkup });
         this.dataHandler.addHandler('editor_comments', { 'handler': TlnEditorCommentStub});
         this.dataHandler.addHandler('sourceLineReference', { 'handler': TlnLineReference});
         this.dataHandler.addHandler('continuation_words', { 'handler': TlnPreviewWord});
         this.dataHandler.addHandler('wordStubs', ['earlier_version', 'overwrittenWord', 'positionalStyleMarkups', 'editor_comments' ]);
         this.dataHandler.setQueryService(this.tlnQueryService);
         this.tlnQueryService.reset_data.subscribe(
            (data_key: string) =>{
               if (data_key == 'page_content'){
                  this.resetData()
               }
         });
      }
   }
   /**
    * listen on pageViewService
    **/
   ngOnInit() {
     this.pageViewService.mousePosition.subscribe(
        (newPoint: Point) =>{  this.tooltipPosition = newPoint;
           //console.log(this.tooltipPosition);
        });
      this.pageViewService.onHoveredWord.subscribe(
         (newWord: Word) => { this.dataHandler.stop_processing.emit(true);this.setContent('word', newWord);this.updateEarlierVersion() }
      );
      this.pageViewService.offHoveredWord.subscribe(
         (newWord: Word) => { this.word = null; this.resetData() }
      );
      this.pageViewService.onHoveredTextByForeignHand.subscribe(
         (newTextByForeignHand: TextByForeignHand) => { this.setContent('foreignHand',newTextByForeignHand) }
      );
      this.pageViewService.offHoveredTextByForeignHand.subscribe(
            (newTextByForeignHand: TextByForeignHand) => this.foreignHand = null 
      );
      this.pageViewService.copyright.subscribe(
         (copyright: Copyright) =>{ 
            this.setContent('copyright', (this.copyright == null) ? copyright : null)
      });
      this.pageViewService.onHoveredContinuation.subscribe(
         (newContinuation: Continuation) => { 
            if(this.continuation == null){
               this.updateLineContinuation(newContinuation) 
            }
      });
      this.pageViewService.offHoveredContinuation.subscribe(
         (newContinuation: Continuation) => { this.dataHandler.stop_processing.emit(true);this.continuation = null;this.updateLineContinuation(); }
      );
  }
  processData() {
      if (this.earlier_version != undefined && this.earlier_version != null){
         this.earlier_version_list.splice(0, 0, this.earlier_version)
         this.dataHandler.resetData('earlier_version');
         this.dataHandler.getData('earlier_version', this.earlier_version_list[0].id);
      }
  }
  /**
   * reset data
   **/
  private resetData(){
      this.earlier_version_list = [];
      this.dataHandler.resetData('positionalStyleMarkups')
      this.dataHandler.resetData('wordStubs')
      this.contentKeys.forEach(key=>this[key] = null);
  }
  /**
   * update earlier version of word
   **/
  private updateEarlierVersion(){
      if (this.word != null && this.dataHandler.ready) {
         this.earlier_version_list = [];
         this.dataHandler.resetData('positionalStyleMarkups')
         this.dataHandler.resetData('wordStubs')
         this.dataHandler.getData('wordStubs', <string>this.word.id);
      }
  }
  private updateLineContinuation(continuation?: Continuation){
      this.setContent('continuation', continuation);
      if(continuation != null && this.dataHandler.ready){
         this.dataHandler.resetData('continuation_words')
         if (continuation.source.page != null){
            this.dataHandler.getData('continuation_words', <string>this.continuation.reference.line.id)
         }
      }
  }
  private getTop(key: string): number {
      if (this.menuOffsetPosition != undefined && this.menuOffsetPosition != null && this.container != null && this.container != undefined){
         let containerRect: DOMRect =  <DOMRect>this.container.getBoundingClientRect();
         this.topOffset = containerRect.top;
         if (!this.fullscreen){
            this.topOffset = this.topOffset - this.menuOffsetPosition.y;
         }
      }
     return this.tooltipPosition.clientY - this.topOffset + this.yOffset;
  }
  private getLeft(width: number): number {
     let containerRect: DOMRect = (this.container != null && this.container != undefined) 
        ? <DOMRect>this.container.getBoundingClientRect() : null;
     this.leftOffset = (this.menuOffsetPosition != undefined && this.menuOffsetPosition != null && containerRect != null) ? containerRect.left : 0;
     let left = this.tooltipPosition.clientX - this.leftOffset;
     if (this.menuOffsetPosition != undefined && this.menuOffsetPosition != null){
         left += this.menuOffsetPosition.x;
     }
     if (!this.tooltipPosition.visible 
        || this.continuation == null
        || this.continuation.show == null
        || this.continuation.show != 'to'){
         return left;
      }
      return (left + width <= containerRect.right) ?  left : left-(left+width-containerRect.right)-5;
  }
  /**
   * Set tooltip's content and remove prior content.
   * @param key   key of content
   * @param content new content
   **/
  private setContent(key: string, content: Continuation|Copyright|PositionalObject){
     this.contentKeys.forEach(key=>this[key] = null);
     this[key] = content;
     this.currentKey = key;
  }
  /**
   * whether tooltip has any content (i.e. any content of {@link /components/ToolTipComponent.html#contentKeys|contentKeys})
   **/
  private hasAnyContent(): boolean {
     return this.contentKeys.filter(key =>this[key] != null).length > 0 
  }
}
