import { Component, OnInit, OnChanges, SimpleChanges,Input } from '@angular/core';
import { DataHandler } from '../data_handler';
import { QuantitativeDataHandler } from '../quant_data_handler';
import { TlnQueryServiceInterface} from '../models';
import { TlnCacheQueryService } from '../services';
import { ManuscriptPageWords } from '../datatypes/word_presentation';

@Component({
  selector: 'word-presentation',
  templateUrl: './word-presentation.component.html',
  styleUrls: ['./word-presentation.component.css']
})
export class WordPresentationComponent implements OnInit, OnChanges {
   /**
   * OPTIONAL pass a queryService with method 
   * {@link /interfaces/TlnQueryServiceInterface.html#getData|getData}
   * to TlnPageViewComponent.
   **/
   @Input() queryService: TlnQueryServiceInterface;
   @Input() wordIds: string[];
   @Input() expanded: boolean = false;
   isLoadingResults: boolean = false;
   dataHandler: QuantitativeDataHandler = new QuantitativeDataHandler(this);
   private readonly displayedColumns: string[] = [ 'text', 'editedText', 'cleanText', 'cleanEditedText', 'link'];
   manuscriptPageWords: ManuscriptPageWords[] = [];
   tlnQueryService: TlnQueryServiceInterface;

   constructor(private localQueryService: TlnCacheQueryService) {}

   ngOnInit() {
      this.tlnQueryService = (this.queryService != null) ? this.queryService : this.localQueryService;
      this.dataHandler.addHandler('manuscriptPageWords', { 'handler': ManuscriptPageWords});
      this.dataHandler.setQueryService(this.tlnQueryService);
      this.dataHandler.start_processing.subscribe(
         (started: boolean) =>{ 
            this.isLoadingResults = true;
      });
      this.dataHandler.processing_finished.subscribe(
         (started: boolean) =>{ 
            this.isLoadingResults = false;
      });
   }
   ngOnChanges(changes: SimpleChanges){
      if (this.expanded && this.manuscriptPageWords.length == 0 && this.wordIds.length > 0){
         const query = ManuscriptPageWords.getParameterizedQuery(this.wordIds)
         this.dataHandler.getData4Query('manuscriptPageWords', query);
      }
   }
}
