import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { MatBottomSheetRef} from '@angular/material/bottom-sheet';
import { ManuscriptUnity, NavigationPage} from '../../models';
import { Mapping, RouteReader } from '../../route-reader';
import { RouteUpdater } from '../../route-updater';
import { TlnTextGeneticOrder} from '../../datatypes/text_version';
import { TLN_CONTEXT_VIEW_PARAM, TLN_PAGE_PARAM, TLN_MANUSCRIPT_PARAM, TLN_TEXT_GENETIC_ORDER_PARAM, TLN_CROSSREF_ROUTE } from '../../constants';

@Component({
  selector: 'page-version-view',
  templateUrl: './page-version-view.component.html',
  styleUrls: ['./page-version-view.component.css']
})
export class PageVersionViewComponent extends RouteUpdater implements OnInit {
   /**
    * IRI of the current page
    **/
   current_iri: string;
   /**
    * the current page
    **/
   @Input() current_page_iri: string;
   /**
    * the genetic text order that should be displayed.
    **/
   @Input() geneticOrders: TlnTextGeneticOrder[] = [];
   @Input() bottomSheetRef: MatBottomSheetRef;
   @Input() parentActivatedRoute: ActivatedRoute;
   @Input() targetRoute: string;
   @Output() textGenesisSelected = new EventEmitter<string>();
   current_manuscript_iri: string;
   current_genetic_order: string;
   contextView: string;
   //TODO: change default parentActivatedRoute, exception @Input
   protected currentRoute: string;// = TLN_CROSSREF_ROUTE;
   protected mapping: Mapping = { 
              current_iri: { param: TLN_PAGE_PARAM, type: "string" },
      current_manuscript_iri: { param: TLN_MANUSCRIPT_PARAM, type: "string" },
      contextView: { param: TLN_CONTEXT_VIEW_PARAM, type: "string" },
      current_genetic_order: { param: TLN_TEXT_GENETIC_ORDER_PARAM, type: "string" } 
   }

   constructor(protected router: Router, protected activatedRoute: ActivatedRoute ){ 
      super(router, activatedRoute);
   }

  ngOnInit() {
     if (this.targetRoute != undefined && this.targetRoute != null){
         this.currentRoute = this.targetRoute;
     }
     super.ngOnInit();
  }
  private openCrossRef(geneticOrder: TlnTextGeneticOrder){
     this.current_genetic_order = geneticOrder.id; 
     this.textGenesisSelected.emit(this.current_genetic_order);
     this.current_iri = this.current_page_iri
     this.contextView = TLN_PAGE_PARAM;
     this.updateParams();
     if(this.bottomSheetRef != undefined && this.bottomSheetRef != null){
         this.bottomSheetRef.dismiss();
     }
  }

}
