import { Pipe, PipeTransform } from '@angular/core';
import { FoundPage} from '../datatypes/search';
@Pipe({
  name: 'resultPipe'
})
export class ResultPipePipe implements PipeTransform {

   transform(pages: FoundPage[]): string {
      if (pages.length == 0){
         return 'Keine Treffer'
      }
      let pageResult = (pages.length > 1) ? pages.length + ' Seiten' : pages[0].title + ' ' + pages[0].number;
      let numFoundWords = 0;
      pages.forEach(page =>page.results.forEach(result =>numFoundWords++));
      return numFoundWords + ' Treffer auf ' + pageResult;
   }

}
