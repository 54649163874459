import { TlnImage } from './image';
import { Image } from '../models';

export class SVGImage extends TlnImage {
   static readonly query: string = `
   PREFIX tln: <http://www.nie.org/ontology/nietzsche#>

   SELECT ?id ?filename ?width ?height ?URL ?secondaryURL ?t_left ?t_top ?t_width ?t_height ?page WHERE {
      ?id a tln:SVGImage;
           tln:hasPrimaryurl ?URL;
           tln:hasSecondaryurl ?secondaryURL;
           tln:hasFileName ?filename;
           tln:hasWidth ?width;
           tln:hasHeight ?height;
           tln:hasTextField ?textfield.
      ?textfield tln:hasLeft ?t_left;
           tln:hasTop ?t_top;
           tln:hasWidth ?t_width;
           tln:hasHeight ?t_height;
           ^tln:pageIsOnTextField ?page.
   }`;

   constructor (data: any, id?: string, service?: any) {
      super(data, id, service);
      this.x = this.text_field.left;
      this.y = this.text_field.top;
   }
}
export class SVGImageClip extends SVGImage {
   static readonly query: string = `
   PREFIX tln: <http://www.nie.org/ontology/nietzsche#>
   PREFIX rdf: <http://www.w3.org/1999/02/22-rdf-syntax-ns#>

   SELECT ?id ?filename ?width ?height ?URL ?secondaryURL ?t_left ?t_top ?t_width ?t_height ?page ?y ?end_bottom WHERE {
      ?id a tln:SVGImage;
           tln:hasPrimaryurl ?URL;
           tln:hasSecondaryurl ?secondaryURL;
           tln:hasFileName ?filename;
           tln:hasWidth ?width;
           tln:hasHeight ?height;
           tln:hasTextField ?textfield.
      ?textfield tln:hasLeft ?t_left;
           tln:hasTop ?y;
           tln:hasWidth ?t_width;
           ^tln:pageIsOnTextField ?page.
       ?page tln:hasLines ?lines.
       ?lines rdf:rest* ?start_node;
              rdf:rest* ?end_node.
       ?start_node rdf:first ?startLine;.
       ?end_node rdf:first ?endLine;.
       ?startLine tln:lineHasTopValueOnTranskription ?start_top.
       ?endLine tln:lineHasBottomValueOnTranskription ?end_bottom.
       OPTIONAL { ?start_node ^rdf:rest/rdf:first ?previousLine.
         ?previousLine tln:lineHasBottomValueOnTranskription ?previous_bottom. 
       }
       OPTIONAL { ?end_node rdf:rest/rdf:first/rdf:rest/rdf:first ?nextLine.
         ?nextLine tln:lineHasTopValueOnTranskription ?next_top. 
       }
       BIND(IF(bound(?previous_bottom), ?previous_bottom+1, ?start_top - 5) as ?t_top)
       BIND(IF(bound(?next_top), ?next_top-?t_top, ?end_bottom - ?t_top + 10) as ?t_height)
   }`;

   constructor (data: any, id?: string, service?: any) {
      super(data, id, service);
      this.y = this.getData4Key('y');
   }
}
