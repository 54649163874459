import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Params, Router} from '@angular/router';
import {Subscription} from "rxjs/index";
import {NavigationServiceService} from "../services/navigation-service.service";
import {
  TLN_CROSSREF_EDITOR_ROUTE, TLN_CROSSREF_ROUTE, TLN_MANUSCRIPT_ROUTE, TLN_SEARCH_ROUTE,
  TLN_VIEWER_ROUTE, TLN_QUANT_ROUTE
} from '../tln-edition/constants';

@Component({
  selector: 'app-content-view-tab-component',
  templateUrl: './content-view-tab-component.component.html',
  styleUrls: ['./content-view-tab-component.component.scss'],
  providers: [ NavigationServiceService ]
})
export class ContentViewTabComponentComponent implements OnInit {
  // navigation tabs for the content view (manuscript view, page view, rhizome view)
  navTabLinks: any[];
  // navbar on th left for navigating
  navBarOpenState = false;
  fullscreen = false;

  navBarOpenMode: string;
  queryParams: Params = {};
  queryParamSubscription: Subscription;
  routeSubscription: Subscription;
  activeSubscription: Subscription;

  constructor(private router: Router,
              private activatedRoute: ActivatedRoute,
              private naviService: NavigationServiceService) {

    // The links/tabs for routing the correct view-component
    this.navTabLinks = [
      {
        label: 'Manuskriptansicht',
        link: TLN_MANUSCRIPT_ROUTE,
        index: 0,
        isActive: true,
        context: 'manuscript', // in which context the viewers are opened
        disabled: false
      }, {
        label: 'Seitenansicht',
        link: TLN_VIEWER_ROUTE,
        index: 1,
        isActive: false,
        context: 'page', // in which context the viewers are opened
        disabled: false
      }, {
        label: 'Querverweise',
        link: TLN_CROSSREF_ROUTE,
        index: 2,
        isActive: false,
        disabled: false
      }, {
        label: 'Suche',
        link: TLN_SEARCH_ROUTE,
        index: 3,
        isActive: false,
        disabled: false
      },
      {
        label: 'Datenabfrage',
        link: TLN_QUANT_ROUTE,
        index: 4,
        isActive: false,
        disabled: false 
      },
    ];
    this.activeSubscription = this.activatedRoute.url.subscribe(url =>{
        if ( !this.navTabLinks.find(nl => nl.link === this.activatedRoute.snapshot.children.pop().routeConfig.path).isActive ) {
          this.setActiveLink(this.activatedRoute.snapshot.children.pop().routeConfig.path);
        }
    });
    // subscribe to route event and for marking the active navTab as active
    this.routeSubscription = this.router.events.subscribe((event) => {
      if ( event instanceof NavigationEnd) {
        // if the navigated link is not active, we set it acive
        if ( !this.navTabLinks.find(nl => nl.link === this.activatedRoute.snapshot.children.pop().routeConfig.path).isActive ) {
          this.setActiveLink(this.activatedRoute.snapshot.children.pop().routeConfig.path);
        }
      }
    });

    this.queryParamSubscription = this.activatedRoute.queryParams.subscribe( (queryParams: Params ) => {
      this.queryParams = queryParams;
      if (queryParams.navBarOpenState) {
        this.navBarOpenState =  JSON.parse(queryParams.navBarOpenState.toLowerCase());
      }
      if (queryParams.fullscreen) {
        this.fullscreen = JSON.parse(queryParams.fullscreen.toLowerCase());
      }
    });
  }

  ngOnInit() {
    this.navBarOpenMode = 'side'; // side || over || push
    this.setParamsOnInit();
    // If url pasted or page refreshed --> resetting this.queryparams to the query params of the url;
    // needed for active routing in the nav tabs & for general use in the template
  }

  // Sets the isActive prop of a navTabLink to true and all others to false .
  setActiveLink(link: string) {
    this.navTabLinks.forEach((navTabLink, index ) => {
      if (navTabLink.link === link) {
        this.navTabLinks[index].isActive = true;
        if (navTabLink.context) {
          window.setTimeout(() => this.naviService.updateRoute({contextView : navTabLink.context}), 300);
        }
      } else {
        this.navTabLinks[index].isActive = false;
      }
    });
  }

  /**
   * setParamsOnInit checks if the page is loaded with query params. If a certain query param is missing, it will be set to a default value
   *
   */
  setParamsOnInit() {
    const qParams: Params = {};
    // Set the NavBarOpenstate to true if it is not defined explicitely as false in the url query param onInit.
    if (this.activatedRoute.snapshot.queryParamMap.get('navBarOpenState') !== 'false') {
    qParams.navBarOpenState = 'true'; }
    // viewMode
    if (!this.activatedRoute.snapshot.queryParamMap.get('viewMode')) {
      qParams.viewMode = 'Transkription/Faksimile';
    }
    // contextView
    if (!this.activatedRoute.snapshot.queryParamMap.get('contextView')) {
      qParams.contextView = 'manuscript';
    }
    // navTabIdx: if none default is null
    if (!this.activatedRoute.snapshot.queryParamMap.get('navTabIdx')) {
      if (this.activatedRoute.snapshot.queryParamMap.get('contextView') === 'manuscript'  || qParams.contextView === 'manuscript') {
        qParams.navTabIdx = '0'; } else {
        qParams.navTabIdx = '1'; }
    }
    this.naviService.updateRoute(qParams);
  }
}
