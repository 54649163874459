import { Component, Input, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { OntologyQueryService } from './ontology-query.service';
import { DataMapping, QueryDataMapping, Namespace, Ontology, OntologyBearer } from './models';
import { SparqlParser } from './sparqljs';


@Component({
  selector: 'ontology-definition',
  templateUrl: './ontology-definition.component.html',
  styleUrls: ['./ontology-definition.component.css']
})
export class OntologyDefinitionComponent implements OnInit, OntologyBearer {
  @Input() ontology: Ontology;
  @Input() namespaces: Namespace[];
  dataMapping: DataMapping = { subject: null, subjectType: null, queryDataMappings: [] };
  query: string = `
  PREFIX rdf: <http://www.w3.org/1999/02/22-rdf-syntax-ns#>
  PREFIX rdfs: <http://www.w3.org/2000/01/rdf-schema#>
  PREFIX owl: <http://www.w3.org/2002/07/owl#>
  PREFIX dct: <http://purl.org/dc/terms/>

  SELECT ?title ?description ?creator ?contributor ?license ?publisher ?version WHERE {
      ?ontology a owl:Ontology.
         OPTIONAL { ?ontology dct:license ?license }
         OPTIONAL { ?ontology dct:title ?title }
         OPTIONAL { ?ontology dct:description ?description }
      	OPTIONAL { ?ontology dct:creator ?creator }
         OPTIONAL { ?ontology dct:publisher ?publisher}
         OPTIONAL { ?ontology dct:contributor ?contributor } 
         OPTIONAL { ?ontology owl:versionInfo ?version } 
   }`

  constructor(private queryService: OntologyQueryService) { }

  ngOnInit() {
     if (this.ontology != undefined && this.ontology != null){
        let parser = new SparqlParser(this.queryService);
        parser.fetchData(this, 'dataMapping', this.query, 'ontology', this.ontology.iri, this.namespaces);
     }
  }

}
