import { BasicResultBindingElement, FusekiResults } from './basic_datatype';
import { TlnWord } from './word';
import { TlnPositionalStyleMarkup, TlnFilteredPositionalStyleMarkup } from './positional-markup';

export class TlnPreviewWord extends TlnWord  {
   /** 
    * the internal default key for replacing {@link /classes/TlnLine.html#query|query} by "id"
    * in {@link /classes/TlnLine.html#getQuery|getQuery} if "key" is omitted.
    **/
   static readonly default_key: string = 'line';
   static readonly query_key: string = 'line';
   /** 
    * the SPARQL-query of this datatype.
    **/
   static readonly query: string = `
   PREFIX homotypic: <http://www.nie.org/ontology/homotypic#>
   PREFIX rdf: <http://www.w3.org/1999/02/22-rdf-syntax-ns#>
   PREFIX stoff: <http://www.nie.org/ontology/standoff#>
   PREFIX tln: <http://www.nie.org/ontology/nietzsche#>

   SELECT DISTINCT ?id ?text ?markup_id ?sStyle ?start ?end ?left ?top ?earlier_version WHERE {
      ?id a tln:Word; 
            tln:wordBelongsToLine ?line; 
            tln:hasTranskriptionPosition/tln:hasLeft ?left;
            tln:hasTranskriptionPosition/tln:hasTop ?top;
            tln:hasText ?text;
            homotypic:hasMarkup ?markup_id. 
      ?markup_id stoff:hasCSS ?sStyle;
          stoff:standoffMarkupHasStartIndex ?start;
          stoff:standoffMarkupHasEndIndex ?end.

      FILTER(NOT EXISTS {?word tln:wordHasWordParts/rdf:rest*/rdf:first ?id})
      FILTER(NOT EXISTS {?word (tln:wordHasEarlierVersion|tln:overwritesWord) ?id})
      OPTIONAL { ?id (tln:wordHasEarlierVersion|tln:overwritesWord)/tln:hasText ?earlier_version}
   }`;
   earlier_version?: string;
   left: number;
   top: number;
   markups: TlnPositionalStyleMarkup[] = [];
    /**
    * The constructor creates a datatype from the data.
    *
    * @param id if omitted the id will be retrieved from data
    **/
   constructor(data: any, id?: string, service?: any){
      super(data, id, service)
      this.earlier_version = this.getData4Key('earlier_version')
      this.left = this.getData4Key('left')
      this.top = this.getData4Key('top')
   }
   public static convertData<T extends typeof BasicResultBindingElement>(this: T, data: FusekiResults, id?: string, service?: any): Array<InstanceType<T>> {
      let elements = []; 
      let content = this.getContent(data);
      for (var i = 0; i < content.length; i++){
         let element = new TlnPreviewWord(content[i], id, service);
         element.markups = TlnFilteredPositionalStyleMarkup.convertData({ head: { vars: []}, results: { bindings: <any>[ content[i] ] } }, content[i]['markup_id'])
         if (elements.length > 0 && element.id == elements[elements.length-1].id){
            elements[elements.length-1].markups.push(element.markups[0])
            if (element.top < elements[elements.length-1].top && element.left < elements[elements.length-1].left){
               elements[elements.length-1].left = element.left;
            }
         } else {
            elements.push(element);
         }
      }
      elements.sort((w0: TlnPreviewWord, w1: TlnPreviewWord)=>{
         return w0.left - w1.left
      })
      return elements;
   }
}
