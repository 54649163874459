import { Injectable } from '@angular/core';
import { Parser, Generator, Wildcard } from 'sparqljs';
import {HttpClient, HttpHeaders} from '@angular/common/http';


@Injectable()
export class QueryService {
  constructor(private http: HttpClient) {
  }

  parser = new Parser();
  sparqlGenerator = new Generator({});

  /**
   * Gets the data from an endpoint via http post
   *
   * @param baseUrl:  The url of the endpoint.
   * @param query:  The query to run.
   * @param queryType: "CONSTRUCT" or "QUERY"
   * @returns the response.
   */
  public getData(baseUrl: string, query: string, queryType?: string ) {
    let httpOptions;
    if (queryType === 'CONSTRUCT') {
      //  A construct does contain a text as response, not a json, so responseType must be 'text' to avoid parse errors
        httpOptions = { headers: new HttpHeaders({'Content-Type': 'application/sparql-query', 'Accept': 'text/turtle'}),
                        responseType: 'text'};
        return this.http.post(baseUrl, query, httpOptions).toPromise();
    } else {
      httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/sparql-query',
                                                                'Accept': 'application/sparql-results+json; charset=UTF-8'})};
      return this.http.post(baseUrl, query, httpOptions).toPromise();
    }
  }

  /**
   * Gets a text file by its name from the directory assets/queries.
   *
   * @param filename  The name of the file + file name extension.
   * @returns the text of the file.
   */
  public getQueryfromFilename(filename) {
    return this.http.get('../assets/queries/' + filename, {responseType: 'text'}).toPromise();
  }

  /**
   * Gets a query string from a given file in the directory assets/queries.
   *
   * @param filename  The name of the file + file name extension.
   * @returns The JSON equivalence of the parsed query.
   */
  public parseQueryFromFile(filename) {
    return this.getQueryfromFilename(filename )
      .then(query => {
        this.parser.parse(query);
      } );
  }

  /**
   * getQueryForItem()
   * Parametrizes a given baseQuery with a iri passed, so the iri will be the resource of the where clause
   *
   * @param subjectIri:  The iri of the selected resource
   * @param baseQuery: name of the query to parametrize
   * @param propertyIri: The iri of the selected property
   * @param objectIri: The iri of the selected object
   * @returns the query for the resource.
   */
  public parametrizeQueryWithItem(baseQuery: string, subjectIri?: string, propertyIri?: string, objectIri?: string): string {
      let parsedQuery = this.parser.parse(baseQuery);
      // parametrize/reset the subject iri to the word's iri we like to query for
      if (subjectIri && subjectIri !== '') {
        parsedQuery.where[0].triples[0].subject = {'termType': 'NamedNode', 'value': decodeURI(subjectIri) };
      }

      if (propertyIri && propertyIri !== '') {
        parsedQuery.where[0].triples[0].predicate = {'termType': 'NamedNode', 'value': decodeURI(propertyIri) };
      }

      if (objectIri && objectIri !== '') {
        parsedQuery.where[0].triples[0].object = {'termType': 'NamedNode', 'value': decodeURI(objectIri) };
      }
      // generate the new query string and return it
      return this.sparqlGenerator.stringify(parsedQuery);
    }

}
