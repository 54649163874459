import { Parser, Generator } from 'sparqljs';
import { first } from 'rxjs/operators';
import { OntologyQueryService } from './ontology-query.service';
import { Link, ParsedQuery, DataMapping, Namespace, QueryDataMapping, Triple, Ontology, OntologyBearer } from './models';

export class SparqlParser {
   namespaces: Namespace[] = [ { prefix: 'rdf', iri: 'http://www.w3.org/1999/02/22-rdf-syntax-ns#', isExternal: true },
                               { prefix: 'rdfs', iri: 'http://www.w3.org/2000/01/rdf-schema#', isExternal: true },
                               { prefix: 'owl', iri: 'http://www.w3.org/2002/07/owl#', isExternal: true },
                               { prefix: 'xsd', iri: 'http://www.w3.org/2001/XMLSchema#', isExternal: true } ]

   constructor(private queryService: OntologyQueryService){}

   public fetchArrayData(component: OntologyBearer, target_key: string, query: string, key: string, iri: string, namespaces?: Namespace[]){
         if (namespaces != undefined && namespaces != null){
            this.namespaces = Array.from(new Set(this.namespaces.concat(namespaces)));
         }
         const paramQuery = SparqlParser.getQuery(query, key, iri);
         let parsedQuery = this.createParsedQuery(paramQuery);
         this.queryService.getData(paramQuery).subscribe( fusekiResults => {
            fusekiResults.results.bindings.forEach(fusekiResult =>{
               let dataMapping  = this.mapQuery2Data(fusekiResult, parsedQuery, component.ontology); 
               let oldItem = component[target_key].filter(item =>item.subject == dataMapping.subject)
               if (oldItem.length > 0){
                   oldItem[0].queryDataMappings = oldItem[0].queryDataMappings.concat(dataMapping.queryDataMappings)
               } else {
                  component[target_key].push(dataMapping);
               }
            });
         });
   }
   public fetchData(component: OntologyBearer, target_key: string, query: string, key: string, iri: string, namespaces?: Namespace[]){
         if (namespaces != undefined && namespaces != null){
            this.namespaces = Array.from(new Set(this.namespaces.concat(namespaces)));
         }
         const paramQuery = SparqlParser.getQuery(query, key, iri);
         let parsedQuery = this.createParsedQuery(paramQuery);
         this.queryService.getData(paramQuery).pipe(first()).subscribe( fusekiResults => {
            let fusekiResult = fusekiResults.results.bindings[0];
            component[target_key] = this.mapQuery2Data(fusekiResult, parsedQuery, component.ontology); 
         })
   }
   private createParsedQuery(paramQuery: string) : ParsedQuery {
         let parser = new Parser();
         let sparqlGenerator = new Generator({});
         let parsedQuery = parser.parse(paramQuery)
         let prefixes = parsedQuery.prefixes;
         Object.keys(prefixes).forEach(key =>{
            if(this.namespaces.filter(namespace =>namespace.prefix == key).length == 0){
               this.namespaces.push({ prefix: key, iri: prefixes[key], isExternal: true });
            }
         })
         return parsedQuery;
   }
   private mapQuery2Data(result: Object, parsedQuery: ParsedQuery, ontology: Ontology): DataMapping {
      let queryDataMappings: QueryDataMapping[] = [];
      let dataMapping: DataMapping = { subject: null, subjectType: null, queryDataMappings: queryDataMappings };
      this.mapData2Query(dataMapping, parsedQuery, result, ontology);
      return dataMapping;
   }
   private mapData2Query(dataMapping: DataMapping, parsedQuery: ParsedQuery, result: Object, ontology: Ontology){
      let variables = parsedQuery.variables.map(variable =>variable['value']);
      parsedQuery.where.forEach(where =>{
         if(where['type'] == 'bgp'){
            where['triples'].forEach( (triple: Triple) =>{
               this.addContent(dataMapping, triple, variables, result, false, ontology);
            });
         } else if(where['type'] == 'optional' || where['type'] == 'union'){
            where['patterns'].forEach(pattern =>{
               if (pattern['triples'] != undefined){
                  pattern['triples'].forEach( (triple: Triple) =>{
                     this.addContent(dataMapping, triple, variables, result, true, ontology);
                  });
               } 
            });
         } 
      });
   }
   private addContent(dataMapping: DataMapping, triple: Triple, variables: string[], result: Object, isOptional: boolean, ontology: Ontology){
      if (variables.includes(triple.subject.value) && !variables.includes(triple.object.value) && triple.object.value != ontology.iri){
         dataMapping.subject = this.replacePrefix(result[triple.subject.value]['value']);
         dataMapping.subjectType = this.createLink(triple.object.value);
      } else if (variables.includes(triple.object.value) && (!isOptional || result.hasOwnProperty(triple.object.value))){
         let link = this.createLink(result[triple.object.value]['value']);
         let queryDataMapping: QueryDataMapping = { 
            variable: triple.object.value, 
            predicate: this.createLink(triple.predicate.value),
            value: result[triple.object.value]['value'], 
            links: (link != null) ? [ link ] : [],
            isOptional: isOptional
         }
         dataMapping.queryDataMappings.push(queryDataMapping);
      } else if (!variables.includes(triple.object.value) && !isOptional && triple.subject.value == ontology.iri) {
         dataMapping.subject = ontology.prefix;
         dataMapping.subjectType = this.createLink(triple.object.value);
      }
   }

   private createLink(namedNode: string): Link {
      let link: Link = null;
      this.namespaces.forEach(namespace =>{
         if (namedNode.startsWith(namespace.iri)){
            let newValue = namedNode.replace(namespace.iri, namespace.prefix + ':');
            link = { target: (namespace.isExternal) ? namedNode : newValue, value: newValue, external: namespace.isExternal };
         }
      });
      if (link == null && namedNode.startsWith('http')){
         link = { target: namedNode, value: namedNode, external: true };
      }
      //console.log(namedNode, link);
      return link;
   }
   private replacePrefix(namedNode: string): string {
      this.namespaces.forEach(namespace =>{
         namedNode = namedNode.replace(namespace.iri, namespace.prefix + ':');
      })
      return namedNode;
   }
   public static getQuery(query: string, key: string, iri: string): string {
      let parser = new Parser();
      let sparqlGenerator = new Generator({});
      let parsedQuery = parser.parse(query)
      for (var k = 0; k < parsedQuery.where.length; k++){
         if (parsedQuery.where[k].patterns != undefined){
            for (var j = 0; j < parsedQuery.where[k].patterns.length; j++){
               if (parsedQuery.where[k].patterns[j].triples != undefined) {
                  for (var i = 0; i < parsedQuery.where[k].patterns[j].triples.length; i++){
                     if(parsedQuery.where[k].patterns[j].triples[i]['subject']['value'] == key){
                        parsedQuery.where[k].patterns[j].triples[i]['subject'] = { termType: "NamedNode", value: iri };
                     } else if(parsedQuery.where[k].patterns[j].triples[i]['object']['value'] == key){
                        parsedQuery.where[k].patterns[j].triples[i]['object'] = { termType: "NamedNode", value: iri };
                     } else if(parsedQuery.where[k].patterns[j].triples[i]['predicate']['value'] == key){
                        parsedQuery.where[k].patterns[j].triples[i]['predicate'] = { termType: "NamedNode", value: iri };
                     }
                  }
               }
            }
         } else if (parsedQuery.where[k].triples != undefined){
            for (var i = 0; i < parsedQuery.where[k].triples.length; i++){
               if(parsedQuery.where[k].triples[i]['subject']['value'] == key){
                  parsedQuery.where[k].triples[i]['subject'] = { termType: "NamedNode", value: iri };
               } else if (parsedQuery.where[k].triples[i]['object']['value'] == key){
                  parsedQuery.where[k].triples[i]['object'] = { termType: "NamedNode", value: iri };
               } else if (parsedQuery.where[k].triples[i]['predicate']['value'] == key){
                  parsedQuery.where[k].triples[i]['predicate'] = { termType: "NamedNode", value: iri };
               }
            }
         }
      }
      return sparqlGenerator.stringify(parsedQuery);
   }
}
