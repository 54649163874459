import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TlnQueryServiceInterface} from '../../models';
import { TlnCacheQueryService } from '../../services';
import { DataHandler } from '../../data_handler';
import { TlnTextGeneticOrder} from '../../datatypes/text_version';

@Component({
  selector: 'tln-page-version-view',
  templateUrl: './tln-page-version-view.component.html',
  providers: [ TlnCacheQueryService],
  styleUrls: ['./tln-page-version-view.component.css']
})
export class TlnPageVersionViewComponent implements OnInit {
   /**
    * OPTIONAL pass a queryService with method 
    * {@link /interfaces/TlnQueryServiceInterface.html#getData|getData}
    * to TlnPageViewComponent.
    **/
   @Input() queryService: TlnQueryServiceInterface;
   @Output() textGenesisSelected = new EventEmitter<string>(); 
   @Input() current_iri: string;
   dataHandler: DataHandler = new DataHandler(this);
   geneticOrders: TlnTextGeneticOrder[] = [];


   constructor(private localQueryService: TlnCacheQueryService) { }

   ngOnInit() {
      let tlnQueryService = (this.queryService != null) ? this.queryService : this.localQueryService;
      this.dataHandler.addHandler('geneticOrders', { 'handler': TlnTextGeneticOrder});
      this.dataHandler.setQueryService(tlnQueryService);
      this.dataHandler.getData('geneticOrders', this.current_iri);
   }

}
