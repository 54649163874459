import { BasicResultBindingElement, FusekiResults } from './basic_datatype';
import { Manuscript, Page, Representation } from '../models';
import { TlnPositionalStyleMarkup } from './positional-markup';
import { PageStub, TlnPage } from './page';

export class ManuscriptStub extends BasicResultBindingElement implements Manuscript, Representation {
   readonly openInTarget: string = 'manuscript';
   static readonly query: string = `
   PREFIX data: <http://rdfh.ch/projects/0068#>
   PREFIX tln: <http://www.nie.org/ontology/nietzsche#>

   SELECT ?id ?title ?type WHERE {
      ?id a tln:ArchivalManuscriptUnity ;
      tln:hasTitle ?title;
      tln:hasManuscriptType ?type.
   }`;
   title: string;
   type: string;
   representation: string;

   constructor (data: any, id?: string, service?: any) {
      super(data, id, service);
      this.title = this.getData4Key('title'); 
      this.type =  this.getData4Key('type');
      this.representation = this.title;
   }
   public getRouterInformation(launchExternally: boolean): Object {
      return { title: this.title, manuscript: this.id, launchExternally: launchExternally }
   }
}
export class ManuscriptLeaf extends BasicResultBindingElement {
   static readonly default_key: string = 'manuscript';
   static readonly query_key: string = 'manuscript';
   static readonly query: string = `
   PREFIX data: <http://rdfh.ch/projects/0068#>
   PREFIX tln: <http://www.nie.org/ontology/nietzsche#>
   PREFIX rdf: <http://www.w3.org/1999/02/22-rdf-syntax-ns#>

   SELECT distinct ?id ?description ?originally WHERE {
      ?manuscript a tln:ArchivalManuscriptUnity ;
      tln:hasPages/rdf:rest*/rdf:first ?page.
      ?page tln:hasFaksimileImage ?image.
      ?image ^tln:isDepictedBy ?id.
      ?id tln:leafHasDescription ?descriptionId.
      ?descriptionId tln:textHasContent ?description.
      OPTIONAL { ?descriptionId tln:leafHasDescriptionAboutOriginalConstitution ?originally }
   }`;
   description: string;
   originally?: string;
   constructor (data: any, id?: string, service?: any) {
      super(data, id, service);
      this.description = this.getData4Key('description'); 
      this.originally = this.getData4Key('originally'); 
   }

}
export class ManuscriptInfoLeaf extends ManuscriptLeaf {
   static readonly default_key: string = 'page';
   static readonly query_key: string = 'page';
   static readonly query: string = `
   PREFIX tln: <http://www.nie.org/ontology/nietzsche#>
   PREFIX rdf: <http://www.w3.org/1999/02/22-rdf-syntax-ns#>

   SELECT distinct ?id ?description ?originally WHERE {
      ?page tln:hasFaksimileImage ?image.
      ?image ^tln:isDepictedBy ?id.
      ?id tln:leafHasDescription ?descriptionId.
      ?descriptionId tln:textHasContent ?description.
      OPTIONAL { ?descriptionId tln:leafHasDescriptionAboutOriginalConstitution ?originally }
   }`;
   
}

export class TlnExtManuscript extends ManuscriptStub {
   /** 
    * the internal default key for replacing {@link /classes/TlnLine.html#query|query} by "id"
    * in {@link /classes/TlnLine.html#getQuery|getQuery} if "key" is omitted.
    **/
   static readonly default_key: string = 'manuscript';
   static readonly query: string = `
   PREFIX data: <http://rdfh.ch/projects/0068#>
   PREFIX tln: <http://www.nie.org/ontology/nietzsche#>
   PREFIX rdf: <http://www.w3.org/1999/02/22-rdf-syntax-ns#>
   PREFIX stoff: <http://www.nie.org/ontology/standoff#>

   SELECT DISTINCT ?gsaSignature ?title ?thumbImage ?type ?archivalicSignature WHERE {
      ?manuscript  tln:hasPages/rdf:first/tln:hasFaksimileImage/tln:hasThumburl ?thumbImage;
                   tln:hasGsaSignature ?gsaSignature;
                   tln:hasTitle ?title;
                   tln:hasManuscriptType ?type.  
      OPTIONAL {
        ?manuscript tln:hasArchivalicSignature ?archivalicSignature. 
      }
   }`;
   /** 
    * the public key for replacing {@link /classes/TlnLine.html#query|query} by "id".
    **/
   static readonly query_key: string = 'manuscript';
   thumbImage: string;
   gsaSignature?: string;
   archivalicSignature?: string;

   constructor (data: any, id?: string, service?: any) {
      super(data, id, service);
      this.thumbImage = this.getData4Key('thumbImage');
      this.gsaSignature = this.getData4Key('gsaSignature');
      this.archivalicSignature = this.getData4Key('archivalicSignature');
   }
}
export class Manuscript4Selection extends ManuscriptStub {
   static readonly query: string = `
   PREFIX data: <http://rdfh.ch/projects/0068#>
   PREFIX tln: <http://www.nie.org/ontology/nietzsche#>
   PREFIX rdf: <http://www.w3.org/1999/02/22-rdf-syntax-ns#>
   PREFIX stoff: <http://www.nie.org/ontology/standoff#>

   SELECT DISTINCT ?id ?title ?type WHERE {
      ?id a tln:ArchivalManuscriptUnity; 
         tln:hasTitle ?title;
         tln:archivalManuscriptUnityHasDataProcessingStatus ?status;
         tln:hasManuscriptType ?type.
      FILTER (?status != "blank")
   }`;
}
export class ManuscriptPages extends ManuscriptStub {
   /** 
    * the internal default key for replacing {@link /classes/TlnLine.html#query|query} by "id"
    * in {@link /classes/TlnLine.html#getQuery|getQuery} if "key" is omitted.
    **/
   static readonly default_key: string = 'id';
   static readonly query: string = `
   PREFIX data: <http://rdfh.ch/projects/0068#>
   PREFIX tln: <http://www.nie.org/ontology/nietzsche#>
   PREFIX rdf: <http://www.w3.org/1999/02/22-rdf-syntax-ns#>
   PREFIX stoff: <http://www.nie.org/ontology/standoff#>

   SELECT DISTINCT ?id ?title ?type ?page ?number WHERE {
      ?id a tln:ArchivalManuscriptUnity; 
         tln:hasTitle ?title;
         tln:hasManuscriptType ?type;
         tln:hasPages/rdf:rest*/rdf:first ?page.  
      ?page tln:hasNumber ?number.
      FILTER (?type = "Mappe")
   }`;
   /** 
    * the public key for replacing {@link /classes/TlnLine.html#query|query} by "id".
    **/
   static readonly query_key: string = 'id';
   pages: Page[] = [];

   constructor (data: any, id?: string, service?: any) {
      super(data, id, service);
   }
   public static convertData<T extends typeof BasicResultBindingElement>(this: T, data: FusekiResults, id?: string, service?: any): Array<InstanceType<T>> {
      let elements = []; 
      let content = this.getContent(data);
      for (var i = 0; i < content.length; i++){
         let element = new ManuscriptPages(content[i], id, service);
         let pages = [];
         if (content[i]['page'] != undefined && content[i]['page'] != null){
            pages = (content[i]['title'] != undefined && content[i]['title'] != null) 
               ?  TlnPage.convertData({ head: { vars: []}, results: { bindings: <any>[ content[i] ] } }, content[i]['page']['value']) 
               :  PageStub.convertData({ head: { vars: []}, results: { bindings: <any>[ content[i] ] } }, content[i]['page']['value']);
         }
         if (elements.length > 0 && elements[elements.length-1].id == element.id){
            if (pages.length > 0){
               elements[elements.length-1].pages.push(pages[0]);
            }
         } else {
            if (pages.length > 0){
               element.pages.push(pages[0]);
            }
            elements.push(element)
         }
      }
      //console.log(elements)
      return elements;
   }

}
export class ReconstructedKonvolut extends ManuscriptStub {
   /** 
    * the internal default key for replacing {@link /classes/TlnLine.html#query|query} by "id"
    * in {@link /classes/TlnLine.html#getQuery|getQuery} if "key" is omitted.
    **/
   static readonly default_key: string = 'manuscript';
   static readonly query: string = `
   PREFIX data: <http://rdfh.ch/projects/0068#>
   PREFIX tln: <http://www.nie.org/ontology/nietzsche#>
   PREFIX rdf: <http://www.w3.org/1999/02/22-rdf-syntax-ns#>
   PREFIX stoff: <http://www.nie.org/ontology/standoff#>

   SELECT DISTINCT ?id ?manuscriptTitle ?title ?type ?page ?number ?description WHERE {
      ?manuscript  tln:partsBelongToReconstructedKonvolut ?id. 
      ?id tln:hasTitle ?manuscriptTitle;
          tln:hasDescription/tln:textHasContent ?description;
          tln:hasManuscriptType ?type;
          tln:hasPages/rdf:rest*/rdf:first ?page.  
      OPTIONAL { ?page tln:hasNumber ?number.}
      OPTIONAL {  
         ?archivalicUnity a tln:ArchivalManuscriptUnity;
               tln:hasPages/rdf:rest*/rdf:first ?page;
               tln:hasTitle ?title.
      }
   }`;
   /** 
    * the public key for replacing {@link /classes/TlnLine.html#query|query} by "id".
    **/
   static readonly query_key: string = 'manuscript';
   pages: Page[] = [];
   description: string;

   constructor (data: any, id?: string, service?: any) {
      super(data, id, service);
      this.title = this.getData4Key('manuscriptTitle');
      this.description = this.getData4Key('description');
   }
   public static convertData<T extends typeof BasicResultBindingElement>(this: T, data: FusekiResults, id?: string, service?: any): Array<InstanceType<T>> {
      let elements = []; 
      let content = this.getContent(data);
      for (var i = 0; i < content.length; i++){
         let element = new ReconstructedKonvolut(content[i], id, service);
         let pages = [];
         if (content[i]['page'] != undefined && content[i]['page'] != null){
            pages = (content[i]['title'] != undefined && content[i]['title'] != null) 
               ?  TlnPage.convertData({ head: { vars: []}, results: { bindings: <any>[ content[i] ] } }, content[i]['page']['value']) 
               :  PageStub.convertData({ head: { vars: []}, results: { bindings: <any>[ content[i] ] } }, content[i]['page']['value']);
         }
         if (elements.length > 0 && elements[elements.length-1].id == element.id){
            if (pages.length > 0){
               elements[elements.length-1].pages.push(pages[0]);
            }
         } else {
            if (pages.length > 0){
               element.pages.push(pages[0]);
            }
            elements.push(element)
         }
      }
      //console.log(elements)
      return elements;
   }

}
export class ManuscriptDescription extends BasicResultBindingElement {
   /** 
    * the internal default key for replacing {@link /classes/TlnLine.html#query|query} by "id"
    * in {@link /classes/TlnLine.html#getQuery|getQuery} if "key" is omitted.
    **/
   static readonly default_key: string = 'manuscript';
   static readonly query: string = `
   PREFIX data: <http://rdfh.ch/projects/0068#>
   PREFIX tln: <http://www.nie.org/ontology/nietzsche#>
   PREFIX rdf: <http://www.w3.org/1999/02/22-rdf-syntax-ns#>
   PREFIX stoff: <http://www.nie.org/ontology/standoff#>

   SELECT DISTINCT ?id ?text ?description_markup ?sStyle ?start ?end WHERE {
      ?manuscript  tln:hasDescription ?id.
      ?id tln:textHasContent ?text.
   OPTIONAL { ?id tln:textHasMarkup ?description_markup.
            ?description_markup stoff:hasCSS ?sStyle;
                          stoff:standoffMarkupHasStartIndex ?start;
                          stoff:standoffMarkupHasEndIndex ?end.}
   }`;
   /** 
    * the public key for replacing {@link /classes/TlnLine.html#query|query} by "id".
    **/
   static readonly query_key: string = 'manuscript';
   text: string;
   markups: TlnPositionalStyleMarkup[];

   constructor (data: any, id?: string, service?: any) {
      super(data, id, service);
      this.text = this.getData4Key('text');
      this.markups = [];
   }
   public static convertData<T extends typeof BasicResultBindingElement>(this: T, data: FusekiResults, id?: string, service?: any): Array<InstanceType<T>> {
      let elements = []; 
      let content = this.getContent(data);
      for (var i = 0; i < content.length; i++){
         let element = new ManuscriptDescription(content[i], id, service);
         let markups = (content[i]['description_markup'] != undefined && content[i]['description_markup'] != null) ? 
            TlnPositionalStyleMarkup.convertData({ head: { vars: []}, results: { bindings: <any>[ content[i] ] } }, content[i]['description_markup']['value']) : [];
         if (elements.length > 0 && elements[elements.length-1].id == element.id){
            if (markups.length > 0){
               elements[elements.length-1].markups.push(markups[0]);
            }
         } else {
            if (markups.length > 0){
               element.markups.push(markups[0]);
            }
            elements.push(element)
         }
      }
      //console.log(elements)
      return elements;
   }
}
export class ManuscriptEarlierDescription  extends BasicResultBindingElement {
   /** 
    * the internal default key for replacing {@link /classes/TlnLine.html#query|query} by "id"
    * in {@link /classes/TlnLine.html#getQuery|getQuery} if "key" is omitted.
    **/
   static readonly default_key: string = 'manuscript';
   static readonly query: string = `
   PREFIX data: <http://rdfh.ch/projects/0068#>
   PREFIX tln: <http://www.nie.org/ontology/nietzsche#>
   PREFIX stoff: <http://www.nie.org/ontology/standoff#>

   SELECT ?id ?text ?author ?citation ?description_markup ?sStyle ?start ?end WHERE {
      ?manuscript  tln:hasEarlierDescriptions ?id.  
      ?id tln:textHasContent ?text;
               tln:hasAuthor ?author;
               tln:hasCitation ?citation.
   OPTIONAL { ?id tln:textHasMarkup ?description_markup.
            ?description_markup stoff:hasCSS ?sStyle;
                          stoff:standoffMarkupHasStartIndex ?start;
                          stoff:standoffMarkupHasEndIndex ?end.}
   }`;
   /** 
    * the public key for replacing {@link /classes/TlnLine.html#query|query} by "id".
    **/
   static readonly query_key: string = 'manuscript';
   text: string;
   author: string;
   citation: string;
   markups: TlnPositionalStyleMarkup[];

   constructor (data: any, id?: string, service?: any) {
      super(data, id, service);
      this.text = this.getData4Key('text');
      this.author = this.getData4Key('author');
      this.citation = this.getData4Key('citation');
      this.markups = [];
   }
   public static convertData<T extends typeof BasicResultBindingElement>(this: T, data: FusekiResults, id?: string, service?: any): Array<InstanceType<T>> {
      let elements = []; 
      let content = this.getContent(data);
      for (var i = 0; i < content.length; i++){
         let element = new ManuscriptEarlierDescription(content[i], id, service);
         let markups = (content[i]['description_markup'] != undefined && content[i]['description_markup'] != null) ? 
            TlnPositionalStyleMarkup.convertData({ head: { vars: []}, results: { bindings: <any>[ content[i] ] } }, content[i]['description_markup']['value']) : [];
         if (elements.length > 0 && elements[elements.length-1].id == element.id){
            if (markups.length > 0){
               elements[elements.length-1].markups.push(markups[0]);
            }
         } else {
            if (markups.length > 0){
               element.markups.push(markups[0]);
            }
            elements.push(element)
         }
      }
      //console.log(elements)
      return elements;
   }
}
