import { BasicResultBindingElement, FusekiResults } from './basic_datatype';
import { EditorComment, Line, LineContinuationReference, Word } from '../models';
import { TlnEditorCommentMarkup } from './positional-markup';
import { TlnLine } from './line';
/**
 * This is the 'editor comment' of page instantiation of an element of {@link /interfaces/FusekiResults.html|FusekiResults}.
 **/
export class TlnPageEditorCommentStub extends BasicResultBindingElement implements EditorComment {
   static readonly default_key: string = 'page';
   static readonly query_key: string = 'page';
   /** 
    * the SPARQL-query of this datatype.
    **/
   static readonly query: string = `
   PREFIX tln: <http://www.nie.org/ontology/nietzsche#>
   PREFIX rdf: <http://www.w3.org/1999/02/22-rdf-syntax-ns#>
   PREFIX rdfs: <http://www.w3.org/2000/01/rdf-schema#>

   SELECT ?id ?editorClass ?comment ?isUncertain WHERE {
      ?page a tln:Page; tln:hasEditorComments/rdf:rest*/rdf:first ?id.
      ?id a ?editorClass;
          tln:hasComment ?comment;
          tln:isUncertain ?isUncertain.
      FILTER (?editorClass != rdfs:Resource)
   }`;
   editorClass: string;
   comment?: string;
   isUncertain: boolean;
    /**
    * The constructor creates a datatype from the data.
    *
    * @param id if omitted the id will be retrieved from data
    **/
   constructor(data: any, id?: string, service?: any){
      super(data, id, service)
      this.editorClass = this.getData4Key('editorClass');
      this.isUncertain = this.getData4Key('isUncertain');
      this.comment = this.getData4Key('comment');
   }
}

/**
 * This is the 'editor comment' stub instantiation of an element of {@link /interfaces/FusekiResults.html|FusekiResults}.
 **/
export class TlnEditorCommentStub extends TlnPageEditorCommentStub  {
   static readonly default_key: string = 'word';
   static readonly query_key: string = 'word';
   /** 
    * the SPARQL-query of this datatype.
    **/
   static readonly query: string = `
   PREFIX tln: <http://www.nie.org/ontology/nietzsche#>
   PREFIX rdf: <http://www.w3.org/1999/02/22-rdf-syntax-ns#>
   PREFIX rdfs: <http://www.w3.org/2000/01/rdf-schema#>

   SELECT ?id ?editorClass ?comment ?correction ?text ?textWithMarkup ?isUncertain WHERE {
     { ?word tln:wordHasEditorComment ?id. } UNION {
       ?word tln:wordHasWordParts/rdf:rest*/rdf:first/tln:wordHasEditorComment ?id.
     }
     ?id a ?editorClass;
      tln:isUncertain ?isUncertain.
     FILTER (?editorClass != rdfs:Resource)
     OPTIONAL { ?id tln:hasComment ?comment.}
     OPTIONAL { ?id tln:hasCorrectionText ?correction;
                  tln:isUncertain true.}
     OPTIONAL { ?id tln:commentHasText ?textWithMarkup.
                ?textWithMarkup tln:textHasContent ?text.
     }
   }`;
   editorClass: string;
   comment?: string;
   correction?: string;
   isUncertain: boolean;
   text?: string;
   textWithMarkup?: string;
    /**
    * The constructor creates a datatype from the data.
    *
    * @param id if omitted the id will be retrieved from data
    **/
   constructor(data: any, id?: string, service?: any){
      super(data, id, service)
      this.editorClass = this.getData4Key('editorClass');
      this.isUncertain = this.getData4Key('isUncertain');
      this.comment = this.getData4Key('comment');
      this.correction = this.getData4Key('correction');
      this.text = this.getData4Key('text');
      this.textWithMarkup = this.getData4Key('textWithMarkup');
   }
}
export class TlnLineEditorComment extends TlnEditorCommentStub {
   static readonly default_key: string = 'page';
   static readonly query_key: string = 'page';
   /** 
    * the SPARQL-query of this datatype.
    **/
   static readonly query: string = `
   PREFIX tln: <http://www.nie.org/ontology/nietzsche#>
   PREFIX stoff: <http://www.nie.org/ontology/standoff#>
   PREFIX rdf: <http://www.w3.org/1999/02/22-rdf-syntax-ns#>
   PREFIX rdfs: <http://www.w3.org/2000/01/rdf-schema#>

   SELECT DISTINCT ?id ?line ?number ?top ?bottom ?editorClass ?comment ?reference ?isToRef ?refTitle ?refPageNumber ?refLine ?isUncertain WHERE {
     ?page tln:hasLines/rdf:rest*/rdf:first ?line.
     ?line tln:lineHasEditorComment ?id;
           tln:lineHasNumber ?number;
           tln:lineHasTopValueOnTranskription ?top;
           tln:lineHasBottomValueOnTranskription ?bottom.
     ?id a ?editorClass;
      tln:isUncertain ?isUncertain.
     FILTER (!isBlank(?editorClass) && ?editorClass != rdfs:Resource)
     OPTIONAL { ?id tln:hasComment ?comment.}
     OPTIONAL { ?id tln:lineContinuationHasReference ?reference;
                    tln:isLineAContinuationTo ?isToRef.
                ?reference tln:hasPageNumber ?refPageNumber;
                           tln:firstLineOfReference ?refLine.
                OPTIONAL { ?reference tln:hasTitle ?refTitle }
     }
   }`;
   lineContinuationReference?: LineContinuationReference;
   tLine: Line;
    /**
    * The constructor creates a datatype from the data.
    *
    * @param id if omitted the id will be retrieved from data
    **/
   constructor(data: any, id?: string, service?: any){
      super(data, id, service)
      this.tLine = {
         id: this.getData4Key('line'),
         number: this.getData4Key('number'),
         bottom: this.getData4Key('bottom'),
         top: this.getData4Key('top')
      }
      const reference = this.getData4Key('reference');
      if (reference != undefined && reference != null){
         this.lineContinuationReference = {
            isToRef: this.getData4Key('isToRef'),
            refTitle: this.getData4Key('refTitle'),
            refPageNumber: this.getData4Key('refPageNumber'),
            refLine: this.getData4Key('refLine')
         }
      }
   }
   public static convertData<T extends typeof BasicResultBindingElement>(this: T, data: FusekiResults, id?: string, service?: any): Array<InstanceType<T>> {
      let elements = []; 
      let content = this.getContent(data);
      for (var i = 0; i < content.length; i++){
         let element = new TlnLineEditorComment(content[i], id, service);
         if (elements.length > 0 && elements[elements.length-1].id == element.id){
            if (elements[elements.length-1].editorClass.endsWith('EditorComment')){
               elements[elements.length-1].editorClass = element.editorClass;
            }
         } else {
            elements.push(element)
         }
      }
      return elements;
   }
}
export class TlnEditorComment extends TlnLineEditorComment {
   static readonly default_key: string = 'page';
   static readonly query_key: string = 'page';
   /** 
    * the SPARQL-query of this datatype.
    **/
   static readonly query: string = `
   PREFIX tln: <http://www.nie.org/ontology/nietzsche#>
   PREFIX stoff: <http://www.nie.org/ontology/standoff#>
   PREFIX rdf: <http://www.w3.org/1999/02/22-rdf-syntax-ns#>
   PREFIX rdfs: <http://www.w3.org/2000/01/rdf-schema#>

   SELECT DISTINCT ?id ?word ?line ?text ?number ?top ?bottom ?editorClass ?comment ?commentIsUncertain ?correction ?markup ?sStyle ?start ?end ?isUncertain WHERE {
     ?page tln:hasWords/rdf:rest*/rdf:first ?word.
     { ?word tln:wordHasEditorComment ?id. } UNION {
       ?word tln:wordHasWordParts/rdf:rest*/rdf:first/tln:wordHasEditorComment ?id.
     }
     ?id a ?editorClass;
         tln:isUncertain ?isUncertain.
     FILTER (?editorClass != rdfs:Resource)
     ?word tln:hasText ?wordText;
           tln:wordBelongsToLine ?line.
     ?line tln:lineHasNumber ?number;
           tln:lineHasTopValueOnTranskription ?top;
           tln:lineHasBottomValueOnTranskription ?bottom.
     OPTIONAL { ?id tln:hasComment ?comment.}
     OPTIONAL { ?word tln:hasEditedText ?editedText.}
     OPTIONAL { ?id tln:hasCorrectionText ?editorCorrection;
                  tln:isUncertain true.}
     OPTIONAL { ?id tln:commentHasText ?textWithMarkup.
                ?textWithMarkup tln:textHasContent ?contentText;
                                tln:textHasMarkup ?markup.
                ?markup stoff:hasCSS ?sStyle;
                        stoff:standoffMarkupHasStartIndex ?start;
                        stoff:standoffMarkupHasEndIndex ?end.
     }
     BIND(IF(BOUND(?contentText), ?contentText, ?wordText) AS ?text)
     BIND(IF(BOUND(?editorCorrection), ?editorCorrection, ?editedText) AS ?correction)
   }`;
   underlineLine: boolean = false;
   word: Word;
   markups: TlnEditorCommentMarkup[] = [];
    /**
    * The constructor creates a datatype from the data.
    *
    * @param id if omitted the id will be retrieved from data
    **/
   constructor(data: any, id?: string, service?: any){
      super(data, id, service)
      this.word = {
         id: this.getData4Key('word'),
         text: this.getData4Key('text'),
         line: (this.tLine != null) ? this.tLine.id : null,
         line_number: (this.tLine != null) ? this.tLine.number: -1,
         deleted: false,
         left: -1,
         top: -1,
         width: -1,
         height: -1
      }
   }
   public static convertData<T extends typeof BasicResultBindingElement>(this: T, data: FusekiResults, id?: string, service?: any): Array<InstanceType<T>> {
      let elements = []; 
      let content = this.getContent(data);
      for (var i = 0; i < content.length; i++){
         let element = <unknown>(new this(content[i], id, service)) as EditorComment;
         element.markups = (content[i]['markup'] != undefined) ? 
            TlnEditorCommentMarkup.convertData({ head: { vars: []}, results: { bindings: <any>[ content[i] ] } }) : [];
         if (elements.length > 0 && elements[elements.length-1].id == element.id){
            if(elements[elements.length-1].editorClass == element.editorClass){
               elements[elements.length-1].markups = elements[elements.length-1].markups.concat(element.markups);
            } else if (elements[elements.length-1].editorClass.endsWith('EditorComment')){
               elements[elements.length-1].editorClass = element.editorClass;
            }
         } else {
            elements.push(element)
         }
      }
      return elements;
   }

}
export class TlnOverwrittenWordEditorComment extends TlnEditorComment {
   /** 
    * the SPARQL-query of this datatype.
    **/
   static readonly query: string = `
   PREFIX tln: <http://www.nie.org/ontology/nietzsche#>
   PREFIX stoff: <http://www.nie.org/ontology/standoff#>
   PREFIX rdf: <http://www.w3.org/1999/02/22-rdf-syntax-ns#>
   PREFIX rdfs: <http://www.w3.org/2000/01/rdf-schema#>

   SELECT DISTINCT ?id ?word ?line ?text ?overwritten_text ?number ?top ?bottom ?editorClass ?comment ?commentIsUncertain ?correction ?markup ?sStyle ?start ?end ?isUncertain WHERE {
     ?page tln:hasWords/rdf:rest*/rdf:first ?word.
     ?word tln:overwritesWord ?overwrittenWord.
     ?overwrittenWord tln:wordHasEditorComment ?id;
           tln:hasText ?wordText. 
     ?id a ?editorClass;
         tln:isUncertain ?isUncertain.
     FILTER (?editorClass != rdfs:Resource)
     ?word tln:wordBelongsToLine ?line;
           tln:hasText ?text.
     ?line tln:lineHasNumber ?number;
           tln:lineHasTopValueOnTranskription ?top;
           tln:lineHasBottomValueOnTranskription ?bottom.
     OPTIONAL { ?id tln:hasComment ?comment.}
     OPTIONAL { ?id tln:hasCorrectionText ?correction;
                  tln:isUncertain true.}
     OPTIONAL { ?word tln:hasStandoffMarkup4PartThatOverwritesWord ?markup.
                ?markup stoff:hasCSS ?sStyle;
                        stoff:standoffMarkupHasStartIndex ?start;
                        stoff:standoffMarkupHasEndIndex ?end.
     }
     BIND(IF(BOUND(?contentText), ?contentText, ?wordText) AS ?overwritten_text)
   }`;
   underlineLine: boolean = true;
   overwritten_text: string;
    /**
    * The constructor creates a datatype from the data.
    *
    * @param id if omitted the id will be retrieved from data
    **/
   constructor(data: any, id?: string, service?: any){
      super(data, id, service)
      this.overwritten_text = this.getData4Key('overwritten_text');
   }
}
