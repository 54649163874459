import {SafeUrl} from '@angular/platform-browser';

export class TlnEntity {
  id: string; // Short id, iri in most cases
  iri: string; // iri
  type: string; // rdfs:type
  contextView: string;
  label?: string;
  description?: string;

  constructor(id: string, iri: string, type: string, contextView: string, label?: string, description?: string) {
    this.id = id;
    this.iri = iri;
    this.type = type;
    this.contextView = contextView;
    this.label = label;
    this.description = description;
  }
}

// The navigation entries in each tree for each viewtab (TlnManuscript, TlnPage, PositionalEntity) used by navigation
//
export class NavigationEntity {
  idx: number;
  tlnEntity: TlnEntity;
  thumb?: SafeUrl; // url of image (Thumbnail)
  img?: SafeUrl; // full image url
  svg?: SafeUrl; // svg url
  avatar?: SafeUrl; // img for avatar

  constructor(idx: number, tlnEntity: TlnEntity, thumb?: string, img?, svg?: SafeUrl, avatar?: SafeUrl) {
    this.idx = idx;
    this.tlnEntity = tlnEntity;
    this.thumb = thumb;
    this.img = img;
    this.svg = svg;
    this.avatar = avatar;
  }
}

export interface NavTreeDef {
  id: string; // name of the tree
  idx: number; // order
  label: string;
  itemQParam: string;
  entries: NavigationEntity[];
  description?: string;
  apiDef?: ApiDef;
  isActive?: boolean;
}

export class ApiDef {
  type: number; // 0 === sparql 1.1., 1 === nietzscheSource API, 2 === existDB
  baseUrl: string;
  dataArray: string;
  query?: string;
  mapping?: TlnEntityMapping; // positive Filter ids: only these id's should be loaded into manuscript nav tree
  paramTriple?: number; // Select which triple of the where clause will be parametrized: 1 === property, 2 === object; default === subject

  constructor( type: number, baseUrl: string, dataArray: string, query?: string, mapping?: TlnEntityMapping, paramTriple?) {
    this.type = type;
    this.baseUrl = baseUrl;
    this.dataArray = dataArray;
    this.query = query;
    this.mapping = mapping;
    this.paramTriple = paramTriple;

  }
}

export class TlnEntityMapping { // Is used for mapping the response from any given apiDef/response to a TlnEntity Instance
  id: string; // Short id, iri in most cases
  iri?: string; // iri
  idx?: string;
  type?: string; // rdfs:type
  label?: string;
  description?: string;
  avatar?: string;
  svg?: string;
  thumb?: string;

  constructor(id: string, iri?: string, idx?: string, type?: string, label?: string, description?: string, avatar?: string, svg?: string, thumb?: string) {
    this.id = id;
    this.iri = iri;
    this.idx = idx;
    this.type = type;
    this.label = label;
    this.description = description;
    this.avatar = avatar;
    this.svg = svg;
    this.thumb = thumb;
  }
}
