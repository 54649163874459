import { Component, OnInit } from '@angular/core';
import { Params } from '@angular/router';
import { CONTENT_VIEW_ROUTE } from './constants';
import { TLN_MANUSCRIPT_ROUTE, TLN_MANUSCRIPT_PARAM } from './tln-edition/constants';


@Component({
  selector: 'app-home-component',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
   manuscriptLink: string[] = [ '/' + CONTENT_VIEW_ROUTE, TLN_MANUSCRIPT_ROUTE ];
   mpXIVParam: Params = {}
   mpXVParam: Params = {}
   ngOnInit(){
      this.mpXIVParam[TLN_MANUSCRIPT_PARAM] = 'http://rdfh.ch/projects/0068#_Mp_XIV';
      this.mpXVParam[TLN_MANUSCRIPT_PARAM] = 'http://rdfh.ch/projects/0068#_Mp_XV';
   }
}


