import { Component, Input, OnInit } from '@angular/core';
import { MarkupSettings, StandoffMarkup } from 'ngx-mat-standoff-markup';
import { EditorComment } from '../models';
import { WordWithLine } from '../datatypes/word';
import { DataHandler } from '../data_handler';
import { TlnCacheQueryService } from '../services';
import { TlnEditorCommentMarkup } from '../datatypes/positional-markup';

@Component({
  selector: 'editor-comment',
  templateUrl: './editor-comment.component.html',
  styleUrls: ['./editor-comment.component.css']
})
export class EditorCommentComponent implements OnInit {
   @Input() editor_comment: EditorComment;
   editorType: string;
   dataHandler: DataHandler = new DataHandler(this);
   markups: StandoffMarkup[] = [];
   word: WordWithLine;
   mySettings: MarkupSettings = new MarkupSettings();

  constructor(private tlnQueryService: TlnCacheQueryService) { }

  ngOnInit() {
     if (this.editor_comment != undefined && this.editor_comment != null && this.editor_comment.editorClass != null && this.editor_comment.editorClass.split('#').length > 1){
        this.dataHandler.setQueryService(this.tlnQueryService);
        if (this.editor_comment.editorClass.split('#')[1] != 'EditorComment'){ 
            this.editorType = this.editor_comment.editorClass.split('#')[1];
        }
        /*if (this.editor_comment.word != undefined && this.editor_comment.word != null){
           this.dataHandler.addHandler('word', { 'handler': WordWithLine});
           this.dataHandler.getData('word', this.editor_comment.word);
        }*/
        if (this.editor_comment.textWithMarkup != undefined && this.editor_comment.textWithMarkup != null){
           this.dataHandler.addHandler('markups', { 'handler': TlnEditorCommentMarkup });
           this.tlnQueryService.reset_data.subscribe(
               (data_key: string) =>{
                  if (data_key == 'page_content'){
                     this.markups = [];
                  }
           });
           this.dataHandler.getData('markups', this.editor_comment.textWithMarkup);
        }
    }     
  }
}
