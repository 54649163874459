import { Component, Input, OnInit } from '@angular/core';
import { DataMapping } from './models';

@Component({
  selector: 'ontology-display',
  templateUrl: './ontology-display.component.html',
  styleUrls: ['./ontology-display.component.css']
})
export class OntologyDisplayComponent implements OnInit {
   @Input() dataMappings: DataMapping[];

  constructor() { }

  ngOnInit() {
  }

}
