import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Content } from './models';

@Component({
  selector: 'menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {
  @Input() titles: Content[] = []
  queryParams: Params;

   constructor(private activatedRoute: ActivatedRoute ) { }

  ngOnInit() {
     this.activatedRoute.queryParams.subscribe( (queryParams: Params ) => {
         this.queryParams = queryParams;
     });

  }

}
