export {HIGHTLIGHT_CASES} from '../page-view/highlight_status';

export enum DATA_PROCESSING_STATUS {
   COMPLETE = 'complete',
   MIXED =  'mixed',
   BLANK = 'blank'
}

export enum VIEW_OPTIONS {
   TRANSKRIPTION = 'Transkription',
   FAKSIMILE = 'Faksimile',
   SYNOPSIS = 'Transkription/Faksimile',
   SYNOPSIS_B = 'Faksimile/Transkription'
}
export const DEFAULT_VIEW_OPTION: string = VIEW_OPTIONS.SYNOPSIS_B;
export const BASE_URL: string = 'https://nietzsche.fuseki.services.dasch.swiss/nietzsche'
export const ONTOLOTY_PREFIX: string = 'http://www.nie.org/ontology/nietzsche#'
export const DATA_PREFIX: string = 'http://rdfh.ch/projects/0068#'
/**
 * Route for TlnCrossrefComponent
 **/
export const TLN_CROSSREF_ROUTE: string = 'tln-crossref';
/**
 * Route for TlnCrossrefComponent
 **/
export const TLN_CROSSREF_EDITOR_ROUTE: string = 'tln-crossref-editor';
/**
 * Route for TlnFulltextComponent
 **/
export const TLN_SEARCH_ROUTE: string = 'tln-search';
/**
 * Route for TlnManuscriptViewComponent
 **/
export const TLN_MANUSCRIPT_ROUTE: string = 'tln-manuscript';
/**
 * Route for TlnQuantComponent
 **/
export const TLN_QUANT_ROUTE: string = 'tln-quant';
/**
 * Route for TlnViewerComponent
 **/
export const TLN_VIEWER_ROUTE: string = 'tln-viewer';
/**
 * Param that refers to the context that should be shown, i.e. 'page' or 'manuscript'.
 **/
export const TLN_CONTEXT_VIEW_PARAM: string = 'contextView';
/**
 * Param that toggles fullscreen, value type: boolean.
 **/
export const TLN_FULLSCREEN_PARAM: string = 'fullscreen';
/**
 * Param for find text in page.
 **/
export const TLN_FIND_PARAM: string = 'find';
/**
 * Param for manuscript iri.
 **/
export const TLN_MANUSCRIPT_PARAM: string = 'manuscript';
/**
 * Param for navigation bar open state.
 **/
export const TLN_NAV_BAR_OPEN_STATE_PARAM: string = 'navBarOpenState';
/**
 * Param for page iri.
 **/
export const TLN_PAGE_PARAM: string = 'page';
/**
 * Param for query params, type: string.
 **/
export const TLN_QUERY_PARAM: string = 'query';
/**
 * Param for quant query params, type: complex.
 **/
export const TLN_QUANT_QUERY_PARAM: string = 'quantQuery';
/**
 * Param for result index, type: number.
 **/
export const TLN_QUANT_RESULT_INDEX_PARAM: string = 'quantResultIndex';
/**
 * Param for result index, type: number.
 **/
export const TLN_RESULT_INDEX_PARAM: string = 'resultIndex';
/**
 * Param for rotation value, type: number.
 **/
export const TLN_ROTATION_PARAM: string = 'rotation';
/**
 * Param for search query params, type: complex.
 **/
export const TLN_SEARCH_QUERY_PARAM: string = 'searchQuery';
/**
 * Param for selected lines.
 **/
export const TLN_SELECTED_LINES_PARAM: string = 'selectedLines';
/**
 * Param for selected lines.
 **/
export const TLN_SELECTED_WORDS_PARAM: string = 'selectedWords';
/**
 * Param for iri of a genetic order of text versions.
 **/
export const TLN_TEXT_GENETIC_ORDER_PARAM: string = 'geneticOrder';
/**
 * Param for selected view option, e.g. 'Transkription', 'Faksimile', etc.
 **/
export const TLN_VIEW_OPTION_PARAM: string = 'viewMode';
/**
 * Param for multi instance zoom (i.e. tln-crossref and tln-fulltext).
 **/
export const TLN_MULTI_INSTANCE_ZOOM_PARAM: string = 'multiInstanceZoom';
/**
 * Param for zoom in tln-viewer.
 **/
export const TLN_ZOOM_PARAM: string = 'zoom';
