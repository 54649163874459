import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {ActivatedRoute, Params} from '@angular/router';

@Component({
  selector: 'app-quant',
  templateUrl: './quant.component.html',
  styleUrls: ['./quant.component.scss']
})
export class QuantComponent implements OnInit {
  private readonly leftOffset = 361;
  width: number = 1000;

  constructor(private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
     this.activatedRoute.queryParams.subscribe( (queryParams: Params)  =>{
         this.width = (queryParams.navBarOpenState === 'true') ? window.innerWidth - this.leftOffset : window.innerWidth - 20;
     });
  }

}
