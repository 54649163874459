import { FusekiResults, BasicResultBindingElement } from './basic_datatype';
import { TlnPositionalObject } from './positional_object';
import { TextByForeignHand } from '../models';
import { TlnPositionalStyleMarkup } from './positional-markup';
/**
 * This is the 'text by foreign hand' instantiation of an element of {@link /interfaces/FusekiResults.html|FusekiResults}.
 * It extends {@link /classes/BasicResultBindingElement.html|BasicResultBindingElement}.
 **/
export class TlnTextByForeignHand extends TlnPositionalObject implements TextByForeignHand {
   /** 
    * the SPARQL-query of this datatype.
    **/
   static readonly query: string = `
   PREFIX tln: <http://www.nie.org/ontology/nietzsche#>
   PREFIX rdf: <http://www.w3.org/1999/02/22-rdf-syntax-ns#>

   SELECT ?id ?text ?pen ?left ?top ?width ?height ?resolution ?comment ?transform ?markup ?sStyle ?start ?end 
          ?idOFH ?textOFH ?penOFH ?markupOFH ?startOFH ?endOFH ?sStyleOFH
   WHERE {
      ?page tln:hasMarkForeignHands/rdf:rest*/rdf:first ?id.
      ?id  tln:textOfForeignHands ?textOfForeignHands;
           tln:penOfForeignHands ?pen;
           tln:hasTranskriptionPosition ?tp.
      ?textOfForeignHands tln:textHasContent ?text.
    	?tp tln:hasLeft ?left; tln:hasTop ?top; tln:hasWidth ?width; tln:hasHeight ?height.
      OPTIONAl { ?id tln:resolutionOfAbbreviation ?resolution.}
      OPTIONAl { ?id tln:foreignHandHasCommentByEditors ?comment.}
      OPTIONAl { ?textOfForeignHands  tln:textHasMarkup ?markup.
                 ?markup tln:standoffTagHasEndIndex ?end;
                         tln:standoffTagHasStartIndex ?start;
                         tln:standoffTagHasCSS ?sStyle.
      }
      OPTIONAl { ?tp tln:hasTransform ?transform.}
      OPTIONAL { ?id tln:foreignHandOverwritesForeignHand ?idOFH.
    				?idOFH tln:textOfForeignHands ?textOfForeignHandsOFH;
             			tln:penOfForeignHands ?penOFH.
    				?textOfForeignHandsOFH tln:textHasContent ?textOFH.
               OPTIONAL {
				      ?textOfForeignHandsOFH tln:textHasMarkup ?markupOFH.
       			   ?markupOFH tln:standoffTagHasEndIndex ?endOFH;
                         tln:standoffTagHasStartIndex ?startOFH;
                         tln:standoffTagHasCSS ?sStyleOFH.
    	         }
      }
   } `;
   /**
    * the text of this word
    **/
   text: string;
   /**
    * the text of this word as it has been edited by the editors.
   **/
   pen: string;
   /**
    * resolution of the abbrevation
    **/
   resolution?: string;
   /**
    * editor's comment about this text of foreign hand
    **/
   comment?: string;
   markups: TlnPositionalStyleMarkup[] = [];
   /**
    * overwritten text by foreign hand
    **/
   overwrittenTextByForeignHand?: TextByForeignHand;
    /**
    * The constructor creates a datatype from the data.
    *
    * @param id if omitted the id will be retrieved from data
    **/
   constructor(data: any, id?: string, service?: any){
      super(data, id, service)
      this.text = this.getData4Key('text');
      this.pen = this.getData4Key('pen');
      this.resolution = this.getData4Key('resolution');
      this.comment = this.getData4Key('comment');
   }
   public static convertData<T extends typeof BasicResultBindingElement>(this: T, data: FusekiResults, id?: string, service?: any): Array<InstanceType<T>> {
      let elements = []; 
      let content = this.getContent(data);
      for (var i = 0; i < content.length; i++){
         let element = new TlnTextByForeignHand(content[i], id, service);
         if (content[i]['markup'] != undefined && content[i]['markup']['value'] != null){
            element.markups = TlnPositionalStyleMarkup.convertData({ head: { vars: []}, results: { bindings: <any>[ content[i] ] } }, content[i]['markup']['value'])
         }
         if (content[i]['idOFH'] != undefined && content[i]['idOFH']['value'] != null){
            //let results = Object.fromEntries(Object.entries(content[i]).filter(key_value =>key_value[0].endsWith('OFH')))
            let contentObject = Object.fromEntries(
               Object.entries(content[i]).filter(key_value =>key_value[0].endsWith('OFH')).map(key_value =>[ key_value[0].replace('OFH', ''), key_value[1]])
            );
            let overwritten_mark = TlnTextByForeignHand.convertData({ head: { vars: []}, results: { bindings: <any>[ contentObject ] }}, content[i]['idOFH']['value'])[0]
            if (elements.length > 0 && elements[elements.length-1].id == element.id 
               && elements[elements.length-1].overwrittenTextByForeignHand != undefined && elements[elements.length-1].overwrittenTextByForeignHand != null){
               overwritten_mark.markups.forEach(markup =>{elements[elements.length-1].overwrittenTextByForeignHand.markups.push(markup) });
            } else {
               element.overwrittenTextByForeignHand = overwritten_mark
            }
         }
         if (elements.length > 0 && elements[elements.length-1].id == element.id){
            element.markups.forEach(markup =>{elements[elements.length-1].markups.push(markup)});
            if (element.overwrittenTextByForeignHand != undefined && element.overwrittenTextByForeignHand != null){
               elements[elements.length-1].overwrittenTextByForeignHand = element.overwrittenTextByForeignHand
            }
         } else {
            elements.push(element)
         }
      }
      //console.log(elements)
      return elements;
   }

}
/**
 * This is the faksimile text by foreign hand instantiation of an element of {@link /interfaces/FusekiResults.html|FusekiResults}.
 * It extends {@link /classes/BasicResultBindingElement.html|BasicResultBindingElement}.
 **/
export class FaksimileTextByForeignHand extends TlnTextByForeignHand {
   /** 
    * the SPARQL-query of this datatype.
    **/
    static readonly query: string = `
   PREFIX tln: <http://www.nie.org/ontology/nietzsche#>
   PREFIX rdf: <http://www.w3.org/1999/02/22-rdf-syntax-ns#>

   SELECT ?id ?text ?pen ?left ?top ?width ?height ?resolution ?comment ?transform ?markup ?sStyle ?start ?end
          ?idOFH ?textOFH ?penOFH ?markupOFH ?startOFH ?endOFH ?sStyleOFH
   WHERE {
      ?page tln:hasMarkForeignHands/rdf:rest*/rdf:first ?id.
      ?id  tln:textOfForeignHands ?textOfForeignHands;
           tln:penOfForeignHands ?pen;
           tln:hasFaksimilePosition ?fp.
      ?textOfForeignHands tln:textHasContent ?text.
    	?fp tln:hasLeft ?left; tln:hasTop ?top; tln:hasWidth ?width; tln:hasHeight ?height.
      OPTIONAl { ?id tln:resolutionOfAbbreviation ?resolution.}
      OPTIONAl { ?id tln:foreignHandHasCommentByEditors ?comment.}
      OPTIONAl { ?textOfForeignHands  tln:textHasMarkup ?markup.
                 ?markup tln:standoffTagHasEndIndex ?end;
                         tln:standoffTagHasStartIndex ?start;
                         tln:standoffTagHasCSS ?sStyle.
      }
      OPTIONAl { ?fp tln:hasTransform ?transform.}
      OPTIONAL { ?id tln:foreignHandOverwritesForeignHand ?idOFH.
    				?idOFH tln:textOfForeignHands ?textOfForeignHandsOFH;
             			tln:penOfForeignHands ?penOFH.
    				?textOfForeignHandsOFH tln:textHasContent ?textOFH.
               OPTIONAL {
				      ?textOfForeignHandsOFH tln:textHasMarkup ?markupOFH.
       			   ?markupOFH tln:standoffTagHasEndIndex ?endOFH;
                         tln:standoffTagHasStartIndex ?startOFH;
                         tln:standoffTagHasCSS ?sStyleOFH.
    	         }
      }

   } `;

}
