import { BasicResultBindingElement, FusekiResults } from './basic_datatype';
/**
 * This is the page/manuscript status instantiation of an element of {@link /interfaces/FusekiResults.html|FusekiResults}.
 **/
export class TlnStatus extends BasicResultBindingElement  {
   static readonly default_key: string = 'id';
   static readonly query_key: string = 'id';
   /** 
    * the SPARQL-query of this datatype.
    **/
   static readonly query: string = `
   PREFIX tln: <http://www.nie.org/ontology/nietzsche#>
   PREFIX rdf: <http://www.w3.org/1999/02/22-rdf-syntax-ns#>
   PREFIX rdfs: <http://www.w3.org/2000/01/rdf-schema#>

   SELECT ?id ?status WHERE {
      { ?id tln:archivalManuscriptUnityHasDataProcessingStatus ?status } 
      UNION 
      { ?id tln:pageHasDataProcessingStatus ?status }
   }`;
   status: string;
    /**
    * The constructor creates a datatype from the data.
    *
    * @param id if omitted the id will be retrieved from data
    **/
   constructor(data: any, id?: string, service?: any){
      super(data, id, service)
      this.status = this.getData4Key('status')
      if (this.status == undefined || this.status == null){
         this.status = 'complete';
      }
   }

}

