import { Component, Input, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { MatBottomSheetRef} from '@angular/material/bottom-sheet';
import { PageViewService } from '../services';
import { Line } from '../models';
import { TlnImprint } from '../datatypes/imprint';
import { Mapping } from '../route-reader';
import { RouteReader } from '../route-reader';
import { TLN_SELECTED_LINES_PARAM, TLN_SELECTED_WORDS_PARAM } from '../constants';

interface Range {
   start: number;
   end?: number;
}

@Component({
  selector: 'imprint',
  templateUrl: './imprint.component.html',
  styleUrls: ['./imprint.component.css']
})
export class ImprintComponent extends RouteReader implements OnInit {
  @Input() imprint: TlnImprint;
  @Input() bottomSheetRef: MatBottomSheetRef;
  @Input() parentActivatedRoute: ActivatedRoute;
  @Input() targetRoute: string;
  highlighted: boolean = false;
  imprintReference: string;
  selectedLines: string[] = [];
  selectedWords: string[] = [];
  tempSelectedLines: string[] = [];
  tempSelectedWords: string[] = [];
  protected mapping: Mapping = { 
     selectedLines: { param: TLN_SELECTED_LINES_PARAM, type: "string" },
     selectedWords: { param: TLN_SELECTED_WORDS_PARAM, type: "string" }
  };

  constructor(private pageViewService: PageViewService, protected router: Router, protected activatedRoute: ActivatedRoute ){ 
      super(router, activatedRoute);
  }

  ngOnInit() {
     this.imprintReference = this.getLineReference() + this.imprint.reference;
     super.ngOnInit();
  }
  private getLineReference(): string {
      let lineString = '';
      if (this.imprint.lines.length == 0){
         return lineString
      }
      const ranges = this.numbers2ConsecutiveRanges(this.imprint.lines.map(line =>line.number));
      ranges.forEach(range =>{
         if (lineString != ''){
            lineString = lineString + ','
         }
         lineString = (range.end != undefined) ? lineString + range.start + '-' + range.end : lineString + range.start;
      });
      return lineString + ': ';
  }
  private numbers2ConsecutiveRanges(lines: number[]): Range[] {
      if (lines.length == 0) {
         return []
      } else if (lines.length < 2){
         return [ { start: lines[0] } ];
      } else if (lines.length == 2) {
         return (lines[1] - lines[0] > 1) ? [ { start: lines[0] }, { start: lines[1] } ] : [ { start: lines[0], end: lines[1] } ];
      } else {
         const index = Math.round((lines.length-1)/2)
         let leftRanges = this.numbers2ConsecutiveRanges(lines.slice(0, index))
         let rightRanges = this.numbers2ConsecutiveRanges(lines.slice(index))
         if (leftRanges.length == 0){
            return rightRanges
         } 
         if (rightRanges.length == 0){
            return leftRanges
         }
         if ((leftRanges[leftRanges.length-1].end != undefined && rightRanges[0].start - leftRanges[leftRanges.length-1].end == 1)
            || rightRanges[0].start - leftRanges[leftRanges.length-1].start == 1){
            leftRanges[leftRanges.length-1].end = (rightRanges[0].end != undefined) ? rightRanges[0].end : rightRanges[0].start;
            return [...leftRanges,...rightRanges.slice(1)];
         }
         return [...leftRanges,...rightRanges];
      }
  }
  private hoverChanged(hover: boolean){
     this.highlighted = hover;
     if (this.imprint != null){
        if (hover && (this.selectedLines.length > 0 || this.selectedWords.length > 0)){
            this.tempSelectedLines = this.selectedLines.slice();
            this.tempSelectedWords = this.selectedWords.slice();
            this.selectedLines.forEach(lineId =>{
               this.pageViewService.onClickedLine.emit({ id: lineId, top: -1, bottom: -1, number: -1 });
            });
            this.selectedWords.forEach(wordId =>{
               this.pageViewService.onClickedWord.emit({ id: wordId, text: 'none', top: -1, deleted: false, line: 'none', line_number: -1, left: -1, width: 0, height: 0 });
            });
         }
         this.imprint.lines.forEach(line =>{
            this.pageViewService.onClickedLine.emit(line);
         })
         if (!hover && (this.tempSelectedLines.length > 0 || this.tempSelectedWords.length > 0)){
            this.tempSelectedLines.forEach(lineId =>{
               this.pageViewService.onClickedLine.emit({ id: lineId, top: -1, bottom: -1, number: -1 });
            });    
            this.tempSelectedWords.forEach(wordId =>{
               this.pageViewService.onClickedWord.emit({ id: wordId, text: 'none', top: -1, deleted: false, line: 'none', line_number: -1, left: -1, width: 0, height: 0 });
            });
         }
     }
  }

}
