import { StandoffMarkup } from 'ngx-mat-standoff-markup';
import { BasicResultBindingElement } from './basic_datatype';
/**
 * This is the line instantiation of an element of {@link /interfaces/FusekiResults.html|FusekiResults}.
 * It extends {@link /classes/BasicResultBindingElement.html|BasicResultBindingElement}.
 **/
export class TlnPositionalStyleMarkup extends BasicResultBindingElement implements StandoffMarkup {
   /** 
    * the internal default key for replacing {@link /classes/TlnLine.html#query|query} by "id"
    * in {@link /classes/TlnLine.html#getQuery|getQuery} if "key" is omitted.
    **/
   static readonly default_key: string = 'word';
   /** 
    * the SPARQL-query of this datatype.
    **/
   static readonly query: string = `
   PREFIX homotypic: <http://www.nie.org/ontology/homotypic#>
   PREFIX rdf: <http://www.w3.org/1999/02/22-rdf-syntax-ns#>
   PREFIX stoff: <http://www.nie.org/ontology/standoff#>
   PREFIX tln: <http://www.nie.org/ontology/nietzsche#>

   SELECT ?id ?sStyle ?start ?end WHERE {
      ?word homotypic:hasMarkup ?id. 
      ?id stoff:hasCSS ?sStyle;
          stoff:standoffMarkupHasStartIndex ?start;
          stoff:standoffMarkupHasEndIndex ?end.
   } `;
   /**
    * one or multiple css style tags like 'font-weight: bold;' or 'font-weight: bold; font-style: italic'
    **/
   sStyle: string;
   /** 
    * character where the style ends.
    **/
   end: number;
   /** 
    * standoff type: 0 === markup via css tag; 1 === apply css class
    **/
   sType: number = 0;
   /** 
    * character where the style starts.
    **/
   start: number;
   static readonly query_key: string = 'word';

   /**
    * The constructor creates a datatype from the data.
    *
    * @param id if omitted the id will be retrieved from data
    **/
   constructor (data: any, id?: string, service?: any) {
      super(data, id, service);
      this.sStyle = this.getData4Key('sStyle');
      this.end = this.getData4Key('end');
      this.start = this.getData4Key('start');
   }
}
export class TlnFilteredPositionalStyleMarkup extends TlnPositionalStyleMarkup {
   protected readonly regex: RegExp = new RegExp('font-size:[0-9]\+%[;]*');

   constructor (data: any, id?: string, service?: any) {
      super(data, id, service);
      console.log(this.sStyle, this.start, this.end);
      this.sStyle = this.sStyle.replace(this.regex,'') + 'filter: invert(1);';
   }
}
export class TlnEditorCommentMarkup extends TlnPositionalStyleMarkup {
   /** 
    * the internal default key for replacing {@link /classes/TlnLine.html#query|query} by "id"
    * in {@link /classes/TlnLine.html#getQuery|getQuery} if "key" is omitted.
    **/
   static readonly default_key: string = 'text';
   /** 
    * the SPARQL-query of this datatype.
    **/
   static readonly query: string = `
   PREFIX rdf: <http://www.w3.org/1999/02/22-rdf-syntax-ns#>
   PREFIX stoff: <http://www.nie.org/ontology/standoff#>
   PREFIX tln: <http://www.nie.org/ontology/nietzsche#>

   SELECT ?sStyle ?start ?end WHERE {
      ?text tln:textHasMarkup ?id.
      ?id stoff:hasCSS ?sStyle;
          stoff:standoffMarkupHasStartIndex ?start;
          stoff:standoffMarkupHasEndIndex ?end.
   } `;
   static readonly query_key: string = 'text';
   constructor (data: any, id?: string, service?: any) {
      super(data, id, service);
      this.sStyle = this.sStyle + 'color: red;';
   }
}
export class TlnOverwrittenMarkup extends TlnPositionalStyleMarkup {
   /** 
    * the SPARQL-query of this datatype.
    **/
   static readonly query: string = `
   PREFIX rdf: <http://www.w3.org/1999/02/22-rdf-syntax-ns#>
   PREFIX stoff: <http://www.nie.org/ontology/standoff#>
   PREFIX tln: <http://www.nie.org/ontology/nietzsche#>

   SELECT ?sStyle ?start ?end WHERE {
      ?word tln:hasStandoffMarkup4PartThatOverwritesWord ?id.
      ?id stoff:hasCSS ?sStyle;
          stoff:standoffMarkupHasStartIndex ?start;
          stoff:standoffMarkupHasEndIndex ?end.
   } `;
   constructor (data: any, id?: string, service?: any) {
      super(data, id, service);
      this.sStyle = this.sStyle + 'color: red;';
      console.log(this.sStyle);
   }

}
