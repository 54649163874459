import { Component, Inject, OnInit } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA} from '@angular/material/bottom-sheet';
import { Copyright } from '../../models';

@Component({
  selector: 'copyright-sheet',
  templateUrl: './copyright-sheet.component.html',
  styleUrls: ['./copyright-sheet.component.css']
})
export class CopyrightSheetComponent implements OnInit {

  constructor(@Inject(MAT_BOTTOM_SHEET_DATA) private copyright: Copyright) { }

  ngOnInit() {
  }

}
