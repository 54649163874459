import { Parser, Generator } from 'sparqljs';
import { BasicResultBindingElement, FusekiResults} from './basic_datatype';
import { ManuscriptStub } from './manuscript';
import { PageStub } from './page';
import { WordStub } from './word';
import { QueryJson } from './query_json';

export class PresentationWord extends WordStub {
   line_number: number;

   constructor (data: any, id?: string, service?: any) {
      super(data, id, service);
      this.line_number = this.getData4Key('line_number');
   }
}
export class PageWords extends PageStub  {
   words: WordStub[] = [];

   constructor (data: any, id?: string, service?: any) {
      super(data, id, service);
   }
}
export class ManuscriptPageWords extends ManuscriptStub {
   static readonly query_key: string = 'word';
   static readonly default_key: string = 'word';
   static readonly query: string = `
   PREFIX tln: <http://www.nie.org/ontology/nietzsche#>
   PREFIX rdf: <http://www.w3.org/1999/02/22-rdf-syntax-ns#>

   SELECT ?id ?word ?title ?type ?page ?number ?text ?line_number WHERE {
      ?word tln:hasText ?text;
         tln:wordBelongsToLine/tln:lineHasNumber ?line_number.
      ?page a tln:Page;
        tln:hasWords/rdf:rest*/rdf:first ?word;
        tln:hasNumber ?number.
      ?id a tln:ArchivalManuscriptUnity;
            tln:hasPages/rdf:rest*/rdf:first ?page;
            tln:hasManuscriptType ?type;
            tln:hasTitle ?title.
   } ORDER BY ?id ?page`;
   pages: PageWords[] = [];

   constructor (data: any, id?: string, service?: any) {
      super(data, id, service);
   }
   public static convertData<T extends typeof BasicResultBindingElement>(this: T, data: FusekiResults, id?: string, service?: any): Array<InstanceType<T>> {
      let manuscripts = []; 
      let content = this.getContent(data);
      for (var i = 0; i < content.length; i++){
         let manuscript = new ManuscriptPageWords(content[i], id, service);
         let page = PageWords.convertData({ head: { vars: []}, results: { bindings: <any>[ content[i] ] } }, content[i]['page']['value'])[0]
         let word = PresentationWord.convertData({ head: { vars: []}, results: { bindings: <any>[ content[i] ] } }, content[i]['word']['value'])[0]
         if (manuscripts.length > 0 && manuscript.id == manuscripts[manuscripts.length-1].id){
            if (manuscripts[manuscripts.length-1].pages.length > 0 
               && page.id == manuscripts[manuscripts.length-1].pages[manuscripts[manuscripts.length-1].pages.length-1].id){
               manuscripts[manuscripts.length-1].pages[manuscripts[manuscripts.length-1].pages.length-1].words.push(word);
            } else {
               if (manuscripts[manuscripts.length-1].pages.length > 0){
                  console.log(page)
               }
               page.words.push(word)
               manuscripts[manuscripts.length-1].pages.push(page)
            }
         } else {
            page.words.push(word)
            manuscript.pages.push(page);
            manuscripts.push(manuscript);
         }
      }
      return manuscripts;
   }
   public static getParameterizedQuery(wordIds: string[]): string {
      let parser = new Parser();
      let sparqlGenerator = new Generator({});
      let parsedQuery = parser.parse(this.query)
      let filters = wordIds.map(id =>QueryJson.createEqualsOperation(id, this.query_key));
      let filterObject = QueryJson.createFilterObject(filters);
      parsedQuery.where.push(filterObject);
      return sparqlGenerator.stringify(parsedQuery);
   }
}
