import { BasicResultBindingElement, FusekiResults } from './basic_datatype';
import { Representation } from '../models';
/**
 * This is the deletion path instantiation of an element of {@link /interfaces/FusekiResults.html|FusekiResults}.
 * It extends {@link /classes/BasicResultBindingElement.html|BasicResultBindingElement}.
 **/
export class DeletionPathRepresentation extends BasicResultBindingElement  implements Representation {
   /** 
    * the internal default key for replacing {@link /classes/TlnLine.html#query|query} by "id"
    * in {@link /classes/TlnLine.html#getQuery|getQuery} if "key" is omitted.
    **/
   readonly openInTarget = 'viewer';
   static readonly default_key: string = 'id';
   static readonly query_key: string = 'id';
   /** 
    * the SPARQL-query of this datatype.
    **/
   static readonly query: string = `
   PREFIX tln: <http://www.nie.org/ontology/nietzsche#>
   PREFIX rdf: <http://www.w3.org/1999/02/22-rdf-syntax-ns#>

   SELECT distinct ?word ?lineNumber ?pageNumber ?title ?page ?manuscript WHERE {
      ?id ^tln:wordIsDeletedByPath ?word.
      ?word tln:wordBelongsToLine ?line.
      ?line tln:lineHasNumber ?lineNumber.
      OPTIONAL {
      ?page tln:hasWords/rdf:rest*/rdf:first ?word;
            tln:hasNumber ?pageNumber.}
      OPTIONAL {
            ?wholeWord tln:wordHasWordParts/rdf:rest*/rdf:first ?word.
            ?page tln:hasWords/rdf:rest*/rdf:first ?wholeWord;
            tln:hasNumber ?pageNumber.}
      ?manuscriptId tln:hasPages/rdf:rest*/rdf:first ?page;
            tln:hasTitle ?title.
   } `;
   /** 
    * the number of this line.
    **/
   lineNumber: number;
   pageNumber: string;
   page: string;
   manuscript: string;
   title: string;
   word: string;
   selectedWords: string[] = [];
   representation: string;

   /**
    * The constructor creates a datatype from the data.
    *
    * @param id if omitted the id will be retrieved from data
    **/
   constructor (data: any, id?: string, service?: any) {
      super(data, id, service);
      this.lineNumber = this.getData4Key('lineNumber');
      this.pageNumber = this.getData4Key('pageNumber')
      this.page = this.getData4Key('page')
      this.title = this.getData4Key('title')
      this.manuscript = this.getData4Key('manuscript')
      this.word = this.getData4Key('word')
      this.representation = this.title + ' ' + this.pageNumber + ', ' + this.lineNumber + ' (' + this.id.substring(this.id.lastIndexOf('_')+1) + ')';
   }
   public static convertData<T extends typeof BasicResultBindingElement>(this: T, data: FusekiResults, id?: string, service?: any): Array<InstanceType<T>> {
      let elements = []; 
      let content = this.getContent(data);
      for (var i = 0; i < content.length; i++){
         let element = new this(content[i], id, service);
         if (elements.length > 0){
            elements[0]['selectedWords'].push(element['word']);
         } else {
            element['selectedWords'].push(element['word']);
            elements.push(element)
         }
      }
      return elements;
   }
   

   public getRouterInformation(launchExternally: boolean): Object {
      return { title: this.title, number: this.pageNumber, page: this.page, 
            manuscript: this.manuscript, selectedWords: this.selectedWords, launchExternally: launchExternally }
   }
}
