import { Component, Input, OnInit } from '@angular/core';
import { OntologyQueryService } from './ontology-query.service';
import { DataMapping, QueryDataMapping, Namespace, Ontology, OntologyBearer } from './models';
import { SparqlParser } from './sparqljs';

export class OntologyRetriever implements OnInit, OntologyBearer {
  @Input() ontology: Ontology;
  @Input() namespaces: Namespace[];
  dataMappings: DataMapping[] = [];
  query: string;

  constructor(protected queryService: OntologyQueryService) { }

  ngOnInit() {
     if (this.ontology != undefined && this.ontology != null && this.query != undefined && this.query != null){
        let parser = new SparqlParser(this.queryService);
        parser.fetchArrayData(this, 'dataMappings', this.query, 'ontology', this.ontology.iri, this.namespaces);
        //console.log(SparqlParser.getQuery(this.query, 'ontology', this.ontology.iri));
     }
  }

}
