import { Pipe, PipeTransform } from '@angular/core';
import { TlnEditorCommentStub } from '../datatypes/editor_comment';

@Pipe({
  name: 'mergeEditorComment'
})
export class MergeEditorCommentPipe implements PipeTransform {

  transform(editor_comments: TlnEditorCommentStub[]): TlnEditorCommentStub[] {
    if (editor_comments.length < 2){
         return editor_comments
    }
    let newComments = [];
    editor_comments.forEach(comment =>{
         if(newComments.length == 0 || newComments[newComments.length-1].id != comment.id){
            newComments.push(comment);
         }
    })
    return newComments;
  }

}
