import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Content } from './models';
import { CONTENT_VIEW_ROUTE, CONCEPTION_ROUTE, DATA_ROUTE, DOCUMENTATION_ROUTE, HELP_ROUTE, HOME_ROUTE, IMPRESSUM_ROUTE, PROJECT_ROUTE } from '../constants';

@Component({
  selector: 'support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.scss']
})
export class SupportComponent implements OnInit {
  titles: Content[] = []
  queryParams: Params;
   contentViewLink: string[] = [ '/' + CONTENT_VIEW_ROUTE ];
  impressumLink: string[] = [ IMPRESSUM_ROUTE ];
  homeLink: string[] = [ HOME_ROUTE ];
  supportLink: string[] = [ HELP_ROUTE ];
  projectLink: string[] = [ PROJECT_ROUTE];
  dataLink: string[] = [ DATA_ROUTE];
  conceptionLink: string[] = [ CONCEPTION_ROUTE];
   index: number = 0;


   constructor(private activatedRoute: ActivatedRoute, private router: Router ) { }

  ngOnInit() {
     this.activatedRoute.queryParams.subscribe( (queryParams: Params ) => {
         this.queryParams = queryParams;
     });

  }
  getContentClass(level: number): string {
     return 'level' + level;
  }
  getNextChildFragment(index: number, titles: Content[]): string {
      if (titles == undefined || titles == null || titles.length <= index) return '';
      return titles[index].link;
  }
  moveToMenuItem(hash: string){
     console.log(hash);
     this.router.navigate([], { queryParams: this.queryParams, fragment: hash });
     setTimeout(()=>{
        window.location = window.location;}, 200);
  }
}
