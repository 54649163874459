import { Component, Input, OnInit } from '@angular/core';
import { MarkupSettings, StandoffMarkup } from 'ngx-mat-standoff-markup';
import { TlnEditorCommentStub } from '../datatypes/editor_comment';
import { DataHandler } from '../data_handler';
import { TlnCacheQueryService } from '../services';
import { TlnOverwrittenMarkup } from '../datatypes/positional-markup';
import { Word } from '../models';

@Component({
  selector: 'overwritten',
  templateUrl: './overwritten.component.html',
  styleUrls: ['./overwritten.component.css']
})
export class OverwrittenComponent implements OnInit {
   @Input() word: Word;
   mySettings: MarkupSettings = new MarkupSettings();
   dataHandler: DataHandler = new DataHandler(this);
   markups: StandoffMarkup[] = [];

  constructor(private tlnQueryService: TlnCacheQueryService) { }

  ngOnInit() {
     if (this.word != undefined && this.word != null){
        this.dataHandler.addHandler('markups', { 'handler': TlnOverwrittenMarkup }); 
        this.dataHandler.setQueryService(this.tlnQueryService);
        this.tlnQueryService.reset_data.subscribe(
            (data_key: string) =>{
               if (data_key == 'page_content'){
                  this.markups = [];
               }
        });
        this.dataHandler.getData('markups', <string>this.word.id);
     }
  }

}
