import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatBottomSheetModule,MatButtonModule,MatCheckboxModule,MatDialogModule,MatExpansionModule,MatFormFieldModule,MatInputModule,MatListModule,MatPaginatorModule,MatRadioModule,MatSelectModule,MatSidenavModule,MatSortModule,MatTableModule,MatToolbarModule,MatButtonToggleModule,MatCardModule,MatIconModule,MatMenuModule,MatTabsModule,MatTooltipModule
} from '@angular/material';
import { MarginFieldComponent } from './margin-field/margin-field.component';
import { TextFieldComponent} from './textfield-component/textfield.component';
import { InteractedDirective } from './interacted.directive';
import { PageViewComponent } from './page-view.component';
import { PageViewService } from './page-view.service';
import { CopyrightComponent } from './copyright/copyright.component';
import { LineReferenceComponent } from './margin-field/line-reference/line-reference.component';
import { CopyrightSheetComponent } from './copyright/copyright-sheet/copyright-sheet.component';

@NgModule({
   declarations: [
      InteractedDirective,
      MarginFieldComponent,
      TextFieldComponent,
      PageViewComponent,
      CopyrightComponent,
      LineReferenceComponent,
      CopyrightSheetComponent
   ],
   imports: [
     MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatDialogModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatPaginatorModule,
    MatRadioModule,
    MatSelectModule,
    MatSidenavModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    CommonModule
   ],
   providers: [
      PageViewService
   ],
   exports: [
      MarginFieldComponent,
      TextFieldComponent,
      PageViewComponent,
      CopyrightComponent
   ],
   entryComponents: [CopyrightSheetComponent]
})
export class PageViewModule { }
